import { ActivityForm } from 'models/activity'
import { ActivityTypes } from 'models/activityTypes'
import client from './client'

// アクティビティタイプを取得
export const getActivityTypes = async (): Promise<ActivityTypes> => {
  return client
    .get(`activityTypes?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

export const addActivity = (
  params: ActivityForm,
  apiToken: string
): Promise<boolean> => {
  return client
    .post(`activities?api_token=${apiToken}`, {
      due_date: params.taskStartDate,
      due_time: params.taskStartTime,
      duration: params.taskDuration,
      deal_id: params.dealId,
      person_id: params.personId,
      org_id: params.orgId,
      note: params.taskMemo,
      subject: params.taskName,
      type: params.taskType,
      user_id: params.taskAssign,
    })
    .then(() => {
      return true
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}
