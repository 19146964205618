import back from './back'
import { User } from 'models/user'

// ログイン認証
export const authUser = async (
  mail: string,
  password: string
): Promise<User | boolean> => {
  return await back
    .post(`/user/loginCheck`, {
      mail: mail,
      password: password,
    })
    .then((res) => {
      return res.data
    })
    .catch(() => {
      return false
    })
}
