//import logo from './logo.svg'
import './App.css'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import DealPage from './components/pages/DealPage'
import DealEdit from './components/pages/DealEdit'
import HomePage from './components/pages/HomePage'
import NotFound from 'components/pages/NotFound'
import Login from 'components/pages/Login'
import User from 'components/pages/User'
import AuthUserProvider, {
  useAuthUser,
} from 'components/modules/AuthUserContext'
import Profile from 'components/pages/Profile'
import FreeDialDeal from 'components/pages/FreeDialDeal'
import Invoice from 'components/pages/Invoice'
import InvoiceEdit from 'components/pages/InvoiceEdit'
import InvoiceCreate from 'components/pages/InvoiceCreate'

// ログイン後の判定処理
const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const authUser = useAuthUser()
  const isAuthenticated = authUser != null
  if (isAuthenticated) {
    return children
  } else {
    return <Navigate to="/login" state={{ from: location }} />
  }
}

// 未ログイン時の判定
const UnAuthRoute = ({ children }: { children: JSX.Element }) => {
  const authUser = useAuthUser()
  const isAuthenticated = authUser != null
  //const { from } = useLocation().state

  if (isAuthenticated) {
    return <Navigate to={'/'} />
  } else {
    return children
  }
}

const App: React.FunctionComponent = () => {
  return (
    <AuthUserProvider>
      <Router>
        <Routes>
          <Route
            path="/deal"
            element={
              <PrivateRoute>
                <DealPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/deal/:id"
            element={
              <PrivateRoute>
                <DealEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/user"
            element={
              <PrivateRoute>
                <User />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/freeDialDeal"
            element={
              <PrivateRoute>
                <FreeDialDeal />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoice"
            element={
              <PrivateRoute>
                <Invoice />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoice/create"
            element={
              <PrivateRoute>
                <InvoiceCreate />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoice/edit/:id"
            element={
              <PrivateRoute>
                <InvoiceEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/login"
            element={
              <UnAuthRoute>
                <Login />
              </UnAuthRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AuthUserProvider>
  )
}

export default App
