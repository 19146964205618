import React, { useState, useEffect } from 'react'
import GenericTemplate from '../templates/GenericTemplate'
import {
  makeStyles,
  Theme,
  createStyles,
  Backdrop,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  TextField,
  Snackbar,
  Input,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useAuthUser } from 'components/modules/AuthUserContext'
//import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Create } from '@material-ui/icons'
import {
  GetInvoiceFromApi,
  InvoiceEditForm,
  InvoiceDetailColumn,
  InvoiceDetailForm,
} from 'models/invoice'
import { checkDealById } from 'api/deal'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import format from 'date-fns/format'
import DateFnsUtils from '@date-io/date-fns'
import jaLocale from 'date-fns/locale/ja'
import { getSolaboBankbook } from 'api/solaboBankbook'
import { FormSelectList } from 'models/deal'
import MaterialTable from 'material-table'
import { getInvoiceById, updateInvoice } from 'api/invoice'
import { useParams } from 'react-router-dom'

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: Date) {
    return format(date, 'yyyy年 MMM', { locale: this.locale })
  }
  getDatePickerHeaderText(date: Date) {
    return format(date, 'MMMd日(E)', { locale: this.locale })
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px',
    },
    submitButton: {
      margin: '5px',
    },
    errorMessage: {
      color: 'red',
    },
    formControl: {
      width: '100%',
      marginTop: '13px',
      marginBottom: '8px',
    },
  })
)

//const { id } = useParams()
const numberFormat = (num: number): string => {
  return num.toLocaleString()
}

const isNumber = (num: string): boolean => {
  // チェック条件パターン
  const pattern = /^[-]?([1-9]\d*|0)(\.\d+)?$/
  // 数値チェック
  return pattern.test(num)
}

type SnackBarParam = {
  open: boolean
  severity: 'error' | 'success' | 'warning' | 'info' | undefined
  message: string
}

const InvoiceEdit: React.FC = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [snackBar, setSnackBar] = useState<SnackBarParam>({
    open: false,
    severity: undefined,
    message: '',
  })
  const [solaboBankbookList, setSolaboBankbookList] = useState<FormSelectList>([
    {},
  ])

  const { id } = useParams()

  const [invoice, setInvoice] = useState<GetInvoiceFromApi | null>(null)

  // 請求詳細の項目を定義
  const columns: InvoiceDetailColumn[] = [
    {
      title: '品番・品名',
      field: 'unit_name',
      validate: (rowData) =>
        rowData.unit_name === undefined || rowData.unit_name === ''
          ? { isValid: false, helperText: '※ 品番・品名を入力してください' }
          : true,
    },
    {
      title: '数量',
      field: 'unit_quantity',
      validate: (rowData) => {
        if (rowData.unit_quantity === undefined || rowData.unit_quantity === '')
          return { isValid: false, helperText: '※ 数量を入力してください' }
        else if (!isNumber(rowData.unit_quantity))
          return { isValid: false, helperText: '※ 数字を入力してください' }
        else return true
      },
    },
    {
      title: '単価',
      field: 'unit_cost',
      validate: (rowData) => {
        if (rowData.unit_cost === undefined || rowData.unit_cost === '')
          return { isValid: false, helperText: '※ 単価を入力してください' }
        else if (!isNumber(rowData.unit_cost))
          return { isValid: false, helperText: '※ 数字を入力してください' }
        else return true
      },
    },
    {
      title: '金額',
      field: 'unit_price',
      type: 'numeric',
      editable: 'never',
    },
  ]

  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetailForm[]>([])

  const authUser = useAuthUser()

  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    trigger,
    // watch,
    // unregister,
    getValues,
  } = useForm<InvoiceEditForm>({
    mode: 'all',
  })

  // 登録時処理
  const handleOnSubmit = async (params) => {
    setLoading(true)

    try {
      // 詳細情報が入力されているかチェック
      if (!invoiceDetails.length) throw new Error('詳細が登録されていません')

      // 存在するDealIdかチェック
      await checkDealById(params.pipedriveDealId)

      // 請求登録
      await updateInvoice({ ...params, invoiceDetails })

      setSnackBar({
        open: true,
        severity: 'success',
        message: '請求書を作成しました',
      })
      // toDo 作成した請求書編集画面へのリンクを作成する？
    } catch (e: unknown) {
      if (e instanceof Error) {
        setSnackBar({
          open: true,
          severity: 'error',
          message: e.message,
        })
      } else {
        setSnackBar({
          open: true,
          severity: 'error',
          message: '予期せぬエラーが発生しました',
        })
      }
    }

    setLoading(false)
  }

  // 結果ダイアログ用
  const handleSnackBarClose = () => {
    setSnackBar({
      open: false,
      severity: undefined,
      message: '',
    })
  }

  useEffect(() => {
    const setFirstView = async () => {
      try {
        // 振込先口座情報を取得
        const solaboBankbooks = await getSolaboBankbook()

        setSolaboBankbookList(
          solaboBankbooks.map((v) => {
            return {
              id: v.id,
              name:
                v.bankbook_name +
                '　' +
                v.bankbook_branch_name +
                '　普通' +
                v.bankbook_number +
                '　' +
                v.bankbook_holder,
            }
          })
        )

        // 請求書詳細情報を取得
        const invoice = await getInvoiceById(Number(id))

        setInvoice(invoice)

        // 請求書詳細の内容
        setInvoiceDetails(
          invoice.invoiceDetails.map((v) => {
            return {
              tableData: { id: v.id },
              unit_name: v.unit_name,
              unit_quantity: v.unit_quantity,
              unit_cost: v.unit_cost,
              unit_price: numberFormat(v.unit_price),
            }
          })
        )

        const totalUnitPrice = invoice.invoiceDetails.reduce((sum, b) => {
          return sum + Number(b.unit_price)
        }, 0)

        setValue('id', invoice.id)
        setValue('pipedriveDealId', invoice.pipedrive_deal_id)
        setValue('invoiceTitle', invoice.invoice_title)
        setValue('invoiceOn', invoice.invoice_on)
        setValue('invoiceDeadOn', invoice.invoice_dead_on)
        setValue('invoiceSendType', invoice.invoice_send_type)
        setValue('solaboBankbookId', invoice.solabo_bankbook_id)
        setValue('invoiceRemark', invoice.invoice_remark)
        setValue('invoiceMemo', invoice.invoice_memo)
        setValue('billingAmountSub', numberFormat(totalUnitPrice))
        setValue('billingAmountConsumption', numberFormat(totalUnitPrice * 0.1))
        setValue('billingAmountTotal', numberFormat(totalUnitPrice * 1.1))
      } catch (e: unknown) {
        if (e instanceof Error) {
          setSnackBar({
            open: true,
            severity: 'error',
            message: e.message,
          })
        } else {
          setSnackBar({
            open: true,
            severity: 'error',
            message: '予期せぬエラーが発生しました',
          })
        }
      }

      setLoading(false)
    }

    setFirstView()
  }, [])

  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress />
      </Backdrop>
    )
  }

  return (
    <GenericTemplate title="請求書編集">
      <>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Input
            {...register('id')}
            id="id"
            type="hidden"
            value={invoice?.id}
          />
          <Input
            {...register('updatedUserId')}
            id="updatedUserId"
            type="hidden"
            value={authUser?.id}
          />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={6} md={2} lg={2}>
                <TextField
                  {...register('pipedriveDealId', {
                    required: '※ 取引IDを入力してください',
                  })}
                  margin="normal"
                  fullWidth
                  id="pipedriveDealId"
                  label={<span style={{ color: 'red' }}>取引ID</span>}
                  size="small"
                />
                {errors.pipedriveDealId && (
                  <span className={classes.errorMessage}>
                    {errors.pipedriveDealId.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={6} lg={4}>
                <TextField
                  {...register('invoiceTitle', {
                    required: '※ 件名を入力してください',
                  })}
                  margin="normal"
                  fullWidth
                  id="invoiceTitle"
                  label={<span style={{ color: 'red' }}>件名</span>}
                  size="small"
                />
                {errors.invoiceTitle && (
                  <span className={classes.errorMessage}>
                    {errors.invoiceTitle.message}
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={6} md={2} lg={2}>
                <FormControl className={classes.formControl}>
                  <Controller
                    name="invoiceOn"
                    control={control}
                    rules={{
                      required: '※ 請求日を選択してください',
                    }}
                    render={({ field }) => (
                      <MuiPickersUtilsProvider
                        utils={ExtendedUtils}
                        locale={jaLocale}
                      >
                        <KeyboardDatePicker
                          {...field}
                          label={<span style={{ color: 'red' }}>請求日</span>}
                          value={field.value ?? null}
                          format="yyyy-MM-dd"
                          autoOk={true}
                          variant="inline"
                          onChange={(_date, v) => {
                            setValue('invoiceOn', v)
                            trigger('invoiceOn')
                          }}
                          invalidDateMessage="※ フォーマットに従って入力してください"
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </FormControl>
                {errors.invoiceOn && (
                  <span className={classes.errorMessage}>
                    {errors.invoiceOn.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={2} lg={2}>
                <FormControl className={classes.formControl}>
                  <Controller
                    name="invoiceDeadOn"
                    control={control}
                    rules={{
                      required: '※ 支払期限日を選択してください',
                    }}
                    render={({ field }) => (
                      <MuiPickersUtilsProvider
                        utils={ExtendedUtils}
                        locale={jaLocale}
                      >
                        <KeyboardDatePicker
                          {...field}
                          label={
                            <span style={{ color: 'red' }}>支払期限日</span>
                          }
                          value={field.value ?? null}
                          format="yyyy-MM-dd"
                          autoOk={true}
                          variant="inline"
                          onChange={(_date, v) => {
                            setValue('invoiceDeadOn', v)
                            trigger('invoiceDeadOn')
                          }}
                          invalidDateMessage="※ フォーマットに従って入力してください"
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </FormControl>
                {errors.invoiceDeadOn && (
                  <span className={classes.errorMessage}>
                    {errors.invoiceDeadOn.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={2} lg={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                    送付タイプ
                  </InputLabel>
                  <Controller
                    name="invoiceSendType"
                    control={control}
                    rules={{
                      required: '※ 送付タイプを選択してください',
                    }}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        <MenuItem value="1">メール</MenuItem>
                        <MenuItem value="2">郵送</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.invoiceSendType && (
                  <span className={classes.errorMessage}>
                    {errors.invoiceSendType.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                    振込先口座情報
                  </InputLabel>
                  <Controller
                    name="solaboBankbookId"
                    control={control}
                    rules={{
                      required: '※ 振込先口座情報を選択してください',
                    }}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {solaboBankbookList.map((bankbook) => (
                          <MenuItem key={bankbook.id} value={bankbook.id}>
                            {bankbook.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.solaboBankbookId && (
                  <span className={classes.errorMessage}>
                    {errors.solaboBankbookId.message}
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  {...register('invoiceRemark')}
                  multiline
                  variant="outlined"
                  fullWidth
                  id="invoiceRemark"
                  label="備考"
                  autoComplete="invoiceRemark"
                  minRows={3}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  {...register('invoiceMemo')}
                  multiline
                  variant="outlined"
                  fullWidth
                  id="invoiceMemo"
                  label="メモ"
                  autoComplete="invoiceMemo"
                  minRows={3}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={6} md={2} lg={2}>
                <TextField
                  {...register('billingAmountSub')}
                  margin="normal"
                  fullWidth
                  id="billingAmountSub"
                  label="小計"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={2} lg={2}>
                <TextField
                  {...register('billingAmountConsumption')}
                  margin="normal"
                  fullWidth
                  id="billingAmountConsumption"
                  label="消費税"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={2} lg={2}>
                <TextField
                  {...register('billingAmountTotal')}
                  margin="normal"
                  fullWidth
                  id="billingAmountTotal"
                  label="合計"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <MaterialTable
            title="詳細"
            columns={columns}
            data={invoiceDetails}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    newData['unit_price'] = numberFormat(
                      newData.unit_quantity * newData.unit_cost
                    )
                    setInvoiceDetails([...invoiceDetails, newData])

                    // 金額計算
                    const invoiceFee = getValues([
                      'billingAmountSub',
                      'billingAmountConsumption',
                      'billingAmountTotal',
                    ])

                    setValue(
                      'billingAmountSub',
                      numberFormat(
                        Number(invoiceFee[0].replace(/,/g, '')) +
                          Number(newData.unit_price.replace(/,/g, ''))
                      )
                    )

                    setValue(
                      'billingAmountConsumption',
                      numberFormat(
                        Number(invoiceFee[1].replace(/,/g, '')) +
                          Number(newData.unit_price.replace(/,/g, '')) * 0.1
                      )
                    )
                    setValue(
                      'billingAmountTotal',
                      numberFormat(
                        Number(invoiceFee[2].replace(/,/g, '')) +
                          Number(newData.unit_price.replace(/,/g, '')) * 1.1
                      )
                    )
                    // 総計等の算出

                    resolve(true)
                  }, 1000)
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    const dataUpdate = [...invoiceDetails]
                    if (oldData) {
                      const index = oldData.tableData.id
                      newData['unit_price'] = numberFormat(
                        newData.unit_quantity * newData.unit_cost
                      )
                      dataUpdate[index] = newData
                      setInvoiceDetails([...dataUpdate])

                      // 金額計算
                      const invoiceFee = getValues([
                        'billingAmountSub',
                        'billingAmountConsumption',
                        'billingAmountTotal',
                      ])

                      setValue(
                        'billingAmountSub',
                        numberFormat(
                          Number(invoiceFee[0].replace(/,/g, '')) +
                            Number(newData.unit_price.replace(/,/g, '')) -
                            Number(oldData.unit_price.replace(/,/g, ''))
                        )
                      )
                      setValue(
                        'billingAmountConsumption',
                        numberFormat(
                          Number(invoiceFee[1].replace(/,/g, '')) +
                            Number(newData.unit_price.replace(/,/g, '')) * 0.1 -
                            Number(oldData.unit_price.replace(/,/g, '')) * 0.1
                        )
                      )
                      setValue(
                        'billingAmountTotal',
                        numberFormat(
                          Number(invoiceFee[2].replace(/,/g, '')) +
                            Number(newData.unit_price.replace(/,/g, '')) * 1.1 -
                            Number(oldData.unit_price.replace(/,/g, '')) * 1.1
                        )
                      )
                    }
                    // 総計等の算出

                    resolve(true)
                  }, 1000)
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    const dataDelete = [...invoiceDetails]
                    const index = oldData.tableData.id
                    dataDelete.splice(index, 1)
                    setInvoiceDetails([...dataDelete])

                    const invoiceFee = getValues([
                      'billingAmountSub',
                      'billingAmountConsumption',
                      'billingAmountTotal',
                    ])

                    setValue(
                      'billingAmountSub',
                      numberFormat(
                        Number(invoiceFee[0].replace(/,/g, '')) -
                          Number(oldData.unit_price.replace(/,/g, ''))
                      )
                    )
                    setValue(
                      'billingAmountConsumption',
                      numberFormat(
                        Number(invoiceFee[1].replace(/,/g, '')) -
                          Number(oldData.unit_price.replace(/,/g, '')) * 0.1
                      )
                    )
                    setValue(
                      'billingAmountTotal',
                      numberFormat(
                        Number(invoiceFee[2].replace(/,/g, '')) -
                          Number(oldData.unit_price.replace(/,/g, '')) * 1.1
                      )
                    )

                    // 総計等の算出

                    resolve(true)
                  }, 1000)
                }),
            }}
            localization={{
              body: {
                addTooltip: '追加',
                editTooltip: '編集',
                editRow: {
                  cancelTooltip: 'キャンセル',
                  saveTooltip: '登録',
                  deleteText: 'この行を削除します。よろしいですか？',
                },
                deleteTooltip: '削除',
                emptyDataSourceMessage: '※ 詳細を作成してください',
              },
              header: {
                actions: '',
              },
            }}
            options={{
              filtering: false,
              search: false,
              draggable: false,
              paging: false,
            }}
          />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Box component="span" m={3} className={classes.buttonWrapper}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                startIcon={<Create />}
              >
                登録
              </Button>
            </Box>
          </Grid>
          <Snackbar
            open={snackBar.open}
            onClose={handleSnackBarClose}
            autoHideDuration={3000}
          >
            <Alert severity={snackBar.severity} onClose={handleSnackBarClose}>
              {snackBar.message}
            </Alert>
          </Snackbar>
        </form>
      </>
    </GenericTemplate>
  )
}

export default InvoiceEdit
