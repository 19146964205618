import {
  InvoiceCreateForm,
  InvoiceList,
  GetInvoiceFromApi,
  InvoiceEditForm,
} from 'models/invoice'

import back from './back'

// 請求書情報を取得
export const getInvoiceList = async (): Promise<InvoiceList[]> => {
  return await back
    .get(`/invoice/getList`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
      throw new Error(err.response.data)
    })
}

// 請求書新規作成
export const createInvoice = async (
  params: InvoiceCreateForm
): Promise<number> => {
  return await back
    .post(`/invoice/create`, params)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
      throw new Error(err.response.data)
    })
}

// 請求書更新
export const updateInvoice = async (params: InvoiceEditForm): Promise<void> => {
  await back
    .post(`/invoice/update`, params)
    .then()
    .catch((err) => {
      console.log(err)
      throw new Error(err.response.data)
    })
}

// 請求書情報取得
export const getInvoiceById = async (
  id: number
): Promise<GetInvoiceFromApi> => {
  return await back
    .post(`/invoice/get`, { invoiceId: id })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
      throw new Error(err.response.data)
    })
}
