import { OrgFieldsPipedrive } from 'models/orgFields'
import client from './client'

export const getOrgFields = async (): Promise<OrgFieldsPipedrive> => {
  return client
    .get(
      `organizationFields?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`,
      {
        params: { limit: 300 },
      }
    )
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}
