import client from './client'
import { UserCreate, Owner, ProfileForm } from '../models/user'
import back from './back'

// ユーザ作成
export const createUser = async (props: UserCreate): Promise<void> => {
  await back.post(`/user/create`, props).catch((err) => {
    console.log(err)
    throw new Error(err.response.data)
  })
}

// ログインユーザ情報を更新
export const updateUser = async (props: ProfileForm): Promise<void> => {
  const params = {
    id: props.id,
    name: props.name,
    email: props.email,
    password: props.password,
    authority: props.authority ? props.authority : null,
    responsible_department: props.responsible_department,
    pipedrive_id: props.pipedrive_id ? props.pipedrive_id : null,
  }
  await back.post(`/user/update`, params).catch((err) => {
    console.log(err)
    throw new Error(err.response.data)
  })
}

// pipedrive user取得
export const getOwnerList = async (): Promise<Owner> => {
  return client
    .get(`users?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
      throw new Error(err.message)
    })
}
