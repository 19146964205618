import React, { useState } from 'react'
import GenericTemplate from 'components/templates/GenericTemplate'
import {
  Button,
  Grid,
  Box,
  TextField,
  Snackbar,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import styles from '../scss/user.module.scss'
import { useForm } from 'react-hook-form'
import { createUser } from '../../api/user'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

type UserForm = {
  name: string
  email: string
  password: string
  pipedriveId: number
  pipedriveToken: string
}

type SnackBarParam = {
  open: boolean
  severity: 'error' | 'success' | 'warning' | 'info' | undefined
  message: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
)

const create: React.FC = () => {
  const classes = useStyles()
  const [snackBar, setSnackBar] = useState<SnackBarParam>({
    open: false,
    severity: 'success',
    message: '',
  })

  const [progress, setProgress] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UserForm>()

  const handleOnSubmit = async (data: UserForm) => {
    setProgress(true)
    // ユーザ作成
    await createUser(data)
      .then(() => {
        setSnackBar({
          open: true,
          severity: 'success',
          message: 'ユーザ登録完了しました',
        })
      })
      .catch((e: unknown) => {
        if (e instanceof Error) {
          setSnackBar({
            open: true,
            severity: 'error',
            message: e.message,
          })
        } else {
          setSnackBar({
            open: true,
            severity: 'error',
            message: '予期せぬエラーが発生しました',
          })
        }
      })
    reset()
    setProgress(false)
  }

  // ダイアログ用
  const handleClose = () => {
    setSnackBar({
      open: false,
      severity: undefined,
      message: '',
    })
  }

  const form = (
    <Backdrop className={classes.backdrop} open={progress}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )

  return (
    <GenericTemplate title="ユーザ作成">
      {form}
      <form className={styles.form}>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={3}>
              <TextField
                {...register('name', {
                  required: '※ 名前を入力してください',
                })}
                margin="normal"
                fullWidth
                id="name"
                label="名前"
                size="small"
                className={styles.text_field}
              />
              {errors.name && (
                <span className={styles.error_message}>
                  {errors.name.message}
                </span>
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                {...register('email', {
                  required: '※ メールアドレスを入力してください',
                })}
                margin="normal"
                fullWidth
                id="email"
                label="メールアドレス"
                size="small"
                className={styles.text_field}
              />
              {errors.email && (
                <span className={styles.error_message}>
                  {errors.email.message}
                </span>
              )}
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('password', {
                  required: '※ パスワードを入力してください',
                  // pattern: {
                  //   value: /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,24}$/i,
                  //   message:
                  //     '※ 半角英数字をそれぞれ1種類以上8文字以上で入力してください',
                  // },
                })}
                margin="normal"
                fullWidth
                id="standard-password-input"
                label="パスワード"
                type="password"
                autoComplete="current-password"
                size="small"
                className={styles.pass_field}
              />
              {errors.password && (
                <span className={styles.error_message}>
                  {errors.password.message}
                </span>
              )}
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('pipedriveId', {
                  required: '※ pipedriveIDを入力してください',
                })}
                margin="normal"
                fullWidth
                id="pipedriveId"
                label="pipedriveID"
                size="small"
                className={styles.text_field}
              />
              {errors.pipedriveId && (
                <span className={styles.error_message}>
                  {errors.pipedriveId.message}
                </span>
              )}
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('pipedriveToken', {
                  required: '※ pipedriveTokenを入力してください',
                })}
                margin="normal"
                fullWidth
                id="pipedriveToken"
                label="pipedriveToken"
                size="small"
                className={styles.text_field}
              />
              {errors.pipedriveToken && (
                <span className={styles.error_message}>
                  {errors.pipedriveToken.message}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box component="span" m={2} className={styles.button_wrapper}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={styles.submit_button}
            onClick={handleSubmit(handleOnSubmit)}
          >
            登録
          </Button>
        </Box>
      </form>
      <Snackbar
        open={snackBar.open}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity={snackBar.severity} onClose={handleClose}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </GenericTemplate>
  )
}

export default create
