import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Backdrop,
  CircularProgress,
  Input,
  Chip,
  Box,
  Button,
  Snackbar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Typography,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { Alert, Autocomplete } from '@material-ui/lab'
import { FormSelectObject, FormSelectList } from 'models/deal'
import { DealFields, PipedriveTotal } from 'models/dealFields'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import jaLocale from 'date-fns/locale/ja'

import format from 'date-fns/format'
import { searchOrgListByName, updateOrg } from 'api/org'
import { searchPersonListByName, updatePerson } from 'api/person'
import { contractDeal, lostDeal, updateDeal } from 'api/deal'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import PersonIcon from '@material-ui/icons/Person'
import BusinessIcon from '@material-ui/icons/Business'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import WcIcon from '@material-ui/icons/Wc'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import LinkIcon from '@material-ui/icons/Link'
import { useAuthUser } from 'components/modules/AuthUserContext'

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: Date) {
    return format(date, 'yyyy年 MMM', { locale: this.locale })
  }
  getDatePickerHeaderText(date: Date) {
    return format(date, 'MMMd日(E)', { locale: this.locale })
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    formControl: {
      width: '100%',
      marginTop: '13px',
      marginBottom: '8px',
    },
    errorMessage: {
      color: 'red',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px',
    },
    submitButton: {
      margin: '5px',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    border: {
      borderTop: '2px solid #e5e5e5 ',
    },
    dealButton: {
      margin: '16px',
    },
    topButton: {
      margin: theme.spacing(1),
    },
    betweenButton: {
      margin: theme.spacing(1),
      marginTop: '40px',
    },
    contractButton: {
      margin: theme.spacing(1),
      backgroundColor: '#08a742',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '14px',
      '&:hover': {
        background: '#30b560',
      },
    },
    lostButton: {
      margin: theme.spacing(1),
      backgroundColor: '#f94839',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '14px',
      '&:hover': {
        background: '#fa6559',
      },
    },
    pipedriveButton: {
      margin: theme.spacing(1),
      fontWeight: 'bold',
      fontSize: '14px',
    },
    beforeModified: {
      color: 'green',
      margin: theme.spacing(1),
      paddingTop: '12px',
    },
  })
)

type SnackBarParam = {
  open: boolean
  severity: 'error' | 'success' | 'warning' | 'info' | undefined
  message: string
}

const SigyoDisplay: React.VFC<PipedriveTotal> = ({
  pipedrive,
}): JSX.Element => {
  const authUser = useAuthUser()
  const classes = useStyles()
  const [snackBar, setSnackBar] = useState<SnackBarParam>({
    open: false,
    severity: undefined,
    message: '',
  })
  // ダイアログ用
  const handleClose = () => {
    setSnackBar({
      open: false,
      severity: undefined,
      message: '',
    })
  }

  const [orgAddress, setOrgAddress] = useState<string>(pipedrive.org.address)
  const [personAddress, setPersonAddress] = useState<string | undefined>(
    pipedrive.person?.address
  )

  const [beforeModified, setbeforeModified] = useState<string>('')

  // 問い合わせ経路
  const [financyRoute, setFinancyRoute] = useState<FormSelectObject>({
    id: Number(pipedrive.deal.fi_inquiry_route),
    name: String(
      pipedrive.dealFields
        .filter((v) => v.id == 12492)
        .map((contactMethod) =>
          contactMethod.options
            .filter((v) => v.id == pipedrive.deal.fi_inquiry_route)
            .map((v) => v.label)
        )[0]
    ),
  })

  // 開業予定都道府県
  const [openingPrefecture, setOpeningPrefecture] = useState<FormSelectObject>({
    id: Number(pipedrive.org.opening_prefecture),
    name: String(
      pipedrive.orgFields
        .filter((v) => v.id == 4056)
        .map((openingPrefecture) =>
          openingPrefecture.options
            .filter((v) => v.id == pipedrive.org.opening_prefecture)
            .map((v) => v.label)
        )[0]
    ),
  })

  // 紹介元（組織）
  const [referralSource, setReferralSource] = useState<FormSelectObject>({
    id: pipedrive.deal.fi_referral_source?.value,
    name: pipedrive.deal.fi_referral_source?.name,
  })

  const [referralSourceList, setReferralSourceList] = useState<FormSelectList>([
    {},
  ])

  // 紹介元担当者
  const [referralSourceManager, setReferralSourceManager] =
    useState<FormSelectObject>({
      id: pipedrive.deal.fi_referral_source_manager?.value,
      name: pipedrive.deal.fi_referral_source_manager?.name,
    })

  const [referralSourceManagerList, setReferralSourceManagerList] =
    useState<FormSelectList>([{}])

  // 士業紹介顧客
  const [prReferralCustomer, setPrReferralCustomer] =
    useState<FormSelectObject>({
      id: pipedrive.deal.pr_referral_customer?.value,
      name: pipedrive.deal.pr_referral_customer?.name,
    })

  const [prReferralCustomerList, setPrReferralCustomerList] =
    useState<FormSelectList>([{}])

  const [loading, setLoading] = useState(true)

  const tmpPrSupportSubsidyTypeList: string[] = []

  pipedrive.orgFields
    .filter((v) => v.id == 4090)
    .map((prSupportSubsidyType) =>
      prSupportSubsidyType.options.map((e) =>
        pipedrive.org.pr_support_subsidy_type?.split(',').includes(String(e.id))
          ? tmpPrSupportSubsidyTypeList.push(e.label)
          : ''
      )
    )

  const [prSupportSubsidyType, setPrSupportSubsidyType] = useState<string[]>(
    tmpPrSupportSubsidyTypeList
  )

  const tmpStageList: FormSelectList = [{}]

  pipedrive.stageList
    .filter(
      (v) =>
        v.active_flag == true && v.pipeline_id == pipedrive.deal.pipeline_id
    )
    .map((stage) =>
      tmpStageList.push({
        id: stage.id,
        name: stage.name,
      })
    )

  tmpStageList.shift()

  const [stageList, setStageList] = useState<FormSelectList>(tmpStageList)

  const contactMethodList: FormSelectList = [{}]
  const openingPrefectureList: FormSelectList = [{}]
  const prSupportSubsidyTypeList: FormSelectList = [{}]

  // 問い合わせ経路
  pipedrive.dealFields
    .filter((v) => v.id == 12492)
    .map((contactMethod) =>
      contactMethod.options.map((e) =>
        contactMethodList?.push({
          id: e.id,
          name: e.label,
        })
      )
    )
  contactMethodList.shift()

  // 開業予定都道府県
  pipedrive.orgFields
    ?.filter((v) => v.id == 4056)
    .map((openingPrefecture) =>
      openingPrefecture.options.map((e) =>
        openingPrefectureList?.push({
          id: e.id,
          name: e.label,
        })
      )
    )
  openingPrefectureList.shift()

  pipedrive.orgFields
    .filter((v) => v.id == 4090)
    .map((prSupportSubsidyType) =>
      prSupportSubsidyType.options.map((e) =>
        prSupportSubsidyTypeList?.push({
          id: e.id,
          name: e.label,
        })
      )
    )
  prSupportSubsidyTypeList.shift()

  // カンマ区切りに切り出し
  const numberFormat = (num: number): string => {
    return num.toLocaleString()
  }

  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
    trigger,
    unregister,
    getValues,
  } = useForm<DealFields>({
    mode: 'all',
    defaultValues: {
      dealUserId: pipedrive.deal.user_id?.value,
      probability: pipedrive.deal.probability,
      expectedCloseDate: pipedrive.deal.expected_close_date,
      orgOwnerId: pipedrive.org.owner_id?.value,
      personOwnerId: pipedrive.person?.owner_id?.value,
      pipelineId: pipedrive.deal.pipeline_id,
      stageId: pipedrive.deal.stage_id,
      inquiryRoute: pipedrive.deal.fi_inquiry_route,
      desiredAmountFrom: pipedrive.deal.fi_desired_amount_from
        ? numberFormat(pipedrive.deal.fi_desired_amount_from)
        : '',
      desiredAmountTo: pipedrive.deal.fi_desired_amount_to
        ? numberFormat(pipedrive.deal.fi_desired_amount_to)
        : '',
      decidedAmount: pipedrive.deal.fi_decided_amount
        ? numberFormat(pipedrive.deal.fi_decided_amount)
        : '',
      requestedDocument: pipedrive.deal.fi_requested_document,
      referralSource: pipedrive.deal.fi_referral_source?.value,
      referralSourceManager: pipedrive.deal.fi_referral_source_manager?.value,
      consultationContent: pipedrive.deal.consultation_content,
      consultationContentForm: pipedrive.deal.consultation_content_form,
      remark: pipedrive.deal.remark,
      remarkSecond: pipedrive.deal.remark_second,
      inSubject: pipedrive.deal.in_subject,
      inBillDate: pipedrive.deal.in_bill_date,
      inPaymentDeadline: pipedrive.deal.in_payment_deadline,
      inSendType: pipedrive.deal.in_send_type,
      inBillFlg: pipedrive.deal.in_bill_flg,
      inPaymentStatus: pipedrive.deal.in_payment_status,
      inPaymentDay: pipedrive.deal.in_payment_day,
      inOriginTransactionDeal: pipedrive.deal.in_origin_transaction_deal,
      prReferralFee: pipedrive.deal.pr_referral_fee
        ? numberFormat(pipedrive.deal.pr_referral_fee)
        : '',
      prReferralCustomer: pipedrive.deal.pr_referral_customer?.value,
      prBusinessNegotiationDate: pipedrive.deal.pr_business_negotiation_date,
      prBusinessNegotiationTime: pipedrive.deal.pr_business_negotiation_time,
      paPaymentDeadline: pipedrive.deal.pa_payment_deadline,
      paApprovalNumber: pipedrive.deal.pa_approval_number,
      contractStartDate: pipedrive.deal.contract_start_date,
      orgName: pipedrive.org.name,
      orgAddress: pipedrive.org.address,
      orgAddressBuildings: pipedrive.org.address_buildings,
      representative: pipedrive.org.representative,
      representativeKana: pipedrive.org.representative_kana,
      orgTel: pipedrive.org.tel,
      orgTelSecond: pipedrive.org.tel_second,
      openingTime: pipedrive.org.opening_time,
      openingPrefecture: pipedrive.org.opening_prefecture,
      foundedStatus: pipedrive.org.founded_status,
      foundedDate: pipedrive.org.founded_date,
      startupType: pipedrive.org.startup_type,
      closingMonth: pipedrive.org.closing_month,
      prContractContent: pipedrive.org.pr_contract_content,
      prMarketSuggest: pipedrive.org.pr_market_suggest,
      prGreatsignSuggest: pipedrive.org.pr_greatsign_suggest,
      prZeiriseeStatus: pipedrive.org.pr_zeirisee_status,
      prTemplate: pipedrive.org.pr_template,
      prFinanceIntroduce: pipedrive.org.pr_finance_introduce,
      prSubsidyIntroduce: pipedrive.org.pr_subsidy_introduce,
      prTaxAccountantIntroduce: pipedrive.org.pr_tax_accountant_introduce,
      prOfficeEvaluate: pipedrive.org.pr_office_evaluate,
      prWordpress: pipedrive.org.pr_wordpress,
      conclusionDate: pipedrive.org.conclusion_date,
      prNegotiateContent: pipedrive.org.pr_negotiate_content,
      url: pipedrive.org.url,
      prQuoteSite: pipedrive.org.pr_quote_site,
      office: pipedrive.org.office,
      orgRemark: pipedrive.org.remark,
      prRewardContent: pipedrive.org.pr_reward_content,
      orgPrReferralFee: pipedrive.org.pr_referral_fee
        ? numberFormat(pipedrive.org.pr_referral_fee)
        : '',
      prSupportSubsidyType: pipedrive.org.pr_support_subsidy_type
        ? pipedrive.org.pr_support_subsidy_type?.split(',')
        : [],
      prContactTool: pipedrive.org.pr_contact_tool,
      staffNumber: pipedrive.org.staff_number,
      personName: pipedrive.person?.name,
      personTel: pipedrive.person?.phone.map((v) => v.value).join(','),
      personEmail: pipedrive.person?.email.map((v) => v.value).join(','),
      mailSend: pipedrive.person?.mail_send,
      prFacebookAccount: pipedrive.person?.pr_facebook_account,
      prFacebookStatus: pipedrive.person?.pr_facebook_status,
      taxIntroduction: pipedrive.person?.tax_introduction,
      kana: pipedrive.person?.kana,
      housingLoan: pipedrive.person?.housing_loan,
      monthlyRepaymentAmountOfHousing: pipedrive.person
        ?.monthly_repayment_amount_of_housing
        ? numberFormat(pipedrive.person.monthly_repayment_amount_of_housing)
        : '',
      remainingRepaymentPeriodForHousing:
        pipedrive.person?.remaining_repayment_period_for_housing,
      housingBalance: pipedrive.person?.housing_balance
        ? numberFormat(pipedrive.person.housing_balance)
        : '',
      housingBuyTime: pipedrive.person?.housing_buy_time,
      personAddress: pipedrive.person?.address,
      personAddressBuildings: pipedrive.person?.address_buildings,
      personRemark: pipedrive.person?.remark,
      sex: pipedrive.person?.sex,
      industryExperience: pipedrive.person?.industry_experience,
      industryExperienceYears: pipedrive.person?.industry_experience_years,
      birthday: pipedrive.person?.birthday,
      rentOrHouse: pipedrive.person?.rent_or_house,
      spouse: pipedrive.person?.spouse,
      spouseIndustry: pipedrive.person?.spouse_industry,
      spouseSalary: pipedrive.person?.spouse_salary
        ? numberFormat(pipedrive.person.spouse_salary)
        : '',
      personTelSecond: pipedrive.person?.tel_second,
      customerRank: pipedrive.person?.customer_rank,
      orgPostCode: pipedrive.org.post_code,
      personPostCode: pipedrive.person?.post_code,
    },
  })

  // 登録時処理
  const handleOnSubmit = async (data: DealFields) => {
    setLoading(true)

    const pipedriveApiToken = process.env.REACT_APP_PIPEDRIVE_API_TOKEN || ''

    if (!process.env.REACT_APP_PIPEDRIVE_API_TOKEN) {
      throw new Error(
        '環境変数 REACT_APP_PIPEDRIVE_API_TOKEN が設定されていません。'
      )
    }
    const apiToken =
      authUser?.pipedrive_token != null
        ? authUser?.pipedrive_token
        : pipedriveApiToken

    try {
      const dealResult = await updateDeal(data, apiToken)
      if (!dealResult) throw new Error('deal update error')
      const orgResult = await updateOrg(data, apiToken)
      if (!orgResult) throw new Error('org update error')
      const personResult = await updatePerson(data, apiToken)
      if (!personResult) throw new Error('person update error')

      setSnackBar({
        open: true,
        severity: 'success',
        message: '取引情報を更新しました',
      })
    } catch (e) {
      console.log(e)
      setSnackBar({
        open: true,
        severity: 'error',
        message: '取引情報の更新に失敗しました',
      })
    }

    const now = new Date()

    setbeforeModified(
      now.getFullYear() +
        '年' +
        (now.getMonth() + 1) +
        '月' +
        now.getDate() +
        '日 ' +
        now.getHours() +
        '時' +
        now.getMinutes() +
        '分'
    )

    setLoading(false)
  }

  // 成約ボタンダイアログ設定
  const [contractOpen, setContractOpen] = useState(false)

  const handleClickContractOpen = (formError: boolean): void => {
    if (!formError) {
      setSnackBar({
        open: true,
        severity: 'error',
        message: '必須項目が未入力のため成約に変更できません',
      })
      return
    }
    setContractOpen(true)
  }

  const handleContractClose = () => {
    setContractOpen(false)
  }

  const handleClickContract = async () => {
    const pipedriveApiToken = process.env.REACT_APP_PIPEDRIVE_API_TOKEN || ''

    if (!process.env.REACT_APP_PIPEDRIVE_API_TOKEN) {
      throw new Error(
        '環境変数 REACT_APP_PIPEDRIVE_API_TOKEN が設定されていません。'
      )
    }
    const apiToken =
      authUser?.pipedrive_token != null
        ? authUser?.pipedrive_token
        : pipedriveApiToken
    // 成約処理
    contractDeal(pipedrive.deal.id, apiToken)
    pipedrive.deal.status = 'won'
    setContractOpen(false)
  }

  // 失注ボタンダイアログ設定
  const [lostOpen, setLostOpen] = useState(false)

  const handleClickLostOpen = (formError: boolean): void => {
    if (!formError) {
      setSnackBar({
        open: true,
        severity: 'error',
        message: '必須項目が未入力のため失注に変更できません',
      })
      return
    }
    setLostOpen(true)
  }

  const handleLostClose = () => {
    setLostOpen(false)
  }

  const handleClickLost = async () => {
    const pipedriveApiToken = process.env.REACT_APP_PIPEDRIVE_API_TOKEN || ''

    if (!process.env.REACT_APP_PIPEDRIVE_API_TOKEN) {
      throw new Error(
        '環境変数 REACT_APP_PIPEDRIVE_API_TOKEN が設定されていません。'
      )
    }
    const apiToken =
      authUser?.pipedrive_token != null
        ? authUser?.pipedrive_token
        : pipedriveApiToken
    // 失注処理
    lostDeal(pipedrive.deal.id, getValues('lostReason'), apiToken)
    pipedrive.deal.status = 'lost'
    setLostOpen(false)
  }

  // 紹介元（組織）
  useEffect(() => {
    const setOrgForReferralSource = async () => {
      const referralSourceList: FormSelectList = [{}]
      // 該当の組織を取得
      const orgList = await searchOrgListByName(referralSource.name)

      orgList.map((v) => {
        referralSourceList.push({
          id: v.item.id,
          name: v.item.name,
        })
      })

      referralSourceList.shift()

      setReferralSourceList(referralSourceList)
    }

    if (referralSource.name) {
      setOrgForReferralSource()
    }
  }, [referralSource])

  // 紹介元担当者
  useEffect(() => {
    const setPersonForReferralSourceManager = async () => {
      const referralSourceManagerList: FormSelectList = [{}]
      // 該当の人物を取得
      const personList = await searchPersonListByName(
        referralSourceManager.name
      )

      personList.map((v) => {
        referralSourceManagerList.push({
          id: v.item.id,
          name: v.item.name,
        })
      })

      referralSourceManagerList.shift()

      setReferralSourceManagerList(referralSourceManagerList)
    }

    if (referralSourceManager.name) {
      setPersonForReferralSourceManager()
    }
  }, [referralSourceManager])

  // 士業紹介顧客
  useEffect(() => {
    const setPersonForPrReferralCustomer = async () => {
      const prReferralCustomerList: FormSelectList = [{}]
      // 該当の人物を取得
      const personList = await searchPersonListByName(prReferralCustomer.name)

      personList.map((v) => {
        prReferralCustomerList.push({
          id: v.item.id,
          name: v.item.name,
        })
      })

      prReferralCustomerList.shift()

      setPrReferralCustomerList(prReferralCustomerList)
    }

    if (prReferralCustomer.name) {
      setPersonForPrReferralCustomer()
    }
    setLoading(false)
  }, [prReferralCustomer])

  // パイプライン変更時ステージ更新
  const setStage = (e: React.ChangeEvent<{ value: unknown }>): void => {
    const tmpStageList: FormSelectList = [{}]

    pipedrive.stageList
      .filter((v) => v.pipeline_id == Number(e.target.value))
      .map((v) =>
        tmpStageList.push({
          id: v.id,
          name: v.name,
        })
      )

    tmpStageList.shift()
    setStageList(tmpStageList)
  }

  // 組織郵便番号変更時
  const setOrgAddressByPostCode = (postCode: string) => {
    fetch(`https://api.zipaddress.net/?zipcode=${postCode}`, {
      mode: 'cors',
    })
      .then((result) => {
        return result.json()
      })
      .then((result) => {
        setOrgAddress(result.data?.fullAddress || '')
      })
  }

  // 人物郵便番号変更時
  const setPersonAddressByPostCode = (postCode: string) => {
    fetch(`https://api.zipaddress.net/?zipcode=${postCode}`, {
      mode: 'cors',
    })
      .then((result) => {
        return result.json()
      })
      .then((result) => {
        setPersonAddress(result.data?.fullAddress || '')
      })
  }

  // 読み込み終了までローディング
  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress />
      </Backdrop>
    )
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Input
        {...register('dealId')}
        id="dealId"
        type="hidden"
        value={pipedrive.deal.id}
      />
      <Input
        {...register('orgId')}
        id="orgId"
        type="hidden"
        value={pipedrive.org.id}
      />
      <Input
        {...register('personId')}
        id="personId"
        type="hidden"
        value={pipedrive.person?.id}
      />
      <Grid container spacing={3} item xs={12}>
        <Grid container xs={12} md={6} lg={3}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className={classes.dealButton}
            startIcon={<MonetizationOnIcon />}
          >
            取引
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.pipedriveButton}
            target="_blank"
            startIcon={<LinkIcon />}
            href={`https://solabo.pipedrive.com/deal/${pipedrive.deal.id}`}
          >
            Pipedrive
          </Button>
          {beforeModified && (
            <span className={classes.beforeModified}>
              前回更新時間：{beforeModified}
            </span>
          )}
        </Grid>
        <Grid container xs={10} justify="flex-end">
          {pipedrive.deal.status == 'open' && (
            <>
              <Button
                variant="contained"
                size="large"
                className={classes.contractButton}
                onClick={async () => {
                  // 成約するためのその他エラーチェック
                  const formError = await trigger()
                  handleClickContractOpen(formError)
                }}
              >
                成約
              </Button>
              <Dialog
                open={contractOpen}
                onClose={handleContractClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{'成約確認'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Typography gutterBottom>
                      取引のステータスを「
                      <span style={{ color: '#08a742', fontWeight: 'bold' }}>
                        成約
                      </span>
                      」へ変更します。よろしいですか？
                    </Typography>
                    <Typography color="error">
                      ※ 入力内容が同時に登録されます
                    </Typography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleContractClose} color="primary">
                    キャンセル
                  </Button>
                  <Button
                    onClick={handleSubmit(async (data) => {
                      await handleOnSubmit(data)
                      handleClickContract()
                    })}
                    color="primary"
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              <Button
                variant="contained"
                size="large"
                className={classes.lostButton}
                onClick={async () => {
                  // 失注するためのその他エラーチェック
                  const formError = await trigger()
                  handleClickLostOpen(formError)
                }}
              >
                失注
              </Button>
              <Dialog
                open={lostOpen}
                onClose={handleLostClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">失注確認</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <Typography gutterBottom>
                      取引のステータスを「
                      <span style={{ color: '#f94839', fontWeight: 'bold' }}>
                        失注
                      </span>
                      」へ変更します。よろしいですか？
                    </Typography>
                    <Typography color="error">
                      ※ 入力内容が同時に登録されます
                    </Typography>
                  </DialogContentText>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="owner-select-label">失注理由</InputLabel>
                    <Controller
                      name="lostReason"
                      control={control}
                      rules={{ required: '※ 失注理由を選択してください' }}
                      render={({ field }) => (
                        <Select {...field}>
                          <MenuItem value="">選択してください</MenuItem>
                          {pipedrive.dealFields
                            .filter((v) => v.id == 12475)
                            .map((lostReason) =>
                              lostReason.options
                                .filter(
                                  (v) =>
                                    v.label.match('【共】') ||
                                    v.label.match('【士】')
                                )
                                .map((e) => (
                                  <MenuItem key={e.id} value={e.label}>
                                    {e.label}
                                  </MenuItem>
                                ))
                            )}
                        </Select>
                      )}
                    />
                  </FormControl>
                  {errors.lostReason && (
                    <span className={classes.errorMessage}>
                      {errors.lostReason.message}
                    </span>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleLostClose} color="primary">
                    キャンセル
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleSubmit(async (data) => {
                      await handleOnSubmit(data)
                      handleClickLost()
                    })}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
          {pipedrive.deal.status == 'won' && (
            <>
              <Button
                variant="contained"
                size="large"
                className={classes.contractButton}
              >
                成約済み
              </Button>
            </>
          )}
          {pipedrive.deal.status == 'lost' && (
            <>
              <Button
                variant="contained"
                size="large"
                className={classes.lostButton}
              >
                失注済み
              </Button>
            </>
          )}
          {pipedrive.deal.status == 'deleted' && (
            <>
              <Button
                variant="contained"
                size="large"
                className={classes.lostButton}
              >
                削除済み
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">オーナー</InputLabel>
              <Controller
                name="dealUserId"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.ownerList
                      .filter((v) => v.active_flag == true)
                      .map((dealOwner) => (
                        <MenuItem key={dealOwner.id} value={dealOwner.id}>
                          {dealOwner.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="grouped-select" style={{ color: 'red' }}>
                パイプライン
              </InputLabel>
              <Controller
                name="pipelineId"
                control={control}
                rules={{
                  required: '※ パイプラインを選択してください',
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                      setStage(e)
                    }}
                  >
                    <MenuItem value="">
                      <em>選択してください</em>
                    </MenuItem>
                    {pipedrive.pipelineList.map((pipeline) => (
                      <MenuItem key={pipeline.id} value={pipeline.id}>
                        {pipeline.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            {errors.pipelineId && (
              <span className={classes.errorMessage}>
                {errors.pipelineId.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="grouped-select" style={{ color: 'red' }}>
                ステージ
              </InputLabel>
              <Controller
                name="stageId"
                control={control}
                rules={{
                  required: '※ ステージを選択してください',
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={async (e) => {
                      await field.onChange(e)
                      trigger([
                        'prReferralFee',
                        'prReferralCustomer',
                        'prNegotiateContent',
                        'prRewardContent',
                        'prContractContent',
                        'prSupportSubsidyType',
                        'prTaxAccountantIntroduce',
                        'prReferralFee',
                        'prFinanceIntroduce',
                        'prSubsidyIntroduce',
                        'prContactTool',
                        'orgPrReferralFee',
                      ])
                    }}
                  >
                    <MenuItem value="">
                      <em>選択してください</em>
                    </MenuItem>
                    {stageList.map((stage) => (
                      <MenuItem key={stage.id} value={stage.id}>
                        {stage.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            {errors.stageId && (
              <span className={classes.errorMessage}>
                {errors.stageId.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('probability', {
                  pattern: {
                    value: /^([1-9]?[0-9]|100)$/,
                    message: '※ 0から100の数値を入力してください',
                  },
                })}
                variant="standard"
                fullWidth
                id="probability"
                label="確率"
                autoComplete="probability"
              />
            </FormControl>
            {errors.probability && (
              <span className={classes.errorMessage}>
                {errors.probability.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Controller
                name="expectedCloseDate"
                control={control}
                rules={{
                  required: '※ 終了予定日を選択してください',
                }}
                render={({ field }) => (
                  <MuiPickersUtilsProvider
                    utils={ExtendedUtils}
                    locale={jaLocale}
                  >
                    <KeyboardDatePicker
                      {...field}
                      label="終了予定日"
                      value={field.value}
                      format="yyyy-MM-dd"
                      autoOk={true}
                      variant="inline"
                      onChange={(_date, v) => {
                        setValue('expectedCloseDate', v)
                      }}
                      invalidDateMessage="※ フォーマットに従って入力してください"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </FormControl>

            {errors.expectedCloseDate && (
              <span className={classes.errorMessage}>
                {errors.expectedCloseDate.message}
              </span>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={6} md={3} lg={2}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                {...register('inquiryRoute')}
                id="combo-box-demo"
                options={contactMethodList}
                getOptionLabel={(option) => option.name ?? ''}
                getOptionSelected={(option) => option.id == financyRoute.id}
                value={financyRoute}
                onChange={(_e, options) => {
                  setValue('inquiryRoute', options?.id)
                  setFinancyRoute({ id: options?.id, name: options?.name })
                }}
                renderInput={(params) => (
                  <TextField {...params} label="問い合わせ経路" />
                )}
              />
            </FormControl>
            {errors.inquiryRoute && (
              <span className={classes.errorMessage}>
                {errors.inquiryRoute.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">
                相談内容（フォーム）
              </InputLabel>
              <Controller
                name="consultationContentForm"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.dealFields
                      .filter((v) => v.id == 12567)
                      .map((consultationContentForm) =>
                        consultationContentForm.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                {...register('referralSource')}
                id="combo-box-demo"
                options={referralSourceList}
                getOptionLabel={(option) => option.name ?? ''}
                getOptionSelected={(option) => option.id == referralSource.id}
                value={referralSource}
                onChange={(_e, options) => {
                  setValue('referralSource', options?.id)
                  setReferralSource({ id: options?.id, name: options?.name })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="紹介元"
                    onKeyDown={(e) => {
                      if (e.code == 'Enter') {
                        setReferralSource({
                          name: (e.target as HTMLInputElement).value,
                        })
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                {...register('referralSourceManager')}
                id="combo-box-demo"
                options={referralSourceManagerList}
                getOptionLabel={(option) => option.name ?? ''}
                getOptionSelected={(option) =>
                  option.id == referralSourceManager.id
                }
                value={referralSourceManager}
                onChange={(_e, options) => {
                  setValue('referralSourceManager', options?.id)
                  setReferralSourceManager({
                    id: options?.id,
                    name: options?.name,
                  })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="紹介元担当者"
                    onKeyDown={(e) => {
                      if (e.code == 'Enter') {
                        setReferralSourceManager({
                          name: (e.target as HTMLInputElement).value,
                        })
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('consultationContent')}
                multiline
                variant="outlined"
                fullWidth
                id="consultationContent"
                label="相談内容"
                autoComplete="consultationContent"
                minRows={3}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('remark')}
                multiline
                variant="outlined"
                fullWidth
                id="remark"
                label="備考"
                autoComplete="remark"
                minRows={3}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('remarkSecond')}
                multiline
                variant="outlined"
                fullWidth
                id="remarkSecond"
                label="備考2"
                autoComplete="remarkSecond"
                minRows={3}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.topButton}
        startIcon={<LocalAtmIcon />}
      >
        請求
      </Button>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={3} lg={2}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('inSubject')}
                variant="standard"
                fullWidth
                id="inSubject"
                label="請求件名"
                autoComplete="inSubject"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Controller
                name="inBillDate"
                control={control}
                render={({ field }) => (
                  <MuiPickersUtilsProvider
                    utils={ExtendedUtils}
                    locale={jaLocale}
                    {...field}
                  >
                    <KeyboardDatePicker
                      label="請求日"
                      value={field.value}
                      format="yyyy-MM-dd"
                      autoOk={true}
                      variant="inline"
                      onChange={(_date, v) => {
                        setValue('inBillDate', v)
                      }}
                      invalidDateMessage="※ フォーマットに従って入力してください"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Controller
                name="inPaymentDeadline"
                control={control}
                render={({ field }) => (
                  <MuiPickersUtilsProvider
                    utils={ExtendedUtils}
                    locale={jaLocale}
                  >
                    <KeyboardDatePicker
                      {...field}
                      label="支払期限日"
                      value={field.value}
                      format="yyyy-MM-dd"
                      autoOk={true}
                      variant="inline"
                      onChange={(_date, v) => {
                        setValue('inPaymentDeadline', v)
                      }}
                      invalidDateMessage="※ フォーマットに従って入力してください"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">送付タイプ</InputLabel>
              <Controller
                name="inSendType"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.dealFields
                      .filter((v) => v.id == 12579)
                      .map((inSendType) =>
                        inSendType.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">請求フラグ</InputLabel>
              <Controller
                name="inBillFlg"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.dealFields
                      .filter((v) => v.id == 12580)
                      .map((inBillFlg) =>
                        inBillFlg.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">入金状況</InputLabel>
              <Controller
                name="inPaymentStatus"
                control={control}
                render={({ field }) => (
                  <Select {...field} disabled>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.dealFields
                      .filter((v) => v.id == 12581)
                      .map((inPaymentStatus) =>
                        inPaymentStatus.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Controller
                name="inPaymentDay"
                control={control}
                render={({ field }) => (
                  <MuiPickersUtilsProvider
                    utils={ExtendedUtils}
                    locale={jaLocale}
                  >
                    <KeyboardDatePicker
                      {...field}
                      disabled
                      label="入金日"
                      value={field.value}
                      format="yyyy-MM-dd"
                      autoOk={true}
                      variant="inline"
                      onChange={(_date, v) => {
                        setValue('inPaymentDay', v)
                      }}
                      invalidDateMessage="※ フォーマットに従って入力してください"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('inOriginTransactionDeal')}
                variant="standard"
                fullWidth
                id="inOriginTransactionDeal"
                label="生成元取引ID"
                autoComplete="inOriginTransactionDeal"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Controller
                name="paPaymentDeadline"
                control={control}
                render={({ field }) => (
                  <MuiPickersUtilsProvider
                    utils={ExtendedUtils}
                    locale={jaLocale}
                  >
                    <KeyboardDatePicker
                      {...field}
                      label="支払期限日(SoLaboから)"
                      value={field.value}
                      format="yyyy-MM-dd"
                      autoOk={true}
                      variant="inline"
                      onChange={(_date, v) => {
                        setValue('paPaymentDeadline', v)
                      }}
                      invalidDateMessage="※ フォーマットに従って入力してください"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('paApprovalNumber')}
                variant="standard"
                fullWidth
                id="paApprovalNumber"
                label="稟議番号"
                autoComplete="paApprovalNumber"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.topButton}
        startIcon={<BusinessCenterIcon />}
      >
        士業
      </Button>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('prReferralFee', {
                  required:
                    watch('stageId') == 20
                      ? '※ 紹介手数料を選択してください'
                      : false,
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                variant="standard"
                fullWidth
                id="prReferralFee"
                label={
                  <span style={watch('stageId') == 20 ? { color: 'red' } : {}}>
                    紹介手数料
                  </span>
                }
                autoComplete="prReferralFee"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue('prReferralFee', numberFormat(Number(amount)))
                  }
                }}
              />
            </FormControl>
            {errors.prReferralFee && (
              <span className={classes.errorMessage}>
                {errors.prReferralFee.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                {...register('prReferralCustomer', {
                  required: [18, 19, 20].includes(watch('stageId'))
                    ? '※ 士業紹介顧客を選択してください'
                    : false,
                })}
                id="combo-box-demo"
                options={prReferralCustomerList}
                getOptionLabel={(option) => option.name ?? ''}
                getOptionSelected={(option) =>
                  option.id == prReferralCustomer.id
                }
                value={prReferralCustomer}
                onChange={(_e, options) => {
                  setValue('prReferralCustomer', options?.id)
                  setPrReferralCustomer({
                    id: options?.id,
                    name: options?.name,
                  })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span
                        style={
                          [18, 19, 20].includes(watch('stageId'))
                            ? { color: 'red' }
                            : {}
                        }
                      >
                        士業紹介顧客
                      </span>
                    }
                    onKeyDown={(e) => {
                      if (e.code == 'Enter') {
                        setPrReferralCustomer({
                          name: (e.target as HTMLInputElement).value,
                        })
                      }
                    }}
                  />
                )}
              />
            </FormControl>
            {errors.prReferralCustomer && (
              <span className={classes.errorMessage}>
                {errors.prReferralCustomer.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Controller
                name="prBusinessNegotiationDate"
                control={control}
                render={({ field }) => (
                  <MuiPickersUtilsProvider
                    utils={ExtendedUtils}
                    locale={jaLocale}
                  >
                    <KeyboardDatePicker
                      {...field}
                      label="商談日"
                      value={field.value}
                      format="yyyy-MM-dd"
                      autoOk={true}
                      variant="inline"
                      onChange={(_date, v) => {
                        setValue('prBusinessNegotiationDate', v)
                      }}
                      invalidDateMessage="※ フォーマットに従って入力してください"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('prBusinessNegotiationTime')}
                variant="standard"
                fullWidth
                id="prBusinessNegotiationTime"
                label="商談時間"
                autoComplete="prBusinessNegotiationTime"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {/* 組織 */}
      <Button
        variant="contained"
        color="secondary"
        size="small"
        className={classes.betweenButton}
        startIcon={<BusinessIcon />}
      >
        組織
      </Button>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">オーナー</InputLabel>
              <Controller
                name="orgOwnerId"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.ownerList
                      .filter((v) => v.active_flag == true)
                      .map((orgOwner) => (
                        <MenuItem key={orgOwner.id} value={orgOwner.id}>
                          {orgOwner.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('orgName', {
                required: '※ 組織名を入力してください',
              })}
              margin="normal"
              fullWidth
              id="orgName"
              label={<span style={{ color: 'red' }}>組織名</span>}
              size="small"
            />
            {errors.orgName && (
              <span className={classes.errorMessage}>
                {errors.orgName.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('representative', {
                required: '※ 代表者名を入力してください',
              })}
              margin="normal"
              fullWidth
              id="representative"
              label={<span style={{ color: 'red' }}>代表者名</span>}
              size="small"
            />
            {errors.representative && (
              <span className={classes.errorMessage}>
                {errors.representative.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('representativeKana', {
                required: '※ 代表者名ふりがなを入力してください',
                pattern: {
                  value: /^[ぁ-んー　]+$/,
                  message: '※ ふりがなのみで入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="representativeKana"
              label={<span style={{ color: 'red' }}>代表者名ふりがな</span>}
              size="small"
            />
            {errors.representativeKana && (
              <span className={classes.errorMessage}>
                {errors.representativeKana.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('orgTel', {
                required: '※ 電話番号を入力してください',
                pattern: {
                  value: /^0\d{1,4}-\d{1,4}-\d{4}$/,
                  message: '※ 電話番号形式で入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="orgTel"
              label="電話番号"
              size="small"
            />
            {errors.orgTel && (
              <span className={classes.errorMessage}>
                {errors.orgTel.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('orgTelSecond', {
                pattern: {
                  value: /^0\d{1,4}-\d{1,4}-\d{4}$/,
                  message: '※ 電話番号形式で入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="orgTelSecond"
              label="電話番号2"
              size="small"
            />
            {errors.orgTelSecond && (
              <span className={classes.errorMessage}>
                {errors.orgTelSecond.message}
              </span>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={6} lg={2}>
            <TextField
              {...register('orgPostCode', {
                pattern: {
                  value: /^[0-9]{3}-[0-9]{4}$/,
                  message: '※ ハイフン入り(3-4)形式で入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="orgPostCode"
              label="郵便番号"
              size="small"
              onBlur={(e) => setOrgAddressByPostCode(e.target.value)}
            />
            {errors.orgPostCode && (
              <span className={classes.errorMessage}>
                {errors.orgPostCode.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('orgAddress', {
                required: '※ 住所を入力してください',
              })}
              margin="normal"
              fullWidth
              id="orgAddress"
              label={<span style={{ color: 'red' }}>住所</span>}
              size="small"
              value={orgAddress}
              onChange={(e) => setOrgAddress(e.target.value)}
            />
            {errors.orgAddress && (
              <span className={classes.errorMessage}>
                {errors.orgAddress.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('orgAddressBuildings')}
              margin="normal"
              fullWidth
              id="orgAddressBuildings"
              label="住所(ビル・マンション)"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Controller
                name="openingTime"
                control={control}
                render={({ field }) => (
                  <MuiPickersUtilsProvider
                    utils={ExtendedUtils}
                    locale={jaLocale}
                  >
                    <KeyboardDatePicker
                      {...field}
                      label="開業時期"
                      value={field.value}
                      format="yyyy-MM-dd"
                      autoOk={true}
                      variant="inline"
                      onChange={(_date, v) => {
                        setValue('openingTime', v)
                      }}
                      invalidDateMessage="※ フォーマットに従って入力してください"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </FormControl>
            {errors.openingTime && (
              <span className={classes.errorMessage}>
                {errors.openingTime.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                {...register('openingPrefecture')}
                id="combo-box-demo"
                options={openingPrefectureList}
                getOptionLabel={(option) => option.name ?? ''}
                getOptionSelected={(option) =>
                  option.id == openingPrefecture.id
                }
                value={openingPrefecture}
                onChange={(_e, options) => {
                  setValue('openingPrefecture', options?.id)
                  setOpeningPrefecture({ id: options?.id, name: options?.name })
                }}
                renderInput={(params) => (
                  <TextField {...params} label="開業予定都道府県" />
                )}
              />
            </FormControl>
            {errors.openingPrefecture && (
              <span className={classes.errorMessage}>
                {errors.openingPrefecture.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">創業状況</InputLabel>
              <Controller
                name="foundedStatus"
                control={control}
                rules={{
                  required: '※ 創業状況を選択してください',
                }}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4045)
                      .map((foundedStatus) =>
                        foundedStatus.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
            {errors.foundedStatus && (
              <span className={classes.errorMessage}>
                {errors.foundedStatus.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Controller
                name="foundedDate"
                control={control}
                render={({ field }) => (
                  <MuiPickersUtilsProvider
                    utils={ExtendedUtils}
                    locale={jaLocale}
                  >
                    <KeyboardDatePicker
                      {...field}
                      label="創業年月"
                      value={field.value}
                      format="yyyy-MM-dd"
                      autoOk={true}
                      variant="inline"
                      onChange={(_date, v) => {
                        setValue('foundedDate', v)
                      }}
                      invalidDateMessage="※ フォーマットに従って入力してください"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </FormControl>
            {errors.foundedDate && (
              <span className={classes.errorMessage}>
                {errors.foundedDate.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">起業形態</InputLabel>
              <Controller
                name="startupType"
                control={control}
                rules={{
                  required: '※ 起業形態を選択してください',
                }}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4055)
                      .map((startupType) =>
                        startupType.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
            {errors.startupType && (
              <span className={classes.errorMessage}>
                {errors.startupType.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">決算月</InputLabel>
              <Controller
                name="closingMonth"
                control={control}
                rules={{
                  required: '※ 決算月を選択してください',
                }}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4084)
                      .map((closingMonth) =>
                        closingMonth.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
            {errors.closingMonth && (
              <span className={classes.errorMessage}>
                {errors.closingMonth.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">事務所</InputLabel>
              <Controller
                name="office"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4044)
                      .map((office) =>
                        office.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('staffNumber', {
                pattern: {
                  value: /^[0-9,]+$/,
                  message: '※ 半角数字のみで入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="staffNumber"
              label="従業員数"
              size="small"
            />
            {errors.staffNumber && (
              <span className={classes.errorMessage}>
                {errors.staffNumber.message}
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.topButton}
        startIcon={<BusinessCenterIcon />}
      >
        士業
      </Button>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel
                id="owner-select-label"
                style={watch('stageId') == 38 ? { color: 'red' } : {}}
              >
                契約内容
              </InputLabel>
              <Controller
                name="prContractContent"
                control={control}
                rules={{
                  required:
                    watch('stageId') == 38
                      ? '※ 契約内容を選択してください'
                      : false,
                }}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4029)
                      .map((prContractContent) =>
                        prContractContent.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
            {errors.prContractContent && (
              <span className={classes.errorMessage}>
                {errors.prContractContent.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">マーケ提案</InputLabel>
              <Controller
                name="prMarketSuggest"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4031)
                      .map((prMarketSuggest) =>
                        prMarketSuggest.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">greatsign提案</InputLabel>
              <Controller
                name="prGreatsignSuggest"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4030)
                      .map((prGreatsignSuggest) =>
                        prGreatsignSuggest.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">税理Seeステータス</InputLabel>
              <Controller
                name="prZeiriseeStatus"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4027)
                      .map((prZeiriseeStatus) =>
                        prZeiriseeStatus.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">雛形</InputLabel>
              <Controller
                name="prTemplate"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4033)
                      .map((prTemplate) =>
                        prTemplate.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel
                id="owner-select-label"
                style={watch('stageId') == 38 ? { color: 'red' } : {}}
              >
                融資紹介
              </InputLabel>
              <Controller
                name="prFinanceIntroduce"
                control={control}
                rules={{
                  required:
                    watch('stageId') == 38
                      ? '※ 融資紹介を選択してください'
                      : false,
                }}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4034)
                      .map((prFinanceIntroduce) =>
                        prFinanceIntroduce.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
            {errors.prFinanceIntroduce && (
              <span className={classes.errorMessage}>
                {errors.prFinanceIntroduce.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel
                id="owner-select-label"
                style={watch('stageId') == 38 ? { color: 'red' } : {}}
              >
                補助金紹介
              </InputLabel>
              <Controller
                name="prSubsidyIntroduce"
                control={control}
                rules={{
                  required:
                    watch('stageId') == 38
                      ? '※ 補助金紹介を選択してください'
                      : false,
                }}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4087)
                      .map((prSubsidyIntroduce) =>
                        prSubsidyIntroduce.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
            {errors.prSubsidyIntroduce && (
              <span className={classes.errorMessage}>
                {errors.prSubsidyIntroduce.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel
                id="owner-select-label"
                style={watch('stageId') == 38 ? { color: 'red' } : {}}
              >
                税理士紹介
              </InputLabel>
              <Controller
                name="prTaxAccountantIntroduce"
                control={control}
                rules={{
                  required:
                    watch('stageId') == 38
                      ? '※ 税理士紹介を選択してください'
                      : false,
                }}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4028)
                      .map((prTaxAccountantIntroduce) =>
                        prTaxAccountantIntroduce.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
            {errors.prTaxAccountantIntroduce && (
              <span className={classes.errorMessage}>
                {errors.prTaxAccountantIntroduce.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">事務所評価</InputLabel>
              <Controller
                name="prOfficeEvaluate"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4092)
                      .map((prOfficeEvaluate) =>
                        prOfficeEvaluate.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">wordpress</InputLabel>
              <Controller
                name="prWordpress"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4035)
                      .map((prWordpress) =>
                        prWordpress.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Controller
                name="conclusionDate"
                control={control}
                render={({ field }) => (
                  <MuiPickersUtilsProvider
                    utils={ExtendedUtils}
                    locale={jaLocale}
                  >
                    <KeyboardDatePicker
                      {...field}
                      label="締結日"
                      value={field.value}
                      format="yyyy-MM-dd"
                      autoOk={true}
                      variant="inline"
                      onChange={(_date, v) => {
                        setValue('conclusionDate', v)
                      }}
                      invalidDateMessage="※ フォーマットに従って入力してください"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl className={classes.formControl}>
              <InputLabel
                id="demo-mutiple-chip-label"
                style={watch('stageId') == 38 ? { color: 'red' } : {}}
              >
                対応補助金種類
              </InputLabel>
              <Select
                {...register('prSupportSubsidyType', {
                  required:
                    watch('stageId') == 38
                      ? '※ 対応補助金を選択してください'
                      : false,
                })}
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={prSupportSubsidyType}
                input={<Input id="select-multiple-chip" />}
                onChange={(v) =>
                  setPrSupportSubsidyType(v.target.value as string[])
                }
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {(selected as string[]).map((value) => (
                      <Chip
                        size="small"
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
              >
                {prSupportSubsidyTypeList?.map((prSupportSubsidyType) => (
                  <MenuItem
                    key={prSupportSubsidyType.id}
                    value={prSupportSubsidyType.name}
                  >
                    {prSupportSubsidyType.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* {errors.prSupportSubsidyType && (
              <span className={classes.errorMessage}>
                {(errors.prSupportSubsidyType as any).message}
              </span>
            )} */}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel
                id="owner-select-label"
                style={watch('stageId') == 38 ? { color: 'red' } : {}}
              >
                連絡ツール
              </InputLabel>
              <Controller
                name="prContactTool"
                control={control}
                rules={{
                  required:
                    watch('stageId') == 38
                      ? '※ 連絡ツールを選択してください'
                      : false,
                }}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4091)
                      .map((prContactTool) =>
                        prContactTool.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
            {errors.prContactTool && (
              <span className={classes.errorMessage}>
                {errors.prContactTool.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              {...register('url', {
                pattern: {
                  value: /https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+/g,
                  message: '※ URL形式で入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="url"
              label="url"
              size="small"
            />
            {errors.url && (
              <span className={classes.errorMessage}>{errors.url.message}</span>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              {...register('prQuoteSite', {
                pattern: {
                  value: /https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+/g,
                  message: '※ URL形式で入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="prQuoteSite"
              label="引用サイト"
              size="small"
            />
            {errors.prQuoteSite && (
              <span className={classes.errorMessage}>
                {errors.prQuoteSite.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              {...register('prNegotiateContent', {
                required:
                  watch('stageId') == 38
                    ? '※ 商談内容を入力してください'
                    : false,
              })}
              multiline
              variant="outlined"
              fullWidth
              id="prNegotiateContent"
              label={
                <span style={watch('stageId') == 38 ? { color: 'red' } : {}}>
                  商談内容
                </span>
              }
              autoComplete="prNegotiateContent"
              minRows={3}
            />
            {errors.prNegotiateContent && (
              <span className={classes.errorMessage}>
                {errors.prNegotiateContent.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              {...register('prRewardContent', {
                required:
                  watch('stageId') == 38
                    ? '※ 報酬内容を選択してください'
                    : false,
              })}
              multiline
              variant="outlined"
              fullWidth
              id="prRewardContent"
              label={
                <span style={watch('stageId') == 38 ? { color: 'red' } : {}}>
                  報酬内容
                </span>
              }
              autoComplete="prRewardContent"
              minRows={3}
            />
            {errors.prRewardContent && (
              <span className={classes.errorMessage}>
                {errors.prRewardContent.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              {...register('orgPrReferralFee', {
                required:
                  watch('stageId') == 38 ? '※ 紹介料を選択してください' : false,
              })}
              multiline
              variant="outlined"
              fullWidth
              id="orgPrReferralFee"
              label={
                <span style={watch('stageId') == 38 ? { color: 'red' } : {}}>
                  紹介料
                </span>
              }
              autoComplete="orgPrReferralFee"
              minRows={3}
            />
            {errors.orgPrReferralFee && (
              <span className={classes.errorMessage}>
                {errors.orgPrReferralFee.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              {...register('orgRemark')}
              multiline
              variant="outlined"
              fullWidth
              id="orgRemark"
              label="備考"
              autoComplete="orgRemark"
              minRows={3}
            />
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        className={classes.betweenButton}
        startIcon={<PersonIcon />}
      >
        人物
      </Button>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">オーナー</InputLabel>
              <Controller
                name="personOwnerId"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.ownerList
                      .filter((v) => v.active_flag == true)
                      .map((personOwner) => (
                        <MenuItem key={personOwner.id} value={personOwner.id}>
                          {personOwner.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('personName', {
                required: '※ 顧客名を入力してください',
              })}
              margin="normal"
              fullWidth
              id="personName"
              label="顧客名"
              size="small"
            />
            {errors.personName && (
              <span className={classes.errorMessage}>
                {errors.personName.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('kana', {
                required: '※ ふりがなを入力してください',
                pattern: {
                  value: /^[ぁ-んー　]+$/,
                  message: '※ ふりがなのみで入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="kana"
              label="ふりがな"
              size="small"
            />
            {errors.kana && (
              <span className={classes.errorMessage}>
                {errors.kana.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('personTel', {
                required: '※ 電話番号を入力してください',
                pattern: {
                  value: /^0\d{1,4}-\d{1,4}-\d{4}$/,
                  message: '※ 電話番号形式で入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="personTel"
              label="電話番号"
              size="small"
            />
            {errors.personTel && (
              <span className={classes.errorMessage}>
                {errors.personTel.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('personTelSecond', {
                pattern: {
                  value: /^0\d{1,4}-\d{1,4}-\d{4}$/,
                  message: '※ 電話番号形式で入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="personTelSecond"
              label="電話番号2"
              size="small"
            />
            {errors.personTelSecond && (
              <span className={classes.errorMessage}>
                {errors.personTelSecond.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('personEmail', {
                required: '※ 電子メールを入力してください',
              })}
              margin="normal"
              fullWidth
              id="personEmail"
              label="電子メール"
              size="small"
            />
            {errors.personEmail && (
              <span className={classes.errorMessage}>
                {errors.personEmail.message}
              </span>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={6} lg={2}>
            <TextField
              {...register('personPostCode', {
                pattern: {
                  value: /^[0-9]{3}-[0-9]{4}$/,
                  message: '※ ハイフン入り(3-4)形式で入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="personPostCode"
              label="郵便番号"
              size="small"
              onBlur={(e) => setPersonAddressByPostCode(e.target.value)}
            />
            {errors.personPostCode && (
              <span className={classes.errorMessage}>
                {errors.personPostCode.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('personAddress', {
                required: '※ 住所を入力してください',
              })}
              margin="normal"
              fullWidth
              id="personAddress"
              label="住所"
              size="small"
              value={personAddress}
              onChange={(e) => setPersonAddress(e.target.value)}
            />
            {errors.personAddress && (
              <span className={classes.errorMessage}>
                {errors.personAddress.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('personAddressBuildings')}
              margin="normal"
              fullWidth
              id="personAddressBuildings"
              label="住所(ビル・マンション等)"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">性別</InputLabel>
              <Controller
                name="sex"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.personFields
                      .filter((v) => v.id == 9069)
                      .map((sex) =>
                        sex.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Controller
                name="birthday"
                control={control}
                render={({ field }) => (
                  <MuiPickersUtilsProvider
                    utils={ExtendedUtils}
                    locale={jaLocale}
                  >
                    <KeyboardDatePicker
                      {...field}
                      label="生年月日"
                      value={field.value}
                      format="yyyy-MM-dd"
                      autoOk={true}
                      variant="inline"
                      onChange={(_date, v) => {
                        setValue('birthday', v)
                      }}
                      invalidDateMessage="※ フォーマットに従って入力してください"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">業務経験</InputLabel>
              <Controller
                name="industryExperience"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.personFields
                      .filter((v) => v.id == 9133)
                      .map((industryExperience) =>
                        industryExperience.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('industryExperienceYears', {
                pattern: {
                  value: /^[0-9,]+$/,
                  message: '※ 半角数字のみで入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="industryExperienceYears"
              label="業務経験年数"
              size="small"
            />
            {errors.industryExperienceYears && (
              <span className={classes.errorMessage}>
                {errors.industryExperienceYears.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">
                SoLaboからのメール受信
              </InputLabel>
              <Controller
                name="mailSend"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.personFields
                      .filter((v) => v.id == 9143)
                      .map((mailSend) =>
                        mailSend.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">顧客ランク</InputLabel>
              <Controller
                name="customerRank"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.personFields
                      .filter((v) => v.id == 9145)
                      .map((customerRank) =>
                        customerRank.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.topButton}
        startIcon={<WcIcon />}
      >
        配偶者
      </Button>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">配偶者</InputLabel>
              <Controller
                name="spouse"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.personFields
                      .filter((v) => v.id == 9067)
                      .map((spouse) =>
                        spouse.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">配偶者業種</InputLabel>
              <Controller
                name="spouseIndustry"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.personFields
                      .filter((v) => v.id == 9068)
                      .map((spouseIndustry) =>
                        spouseIndustry.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('spouseSalary', {
                pattern: {
                  value: /^[0-9,]+$/,
                  message: '※ 半角数字のみで入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="spouseSalary"
              label="配偶者給与"
              size="small"
              onBlur={(e) => {
                if (e.target.value) {
                  const amount = e.target.value.replace(/,/g, '')
                  setValue('spouseSalary', numberFormat(Number(amount)))
                }
              }}
            />
            {errors.spouseSalary && (
              <span className={classes.errorMessage}>
                {errors.spouseSalary.message}
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.topButton}
        startIcon={<BusinessCenterIcon />}
      >
        士業
      </Button>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">【税】税理士紹介</InputLabel>
              <Controller
                name="taxIntroduction"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.personFields
                      .filter((v) => v.id == 9144)
                      .map((taxIntroduction) =>
                        taxIntroduction.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">
                Facebookステータス
              </InputLabel>
              <Controller
                name="prFacebookStatus"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.personFields
                      .filter((v) => v.id == 9118)
                      .map((prFacebookStatus) =>
                        prFacebookStatus.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('prFacebookAccount')}
              margin="normal"
              fullWidth
              id="prFacebookAccount"
              label="Facebookアカウント"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              {...register('personRemark')}
              multiline
              variant="outlined"
              fullWidth
              id="personRemark"
              label="備考"
              autoComplete="personRemark"
              minRows={3}
            />
          </Grid>
        </Grid>
        <Box component="span" m={3} className={classes.buttonWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={() => unregister('lostReason')}
          >
            登録
          </Button>
        </Box>
      </Grid>
      <Snackbar
        open={snackBar.open}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity={snackBar.severity} onClose={handleClose}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </form>
  )
}

export default SigyoDisplay
