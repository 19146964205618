import React, { useState, Suspense, useEffect } from 'react'
import GenericTemplate from '../templates/GenericTemplate'
import {
  makeStyles,
  Theme,
  createStyles,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import { PipedriveForm } from 'models/dealFields'
import { useAuthUser } from 'components/modules/AuthUserContext'
import FinanceDisplay from './FinanceDisplay'
import ManagerDisplay from './ManagerDisplay'
import SigyoDisplay from './SigyoDisplay'
import CallDisplay from './CallDisplay'
import MarketDisplay from './MarketDisplay'
import { getDealById } from 'api/deal'
import { getDealFields } from 'api/dealField'
import { useParams } from 'react-router-dom'
import { getOrgById } from 'api/org'
import { getOrgFields } from 'api/orgFields'
import { getPersonById } from 'api/person'
import { getPersonFields } from 'api/personFields'
import { getOwnerList } from 'api/user'
import { PersonApi } from 'models/person'
import { getStageList } from 'api/stage'
import { getPipelineList } from 'api/pipeline'
import { OrgApi } from 'models/org'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px',
    },
    submitButton: {
      margin: '5px',
    },
  })
)

// 取引フィールド部分
const DealEditField: React.FC = () => {
  const classes = useStyles()
  const [progress, setProgress] = useState(true)

  const authUser = useAuthUser()

  const [pipedriveForm, setPipedriveForm] = useState<PipedriveForm>()

  const backdrop = (
    <Backdrop className={classes.backdrop} open={progress}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )

  const formView = (
    responsibleDepartmentId: number | undefined,
    pipedriveForm: PipedriveForm
  ) => {
    switch (responsibleDepartmentId) {
      case 4: // 融資
        return <FinanceDisplay pipedrive={pipedriveForm} />
      case 5: // 架電
        return <CallDisplay pipedrive={pipedriveForm} />
      case 6: // 士業
        return <SigyoDisplay pipedrive={pipedriveForm} />
      case 7: // 管理部
        return <FinanceDisplay pipedrive={pipedriveForm} />
      case 8: // マーケ
        return <MarketDisplay pipedrive={pipedriveForm} />
      default:
        return <ManagerDisplay pipedrive={pipedriveForm} />
    }
  }

  const { id } = useParams()

  useEffect(() => {
    // 融資用編集画面
    const setFinanceField = async () => {
      const [
        deal,
        dealFields,
        orgFields,
        personFields,
        ownerList,
        stageList,
        pipelineList,
      ] = await Promise.all([
        getDealById(id),
        getDealFields(),
        getOrgFields(),
        getPersonFields(),
        getOwnerList(),
        getStageList(),
        getPipelineList(),
      ])

      let org: OrgApi | null = null

      let person: PersonApi | null = null
      // 人物IDから人物情報を取得
      if (deal.person_id) {
        ;[org, person] = await Promise.all([
          getOrgById(deal.org_id.value),
          getPersonById(deal.person_id.value),
        ])
      } else {
        org = await getOrgById(deal.org_id.value)
      }

      setPipedriveForm({
        deal: deal,
        dealFields: dealFields,
        org: org,
        orgFields: orgFields,
        person: person,
        personFields: personFields,
        ownerList: ownerList,
        stageList: stageList,
        pipelineList: pipelineList,
      })

      setProgress(false)
    }

    setFinanceField()
  }, [])

  if (progress) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress />
      </Backdrop>
    )
  }

  return (
    <>
      {backdrop}
      <Suspense fallback={<p>Loading...</p>}>
        {pipedriveForm &&
          formView(authUser?.responsible_department, pipedriveForm)}
      </Suspense>
    </>
  )
}

const DealEdit: React.FC = () => {
  return (
    <GenericTemplate title="取引編集">
      <DealEditField />
    </GenericTemplate>
  )
}

export default DealEdit
