import GenericTemplate from 'components/templates/GenericTemplate'

const HomePage: React.FC = () => {
  return (
    <GenericTemplate title="トップページ">
      <>トップページの内容</>
    </GenericTemplate>
  )
}
export default HomePage
