import { PersonFieldsPipedrive } from 'models/personFields'
import client from './client'

export const getPersonFields = async (): Promise<PersonFieldsPipedrive> => {
  return client
    .get(
      `personFields?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`,
      {
        params: { limit: 300 },
      }
    )
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}
