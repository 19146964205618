import client from './client'

import { OrgApi, OrgSeach } from '../models/org'
import { DealFields } from 'models/dealFields'

// 単一の取引情報を取得
export const getOrgById = async (id: number | undefined): Promise<OrgApi> => {
  return client
    .get(
      `organizations/${id}?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`
    )
    .then((res) => {
      // 識別子が多いためオブジェクトに置き換える
      const org = {
        ...res.data,
        url: res.data['b0f1c5219d21bf9f7eef5a89359557c214f6eaa8'],
        second_before_sales_amount:
          res.data['a8bc64006ea3b6bddeef399c60998f2df5894794'],
        second_before_net_income:
          res.data['b543b408a2bda385b8892fc4f34d7b484832148b'],
        second_before_officer_compensation_amount:
          res.data['911af9a3a70f6b952f50d80d3a4d34cd02502042'],
        second_before_depreciation_amount:
          res.data['38243a06fd9b1f1629c482d789caf72d74d4071c'],
        first_before_sales_amount:
          res.data['c52f79d685569210de6f9147b095bacc962f2ed6'],
        first_before_net_income:
          res.data['d450c6c2604d466427dca38ff485544701bc82c7'],
        first_before_officer_compensation_amount:
          res.data['a67d7a72162da2750220fd2eeac6495226332c72'],
        first_before_depreciation_amount:
          res.data['47be065cc9b60c46b6c241a4c91881ef722f28ae'],
        pr_greatsign_suggest:
          res.data['4ddb3f724549d59d20c48852f4b42954c17055f2'],
        pr_wordpress: res.data['3c4cd2a3bd04eac7c6362913f3f4440b45324edc'],
        pr_market_suggest: res.data['39a500229b2efc6b57839d2215f479feabaf4a66'],
        pr_office_evaluate:
          res.data['a04ad6c6649dadd71f411b17c2f7636af1b0ed7d'],
        pr_negotiate_content:
          res.data['c5373bb8cf432fc53b05faf5ca2b931409a63a5a'],
        pr_reward_content: res.data['d11d82af0af0af15b6ff4d22f9a49dbeb57182aa'],
        pr_contract_content:
          res.data['eeeccdfa76c504dcdd976bc7a80ef1817087bcea'],
        pr_support_subsidy_type:
          res.data['b533325a682df0eed1c7ce56a1a89f4057fc42bf'],
        pr_quote_site: res.data['00d889fd23dc5f6fa69cf9f9bab82131ef5629d2'],
        pr_zeirisee_status:
          res.data['c2cb3de6ecb1a675886532c0a7cdf7a717274a96'],
        pr_tax_accountant_introduce:
          res.data['a485fdd8da12b1894cea1475c4eaea20ad84c0a2'],
        pr_referral_fee: res.data['1019f4d6ea192edcc97eacb3b88d95f304ccd740'],
        pr_finance_introduce:
          res.data['9fc718d1ab95518c5359a9ef3573d707a2bc6a25'],
        pr_subsidy_introduce:
          res.data['873691cbef36dad1f3df362467f323bec488ec22'],
        pr_contact_tool: res.data['6cad8656c266b8c7ea954dbb9d039378dbee2bd0'],
        pr_template: res.data['09decc1216331d5fc03d533592f2674506c9629d'],
        al_service_child: res.data['00c4bca76be9b12bb028288432a21481d702c10e'],
        al_service_parent: res.data['cb37a829f479998cba4b6ea745a5dc64b013c116'],
        ea_post_corona_propose:
          res.data['d7a85be4ce018d189532ffc5006ce3acdaaf5c24'],
        office: res.data['3433cd9a12d83ba9add668bcfcbaa2ef894c25c1'],
        representative: res.data['762cc82a411713cb704fc0b195fe4524464af031'],
        representative_kana:
          res.data['ca10c3817005b4fafaa49f3891a8ce078393685e'],
        address_buildings: res.data['486ae92fbd6b1752420c3560de2f3a505bba36c8'],
        remark: res.data['0dc0d83a78202b30818189f374c12d15aff8bf9c'],
        founded_date: res.data['e9cc1aa87dcf4c151ec65ec79f013fcd8c992846'],
        founded_status: res.data['c52709aec2ba42ea4a0ee4f4db904411843f99a7'],
        staff_number: res.data['2ebdbc831a5442018db456ebc218a5886f9f5242'],
        existing_loan_first:
          res.data['d489d6ec4fb33db8799c784d507953bbd6c77bd7'],
        existing_loan_second:
          res.data['a10a34d21c2cfbb31a8585fbdd7c29b3a24eae4b'],
        existing_loan_third:
          res.data['3769c43ce67b11ac99c130447a1f653c20a42d82'],
        existing_loan_forth:
          res.data['9bc45747c80b8b8ff67ae84cc1a270f96a662cee'],
        existing_loan_fifth:
          res.data['bc4184f54da3a15294ebf9dea35d98146199b930'],
        existing_loan_detail_first:
          res.data['c6f848eee25f794415d60875a01fd969383d24bb'],
        existing_loan_detail_second:
          res.data['0d1d966a1f8af66c7f83f6d197d32adb88cedacf'],
        existing_loan_detail_third:
          res.data['0960a0f64c9330798ed5775ee3d54bbcc8166805'],
        existing_loan_detail_forth:
          res.data['3bf64bf8ac189a71954b5927784fed583433a841'],
        existing_loan_detail_fifth:
          res.data['334ec5bc366cbe2978b23d7da93826465174cd53'],
        existing_loan_amount_first:
          res.data['a84aacf4503632d8ae955ecfe748aa7cf7c3b81e'],
        existing_loan_amount_second:
          res.data['356bf4d3c138bfed33ca9aaa561065c96b6255ca'],
        existing_loan_amount_third:
          res.data['4575a1fa723b823724860ab65cb1b8db56303718'],
        existing_loan_amount_forth:
          res.data['2ef4dfd1c57f72566c30fe00c6d9d19414b30950'],
        existing_loan_amount_fifth:
          res.data['cc0285473d52fd3f4f4fa405ec7c8260eda74980'],
        old_manager: res.data['5a8ae0eccafc2c7a63bb02a479960c3033b456a2'],
        closing_month: res.data['34ca786fc73b2389ef666267b2c2f0d89068484f'],
        conclusion_date: res.data['0d3751359532fe356666708bcfb32b1c6a5fe2eb'],
        industry_child: res.data['1670bf1b607c3ededa3f9048c81371ef741113be'],
        industry_grandchild:
          res.data['ffe61d4851ce1baef41c82f772f58d1ffdceaa28'],
        industry_grandparent:
          res.data['d0693aea120dc3312bf85acb93ff47985d3dc321'],
        industry_parent: res.data['e861cfd3828defa8d9eab71fb7be2666f44fa716'],
        startup_type: res.data['ea2138980a2260311a4122cbc8bcd9d3558f8360'],
        opening_prefecture:
          res.data['ee40cb99dc50c6816ca23a9a80496bf774c8d444'],
        opening_time: res.data['3bbfa4fdb40230399dff6bd41d5a50f67f7287f4'],
        tel: res.data['f6874c82007aa59543091a929daba42f0f3fa893'],
        tel_second: res.data['ecb43d278dddacc0df436669ccbcead05eb3ddad'],
        post_code: res.data['a6652f8f4ed399499bd08cbc01c184a5f22828a8'],
        futokoro: res.data['47ebdc1ebe5809d0a7f5073cfc75b450d34af649'],
      }

      return org
    })
    .catch((err) => {
      console.log(err)
    })
}

// 文字列から組織候補を取得
export const searchOrgListByName = async (
  orgName: string | undefined
): Promise<OrgSeach> => {
  return client
    .get(
      `organizations/search?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`,
      {
        params: {
          term: orgName,
        },
      }
    )
    .then((res) => {
      return res.data.items
    })
    .catch((err) => {
      console.log(err)
    })
}

// 組織更新
export const updateOrg = async (
  org: DealFields,
  apiToken: string
): Promise<boolean> => {
  const orgParam = {
    name: org.orgName,
    owner_id: org.orgOwnerId,
    b0f1c5219d21bf9f7eef5a89359557c214f6eaa8: org.url,
    a8bc64006ea3b6bddeef399c60998f2df5894794: org.secondBeforeSalesAmount,
    b543b408a2bda385b8892fc4f34d7b484832148b: org.secondBeforeNetIncome,
    '911af9a3a70f6b952f50d80d3a4d34cd02502042':
      org.secondBeforeOfficerCompensationAmount,
    '38243a06fd9b1f1629c482d789caf72d74d4071c':
      org.secondBeforeDepreciationAmount,
    c52f79d685569210de6f9147b095bacc962f2ed6: org.firstBeforeSalesAmount,
    d450c6c2604d466427dca38ff485544701bc82c7: org.firstBeforeNetIncome,
    a67d7a72162da2750220fd2eeac6495226332c72:
      org.firstBeforeOfficerCompensationAmount,
    '47be065cc9b60c46b6c241a4c91881ef722f28ae':
      org.firstBeforeDepreciationAmount,
    '4ddb3f724549d59d20c48852f4b42954c17055f2': org.prGreatsignSuggest,
    '3c4cd2a3bd04eac7c6362913f3f4440b45324edc': org.prWordpress,
    '39a500229b2efc6b57839d2215f479feabaf4a66': org.prMarketSuggest,
    a04ad6c6649dadd71f411b17c2f7636af1b0ed7d: org.prOfficeEvaluate,
    c5373bb8cf432fc53b05faf5ca2b931409a63a5a: org.prNegotiateContent,
    d11d82af0af0af15b6ff4d22f9a49dbeb57182aa: org.prRewardContent,
    eeeccdfa76c504dcdd976bc7a80ef1817087bcea: org.prContractContent,
    b533325a682df0eed1c7ce56a1a89f4057fc42bf:
      org.prSupportSubsidyType?.join(','),
    '00d889fd23dc5f6fa69cf9f9bab82131ef5629d2': org.prQuoteSite,
    c2cb3de6ecb1a675886532c0a7cdf7a717274a96: org.prZeiriseeStatus,
    a485fdd8da12b1894cea1475c4eaea20ad84c0a2: org.prTaxAccountantIntroduce,
    '1019f4d6ea192edcc97eacb3b88d95f304ccd740': org.orgPrReferralFee,
    '873691cbef36dad1f3df362467f323bec488ec22': org.prSubsidyIntroduce,
    '6cad8656c266b8c7ea954dbb9d039378dbee2bd0': org.prContactTool,
    '09decc1216331d5fc03d533592f2674506c9629d': org.prTemplate,
    '00c4bca76be9b12bb028288432a21481d702c10e':
      org.orgAlServiceChild?.join(','),
    cb37a829f479998cba4b6ea745a5dc64b013c116: org.orgAlServiceParent?.join(','),
    d7a85be4ce018d189532ffc5006ce3acdaaf5c24: org.eaPostCoronaPropose,
    '3433cd9a12d83ba9add668bcfcbaa2ef894c25c1': org.office,
    '762cc82a411713cb704fc0b195fe4524464af031': org.representative,
    ca10c3817005b4fafaa49f3891a8ce078393685e: org.representativeKana,
    '486ae92fbd6b1752420c3560de2f3a505bba36c8': org.orgAddressBuildings,
    '0dc0d83a78202b30818189f374c12d15aff8bf9c': org.orgRemark,
    e9cc1aa87dcf4c151ec65ec79f013fcd8c992846: org.foundedDate,
    c52709aec2ba42ea4a0ee4f4db904411843f99a7: org.foundedStatus,
    '2ebdbc831a5442018db456ebc218a5886f9f5242': org.staffNumber,
    d489d6ec4fb33db8799c784d507953bbd6c77bd7: org.existingLoanFirst,
    a10a34d21c2cfbb31a8585fbdd7c29b3a24eae4b: org.existingLoanSecond,
    '3769c43ce67b11ac99c130447a1f653c20a42d82': org.existingLoanThird,
    '9bc45747c80b8b8ff67ae84cc1a270f96a662cee': org.existingLoanForth,
    bc4184f54da3a15294ebf9dea35d98146199b930: org.existingLoanFifth,
    c6f848eee25f794415d60875a01fd969383d24bb: org.existingLoanDetailFirst,
    '0d1d966a1f8af66c7f83f6d197d32adb88cedacf': org.existingLoanDetailSecond,
    '0960a0f64c9330798ed5775ee3d54bbcc8166805': org.existingLoanDetailThird,
    '3bf64bf8ac189a71954b5927784fed583433a841': org.existingLoanDetailForth,
    '334ec5bc366cbe2978b23d7da93826465174cd53': org.existingLoanDetailFifth,
    a84aacf4503632d8ae955ecfe748aa7cf7c3b81e: org.existingLoanAmountFirst,
    '356bf4d3c138bfed33ca9aaa561065c96b6255ca': org.existingLoanAmountSecond,
    '4575a1fa723b823724860ab65cb1b8db56303718': org.existingLoanAmountThird,
    '2ef4dfd1c57f72566c30fe00c6d9d19414b30950': org.existingLoanAmountForth,
    cc0285473d52fd3f4f4fa405ec7c8260eda74980: org.existingLoanAmountFifth,
    '5a8ae0eccafc2c7a63bb02a479960c3033b456a2': org.orgOldManager,
    '34ca786fc73b2389ef666267b2c2f0d89068484f': org.closingMonth,
    '0d3751359532fe356666708bcfb32b1c6a5fe2eb': org.conclusionDate,
    '1670bf1b607c3ededa3f9048c81371ef741113be': org.industryChild,
    ffe61d4851ce1baef41c82f772f58d1ffdceaa28: org.industryGrandchild,
    d0693aea120dc3312bf85acb93ff47985d3dc321: org.industryGrandparent,
    e861cfd3828defa8d9eab71fb7be2666f44fa716: org.industryParent,
    ea2138980a2260311a4122cbc8bcd9d3558f8360: org.startupType,
    ee40cb99dc50c6816ca23a9a80496bf774c8d444: org.openingPrefecture,
    '3bbfa4fdb40230399dff6bd41d5a50f67f7287f4': org.openingTime,
    f6874c82007aa59543091a929daba42f0f3fa893: org.orgTel,
    ecb43d278dddacc0df436669ccbcead05eb3ddad: org.orgTelSecond,
    a6652f8f4ed399499bd08cbc01c184a5f22828a8: org.orgPostCode,
    address: org.orgAddress,
  }

  return client
    .put(`organizations/${org.orgId}?api_token=${apiToken}`, orgParam)
    .then(() => {
      return true
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}
