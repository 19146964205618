// ユーザの所属部署
export const RESPONSIBLE_DEPARTMENT = [
  { id: 1, name: '役員' },
  { id: 2, name: '経営企画室' },
  { id: 3, name: '開発部' },
  { id: 4, name: '融資' },
  { id: 5, name: '架電' },
  { id: 6, name: '士業' },
  { id: 7, name: '管理部' },
  { id: 8, name: 'マーケ' },
  { id: 9, name: 'マークアップ' },
  { id: 10, name: 'ライター' },
  { id: 11, name: 'その他' },
]

// 業種の祖父母は固定であるため固定値定義
export const INDUSTRY_GRANDPARENT = [
  { id: 1, pipedrive_field_id: 4158, industry_grandparent_name: '農業，林業' },
  { id: 2, pipedrive_field_id: 4159, industry_grandparent_name: '漁業' },
  {
    id: 3,
    pipedrive_field_id: 4160,
    industry_grandparent_name: '鉱業，採石業，砂利採取業',
  },
  { id: 4, pipedrive_field_id: 4161, industry_grandparent_name: '建設業' },
  { id: 5, pipedrive_field_id: 4162, industry_grandparent_name: '製造業' },
  {
    id: 6,
    pipedrive_field_id: 4163,
    industry_grandparent_name: '電気・ガス・熱供給・水道業',
  },
  { id: 7, pipedrive_field_id: 4164, industry_grandparent_name: '情報通信業' },
  {
    id: 8,
    pipedrive_field_id: 4165,
    industry_grandparent_name: '運輸業，郵便業',
  },
  {
    id: 9,
    pipedrive_field_id: 4166,
    industry_grandparent_name: '卸売業，小売業',
  },
  {
    id: 10,
    pipedrive_field_id: 4167,
    industry_grandparent_name: '金融業，保険業',
  },
  {
    id: 11,
    pipedrive_field_id: 4168,
    industry_grandparent_name: '不動産業，物品賃貸業',
  },
  {
    id: 12,
    pipedrive_field_id: 4169,
    industry_grandparent_name: '学術研究，専門・技術サービス業',
  },
  {
    id: 13,
    pipedrive_field_id: 4170,
    industry_grandparent_name: '宿泊業，飲食サービス業',
  },
  {
    id: 14,
    pipedrive_field_id: 4171,
    industry_grandparent_name: '生活関連サービス業，娯楽業',
  },
  {
    id: 15,
    pipedrive_field_id: 4172,
    industry_grandparent_name: '教育，学習支援業',
  },
  { id: 16, pipedrive_field_id: 4173, industry_grandparent_name: '医療，福祉' },
  {
    id: 17,
    pipedrive_field_id: 4174,
    industry_grandparent_name: '複合サービス事業',
  },
  { id: 18, pipedrive_field_id: 4175, industry_grandparent_name: 'サービス業' },
  {
    id: 19,
    pipedrive_field_id: 4176,
    industry_grandparent_name: '公務（他に分類されるものを除く）',
  },
  {
    id: 20,
    pipedrive_field_id: 4177,
    industry_grandparent_name: '分類不能の産業',
  },
]

// 都道府県は固定であるため固定値定義
export const PREFECTURE = [
  { id: 2943, prefecture_name: '北海道' },
  { id: 2944, prefecture_name: '青森県' },
  { id: 2945, prefecture_name: '岩手県' },
  { id: 2946, prefecture_name: '宮城県' },
  { id: 2947, prefecture_name: '秋田県' },
  { id: 2948, prefecture_name: '山形県' },
  { id: 2949, prefecture_name: '福島県' },
  { id: 2950, prefecture_name: '茨城県' },
  { id: 2951, prefecture_name: '栃木県' },
  { id: 2952, prefecture_name: '群馬県' },
  { id: 2953, prefecture_name: '埼玉県' },
  { id: 2954, prefecture_name: '千葉県' },
  { id: 2955, prefecture_name: '東京都' },
  { id: 2956, prefecture_name: '神奈川県' },
  { id: 2957, prefecture_name: '新潟県' },
  { id: 2958, prefecture_name: '富山県' },
  { id: 2959, prefecture_name: '石川県' },
  { id: 2960, prefecture_name: '福井県' },
  { id: 2961, prefecture_name: '山梨県' },
  { id: 2962, prefecture_name: '長野県' },
  { id: 2963, prefecture_name: '岐阜県' },
  { id: 2964, prefecture_name: '静岡県' },
  { id: 2965, prefecture_name: '愛知県' },
  { id: 2966, prefecture_name: '三重県' },
  { id: 2967, prefecture_name: '滋賀県' },
  { id: 2968, prefecture_name: '京都府' },
  { id: 2969, prefecture_name: '大阪府' },
  { id: 2970, prefecture_name: '兵庫県' },
  { id: 2971, prefecture_name: '奈良県' },
  { id: 2972, prefecture_name: '和歌山県' },
  { id: 2973, prefecture_name: '鳥取県' },
  { id: 2974, prefecture_name: '島根県' },
  { id: 2975, prefecture_name: '岡山県' },
  { id: 2976, prefecture_name: '広島県' },
  { id: 2977, prefecture_name: '山口県' },
  { id: 2978, prefecture_name: '徳島県' },
  { id: 2979, prefecture_name: '香川県' },
  { id: 2980, prefecture_name: '愛媛県' },
  { id: 2981, prefecture_name: '高知県' },
  { id: 2982, prefecture_name: '福岡県' },
  { id: 2983, prefecture_name: '佐賀県' },
  { id: 2984, prefecture_name: '長崎県' },
  { id: 2985, prefecture_name: '熊本県' },
  { id: 2986, prefecture_name: '大分県' },
  { id: 2987, prefecture_name: '宮崎県' },
  { id: 2988, prefecture_name: '鹿児島県' },
  { id: 2989, prefecture_name: '沖縄県' },
]

// pipeline
export const PIPELINE = [
  {
    id: 2,
    name: '融資',
  },
  {
    id: 10,
    name: '補助金紹介',
  },
  {
    id: 3,
    name: '税理士紹介',
  },
  {
    id: 6,
    name: '士業提携',
  },
  {
    id: 5,
    name: '提携紹介',
  },
  {
    id: 7,
    name: '提携',
  },
  {
    id: 8,
    name: '経営力向上計画',
  },
  {
    id: 9,
    name: 'カルテット',
  },
  {
    id: 11,
    name: '支払申請',
  },
  {
    id: 4,
    name: '補助金',
  },
  {
    id: 12,
    name: 'ソラビジ',
  },
  {
    id: 13,
    name: '伴走プラン',
  },
  {
    id: 14,
    name: '早期経営改善計画',
  },
  {
    id: 15,
    name: '制作プラン',
  },
  {
    id: 16,
    name: '資金調達情報配信サービス',
  },
  {
    id: 17,
    name: '融資顧問契約',
  },
  {
    id: 18,
    name: '経営行動計画書',
  },
  {
    id: 19,
    name: '採択後フォロー',
  },
  {
    id: 20,
    name: 'IT導入補助金',
  },
  {
    id: 21,
    name: 'ITメーカー提携',
  },
  {
    id: 22,
    name: 'ITツールフォロー',
  },
]

// 融資担当リスト
export const FINANCE_SUPPORT_MEMBER = [
  { id: 12620110, name: '事務', duration: null, type: 'finance' },
  { id: 12620286, name: '事務2', duration: null, type: 'finance' },
  { id: 12620198, name: '村野 智範', duration: '01:00', type: 'finance' },
  { id: 12620220, name: '安藤 崇行', duration: '01:00', type: 'finance' },
  { id: 12620242, name: '中島 秀明', duration: '01:00', type: 'finance' },
  { id: 12620275, name: '篠田 佳希', duration: '01:00', type: 'finance' },
  { id: 13711211, name: '岩波 博昌', duration: '01:00', type: 'finance' },
  { id: 14471424, name: '本間 義人', duration: '01:00', type: 'finance' },
  { id: 14033536, name: '根本 賢', duration: '01:00', type: 'finance' },
  { id: 15938934, name: '石井 宏一', duration: '01:00', type: 'finance' },
  { id: 16158318, name: '太田 亮介', duration: '01:00', type: 'finance' },
  { id: 12355261, name: '大石田 一真', duration: '00:45', type: 'finance' },
  { id: 12620352, name: '中村 翼', duration: '01:00', type: 'subsidy' },
  { id: 20723821, name: '桑原 光一郎', duration: '01:00', type: 'subsidy' },
  { id: 13276392, name: '小柳 太輝', duration: '01:00', type: 'subsidy' },
  { id: 15807800, name: '村口 貴哉', duration: '01:00', type: 'subsidy' },
  { id: 14823809, name: '山本 琉聖', duration: '01:00', type: 'subsidy' },
  { id: 15439897, name: '近田 征汰朗', duration: '01:00', type: 'subsidy' },
  { id: 15579542, name: '原田 悠太', duration: '01:00', type: 'subsidy' },
  { id: 15469267, name: '田上 航平', duration: '01:00', type: 'subsidy' },
  { id: 16165897, name: '芦川 源和', duration: '01:00', type: 'subsidy' },
  { id: 16384159, name: '須田 七海', duration: '01:00', type: 'subsidy' },
  { id: 20723931, name: '板橋 千幸', duration: '01:00', type: 'subsidy' },
  { id: 15789543, name: '廣兼 佳明', duration: '01:00', type: 'subsidy' },
  { id: 16384126, name: '工藤 優', duration: '01:00', type: 'subsidy' },
  { id: 99999992, name: '富田 智恵', duration: '01:00', type: 'subsidy' }, // オーナー：村口, アクティビティ：事務２登録
  { id: 12620330, name: '南 晃二朗', duration: '01:00', type: 'postCorona' },
  { id: 14463746, name: '小野 秀昂', duration: '01:00', type: 'postCorona' },
]

export const FINANCE_MEMBER_DISPLAY = [
  { id: 12620110, name: '事務' },
  { id: 12620286, name: '事務2' },
  { id: 12620198, name: '村野 智範' },
  { id: 12620220, name: '安藤 崇行' },
  { id: 12620242, name: '中島 秀明' },
  { id: 12620275, name: '篠田 佳希' },
  { id: 13711211, name: '岩波 博昌' },
  { id: 14471424, name: '本間 義人' },
  { id: 14033536, name: '根本 賢' },
  { id: 15938934, name: '石井 宏一' },
  { id: 16158318, name: '太田 亮介' },
  { id: 12355261, name: '大石田 一真' },
]

export const SUBSIDY_MEMBER_DISPLAY = [
  { id: 12620352, name: '中村 翼' },
  { id: 20723821, name: '桑原 光一郎' },
  { id: 13276392, name: '小柳 太輝' },
  { id: 15807800, name: '村口 貴哉' },
  { id: 14823809, name: '山本 琉聖' },
  { id: 15439897, name: '近田 征汰朗' },
  { id: 15579542, name: '原田 悠太' },
  { id: 15469267, name: '田上 航平' },
  { id: 16165897, name: '芦川 源和' },
  { id: 16384159, name: '須田 七海' },
  { id: 20723931, name: '板橋 千幸' },
  { id: 15789543, name: '廣兼 佳明' },
  { id: 16384126, name: '工藤 優' },
  { id: 99999992, name: '富田 智恵' },
]

export const POST_CORONA = [
  { id: 12620330, name: '南 晃二朗' },
  { id: 14463746, name: '小野 秀昂' },
]
