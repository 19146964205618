import React, { useState, useEffect } from 'react'
import GenericTemplate from '../templates/GenericTemplate'
import MaterialTable from 'material-table'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  // Grid,
  // TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import { getDeals } from '../../api/deal'
import { getOwnerList } from '../../api/user'
import { DealApi, DealDisplay } from '../../models/deal'
import { Owner } from '../../models/user'
import { PipelineList } from '../../models/pipeline'
import { getPipelineList } from '../../api/pipeline'
import { useNavigate } from 'react-router-dom'
import { useAuthUser } from 'components/modules/AuthUserContext'
import { getStageList } from 'api/stage'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
)

const DealPage: React.FC = () => {
  const classes = useStyles()
  const authUser = useAuthUser()
  const [dealList, setDealList] = useState<DealApi[] | undefined>()
  const [ownerId, setOwnerId] = useState<number>(0)
  const [owners, setOwners] = useState<Owner | undefined>()
  const [pipelineList, setPipelineList] = useState<PipelineList>({})
  const [stageList, setStageList] = useState<PipelineList>({})
  const [statusName, setStatusName] = useState<string>('open')
  const [progress, setProgress] = useState(true)

  // オーナー変更時の取引取得
  const ownerChange = async (e: React.ChangeEvent<{ value: unknown }>) => {
    setProgress(true)
    const deals = await getDeals(Number(e.target.value), statusName)
    setDealList(deals)
    setProgress(false)
    setOwnerId(Number(e.target.value))
  }

  // ステータス変更時の取引取得
  const statusChange = async (e: React.ChangeEvent<{ value: unknown }>) => {
    setProgress(true)
    const deals = await getDeals(ownerId, String(e.target.value))
    setDealList(deals)
    setProgress(false)
    setStatusName(String(e.target.value))
  }

  const history = useNavigate()
  const editLink = (dealId: number) => {
    history(`/deal/${dealId}`)
  }

  useEffect(() => {
    const firstSetting = async () => {
      // pipedriveアカウント持ちはオーナーとしてデフォルト設定
      let pipedriveId = 0
      if (authUser?.pipedrive_id) {
        pipedriveId = authUser.pipedrive_id
        setOwnerId(authUser.pipedrive_id)
      }

      const [ownerList, pipelineList, stageList, deals] = await Promise.all([
        getOwnerList(),
        getPipelineList(),
        getStageList(),
        getDeals(pipedriveId, statusName),
      ])

      const pipelineChoice: PipelineList = {}
      pipelineList.map((pipeline) => {
        pipelineChoice[pipeline.id] = pipeline.name
      })
      const stageChoice = {}
      stageList.map((stage) => {
        stageChoice[stage.id] = stage.name
      })

      setDealList(deals)
      setPipelineList(pipelineChoice)
      setStageList(stageChoice)
      setOwners(ownerList)

      setProgress(false)
    }
    firstSetting()
  }, [])

  if (progress) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress />
      </Backdrop>
    )
  }

  const deals: DealDisplay[] = []

  return (
    <GenericTemplate title="取引一覧">
      {dealList?.map((deal) => {
        const copyObject = {
          id: deal.id,
          title: deal.title,
          owner_name: deal.owner_name,
          org_name: deal.org_name,
          person_name: deal.person_name,
          pipeline_name: pipelineList[deal.pipeline_id],
          stage_name: stageList[deal.stage_id],
          add_time: deal.add_time,
          update_time: deal.update_time,
        }

        deals.push(copyObject)
      })}
      <FormControl className={classes.formControl}>
        <InputLabel id="owner-select-label">オーナー</InputLabel>
        <Select
          labelId="owner-select-label"
          id="owner-select"
          value={ownerId}
          onChange={(e) => ownerChange(e)}
        >
          <MenuItem value={0}>考慮しない</MenuItem>
          {owners?.map((owner) => {
            if (owner.active_flag)
              return (
                <MenuItem key={owner.id} value={owner.id}>
                  {owner.name}
                </MenuItem>
              )
          })}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="status-select-label">ステータス</InputLabel>
        <Select
          labelId="status-select-label"
          id="status-select"
          value={statusName}
          onChange={(e) => statusChange(e)}
        >
          <MenuItem value={'all_not_deleted'}>考慮しない</MenuItem>
          <MenuItem value={'open'}>進行中</MenuItem>
          <MenuItem value={'won'}>成約</MenuItem>
          <MenuItem value={'lost'}>失注</MenuItem>
          <MenuItem value={'deleted'}>削除済み</MenuItem>
        </Select>
      </FormControl>
      <MaterialTable
        localization={{
          header: { actions: '' },
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: '編集',
            onClick: (_, rowData) => {
              editLink((rowData as DealDisplay).id)
            },
          },
        ]}
        columns={[
          { title: 'タイトル', field: 'title' },
          { title: 'オーナー', field: 'owner_name' },
          { title: '組織名', field: 'org_name' },
          { title: '人物名', field: 'person_name' },
          { title: 'パイプライン', field: 'pipeline_name' },
          { title: 'ステージ', field: 'stage_name' },
          { title: '問い合わせ日', field: 'add_time' },
          { title: '最終更新日', field: 'update_time' },
        ]}
        data={deals}
        title="Deals"
        options={{
          pageSize: 50,
          actionsColumnIndex: -1,
          filtering: true,
        }}
      />
    </GenericTemplate>
  )
}

export default DealPage
