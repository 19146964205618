import { DealFieldsPipedrive } from 'models/dealFields'
import client from './client'

export const getDealFields = async (): Promise<DealFieldsPipedrive> => {
  return client
    .get(`dealFields?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`, {
      params: { limit: 300 },
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}
