import axios, { AxiosInstance, AxiosResponse } from 'axios'

let client: AxiosInstance

const companyDomain = 'solabo'

export default client = axios.create({
  baseURL: `https://${companyDomain}.pipedrive.com/api/v1/`,
  headers: {
    'Content-Type': 'application/json',
  },
})

client.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse | undefined => {
    return { ...response.data }
  }
)
