import axios, { AxiosInstance, AxiosResponse } from 'axios'

let back: AxiosInstance

export default back = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

back.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse | undefined => {
    return { ...response }
  }
)
