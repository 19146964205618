import { User } from 'models/user'
import { createContext, useContext, useEffect, useState } from 'react'

type OperationType = {
  login: (user: User) => void
  logout: () => void
}

const getDefaultAuthUser = () => {
  const defaultAuthUser = window.localStorage.getItem('authUser')
  if (defaultAuthUser) return JSON.parse(defaultAuthUser)
  else return null
}

const setAuthUserToLocalStorage = (authUser: User): void => {
  const authUserStringfy = JSON.stringify(authUser)
  window.localStorage.setItem('authUser', authUserStringfy)
}

const AuthUserContext = createContext<User | null>(null)
const AuthOperationContext = createContext<OperationType>({
  login: () => console.error('providerが設定されていません'),
  logout: () => console.error('providerが設定されていません'),
})

const AuthUserProvider: React.FC = ({ children }) => {
  const [authUser, setAuthUser] = useState<User | null>(getDefaultAuthUser())
  const login = async (user: User) => {
    setAuthUser(user)
  }

  const logout = async () => {
    setAuthUser(null)
    window.localStorage.removeItem('authUser')
  }

  useEffect(() => {
    if (authUser) setAuthUserToLocalStorage(authUser)
  }, [authUser])

  return (
    <AuthOperationContext.Provider value={{ login, logout }}>
      <AuthUserContext.Provider value={authUser}>
        {children}
      </AuthUserContext.Provider>
    </AuthOperationContext.Provider>
  )
}

export const useAuthUser = (): User | null => useContext(AuthUserContext)
export const useLogin = (): ((user: User) => void) =>
  useContext(AuthOperationContext).login
export const useLogout = (): (() => void) =>
  useContext(AuthOperationContext).logout

export default AuthUserProvider
