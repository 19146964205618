import { useState } from 'react'
import {
  Box,
  Button,
  Grid,
  makeStyles,
  createStyles,
  TextField,
  Theme,
  Backdrop,
  CircularProgress,
  Snackbar,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Input,
} from '@material-ui/core'
import { useAuthUser } from 'components/modules/AuthUserContext'
import styles from '../scss/user.module.scss'
import GenericTemplate from 'components/templates/GenericTemplate'
import { ProfileForm } from 'models/user'
import { Controller, useForm } from 'react-hook-form'
import { updateUser } from 'api/user'
import { Alert } from '@material-ui/lab'
import { RESPONSIBLE_DEPARTMENT } from 'components/modules/Const'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    formControl: {
      width: '100%',
      marginTop: '13px',
      marginBottom: '8px',
    },
  })
)

type SnackBarParam = {
  open: boolean
  severity: 'error' | 'success' | 'warning' | 'info' | undefined
  message: string
}

const Profile: React.FC = () => {
  const classes = useStyles()
  // ログインユーザ情報を取得
  const authUser = useAuthUser()
  const [progress, setProgress] = useState(false)
  const [snackBar, setSnackBar] = useState<SnackBarParam>({
    open: false,
    severity: 'success',
    message: '',
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ProfileForm>({ mode: 'all' })

  const handleOnSubmit = async (data: ProfileForm) => {
    setProgress(true)

    try {
      // ユーザ更新
      await updateUser(data)
      setSnackBar({
        open: true,
        severity: 'success',
        message: 'ユーザ情報を更新しました',
      })
    } catch (e: unknown) {
      if (e instanceof Error) {
        setSnackBar({
          open: true,
          severity: 'error',
          message: e.message,
        })
      } else {
        setSnackBar({
          open: true,
          severity: 'error',
          message: '予期せぬエラーが発生しました',
        })
      }
    }

    setProgress(false)
  }

  // ダイアログ用
  const handleClose = () => {
    setSnackBar({
      open: false,
      severity: undefined,
      message: '',
    })
  }

  const form = (
    <Backdrop className={classes.backdrop} open={progress}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )

  return (
    <GenericTemplate title="プロフィール">
      {form}
      <form className={styles.form} onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={3}>
              <Input
                {...register('id', {})}
                id="id"
                type="hidden"
                value={authUser?.id}
              />
              <TextField
                {...register('name', {
                  required: '※ 名前を入力してください',
                })}
                margin="normal"
                fullWidth
                id="name"
                label="名前"
                size="small"
                className={styles.text_field}
                defaultValue={authUser?.name}
              />
              {errors.name && (
                <span className={styles.error_message}>
                  {errors.name.message}
                </span>
              )}
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('email', {
                  required: '※ メールアドレスを入力してください',
                  pattern: {
                    value:
                      /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/,
                    message: '※ 正しいアドレスを入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="email"
                label="メールアドレス"
                size="small"
                className={styles.text_field}
                defaultValue={authUser?.email}
              />
              {errors.email && (
                <span className={styles.error_message}>
                  {errors.email.message}
                </span>
              )}
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('password', {
                  minLength: {
                    value: 8,
                    message: '※ 8文字以上で入力してください',
                  },
                  // pattern: {
                  //   value: /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,24}$/i,
                  //   message:
                  //     '※ 半角英数字をそれぞれ1種類以上8文字以上で入力してください',
                  // },
                })}
                margin="normal"
                fullWidth
                id="standard-password-input"
                label="パスワード（入力時変更）"
                type="password"
                autoComplete="current-password"
                size="small"
                className={styles.pass_field}
              />
              {errors.password && (
                <span className={styles.error_message}>
                  {errors.password.message}
                </span>
              )}
            </Grid>
            <Grid item xs={3}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">権限</InputLabel>
                <Controller
                  name="authority"
                  control={control}
                  rules={{
                    required: '権限を選択してください',
                  }}
                  defaultValue={authUser?.authority}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {authUser?.authority == 1 && (
                        <MenuItem value={1}>管理者</MenuItem>
                      )}
                      <MenuItem value={2}>利用者</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
              {errors.authority && (
                <span className={styles.error_message}>
                  {errors.authority.message}
                </span>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} item xs={12}>
            <Grid item xs={3}>
              <FormControl className={classes.formControl}>
                <InputLabel id="owner-select-label">担当部署</InputLabel>
                <Controller
                  name="responsible_department"
                  control={control}
                  rules={{
                    required: '担当部署を選択してください',
                  }}
                  defaultValue={authUser?.responsible_department}
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value="">選択してください</MenuItem>
                      {RESPONSIBLE_DEPARTMENT.map((department) => (
                        <MenuItem key={department.id} value={department.id}>
                          {department.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              {errors.responsible_department && (
                <span className={styles.error_message}>
                  {errors.responsible_department.message}
                </span>
              )}
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register('pipedrive_id', {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                margin="normal"
                fullWidth
                id="pipedrive_id"
                label="pipedrive ID"
                size="small"
                className={styles.text_field}
                defaultValue={authUser?.pipedrive_id}
              />
              {errors.pipedrive_id && (
                <span className={styles.error_message}>
                  {errors.pipedrive_id.message}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box component="span" m={2} className={styles.button_wrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={styles.submit_button}
          >
            登録
          </Button>
        </Box>
      </form>
      <Snackbar
        open={snackBar.open}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity={snackBar.severity} onClose={handleClose}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </GenericTemplate>
  )
}
export default Profile
