import { useState, useEffect } from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Backdrop,
  CircularProgress,
  Input,
  Box,
  Button,
  Snackbar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Typography,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { Alert, Autocomplete } from '@material-ui/lab'
import { FormSelectObject, FormSelectList } from 'models/deal'
import { DealFields, PipedriveTotal } from 'models/dealFields'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import jaLocale from 'date-fns/locale/ja'

import format from 'date-fns/format'
import { searchOrgListByName, updateOrg } from 'api/org'
import { searchPersonListByName, updatePerson } from 'api/person'
import { contractDeal, lostDeal, updateDeal } from 'api/deal'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import PersonIcon from '@material-ui/icons/Person'
import BusinessIcon from '@material-ui/icons/Business'
import RateReviewIcon from '@material-ui/icons/RateReview'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import LinkIcon from '@material-ui/icons/Link'
import { useAuthUser } from 'components/modules/AuthUserContext'
import { INDUSTRY_GRANDPARENT } from 'components/modules/Const'
import {
  getIndustryChild,
  getIndustryGrandchild,
  getIndustryParent,
} from 'api/industry'
import {
  IndustryChild,
  IndustryGrandchild,
  IndustryParent,
} from 'models/industry'

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: Date) {
    return format(date, 'yyyy年 MMM', { locale: this.locale })
  }
  getDatePickerHeaderText(date: Date) {
    return format(date, 'MMMd日(E)', { locale: this.locale })
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    formControl: {
      width: '100%',
      marginTop: '13px',
      marginBottom: '8px',
    },
    errorMessage: {
      color: 'red',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px',
    },
    submitButton: {
      margin: '5px',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    border: {
      borderTop: '2px solid #e5e5e5 ',
    },
    dealButton: {
      margin: '16px',
    },
    topButton: {
      margin: theme.spacing(1),
    },
    betweenButton: {
      margin: theme.spacing(1),
      marginTop: '40px',
    },
    contractButton: {
      margin: theme.spacing(1),
      backgroundColor: '#08a742',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '14px',
      '&:hover': {
        background: '#30b560',
      },
    },
    lostButton: {
      margin: theme.spacing(1),
      backgroundColor: '#f94839',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '14px',
      '&:hover': {
        background: '#fa6559',
      },
    },
    pipedriveButton: {
      margin: theme.spacing(1),
      fontWeight: 'bold',
      fontSize: '14px',
    },
    beforeModified: {
      color: 'green',
      margin: theme.spacing(1),
      paddingTop: '12px',
    },
  })
)

type SnackBarParam = {
  open: boolean
  severity: 'error' | 'success' | 'warning' | 'info' | undefined
  message: string
}

const CallDisplay: React.VFC<PipedriveTotal> = ({ pipedrive }): JSX.Element => {
  const authUser = useAuthUser()
  const classes = useStyles()
  const [snackBar, setSnackBar] = useState<SnackBarParam>({
    open: false,
    severity: undefined,
    message: '',
  })
  // ダイアログ用
  const handleClose = () => {
    setSnackBar({
      open: false,
      severity: undefined,
      message: '',
    })
  }

  const [orgAddress, setOrgAddress] = useState<string>(pipedrive.org.address)
  const [personAddress, setPersonAddress] = useState<string | undefined>(
    pipedrive.person?.address
  )

  const [beforeModified, setbeforeModified] = useState<string>('')

  // 問い合わせ経路
  const [financyRoute, setFinancyRoute] = useState<FormSelectObject>({
    id: Number(pipedrive.deal.fi_inquiry_route),
    name: String(
      pipedrive.dealFields
        .filter((v) => v.id == 12492)
        .map((contactMethod) =>
          contactMethod.options
            .filter((v) => v.id == pipedrive.deal.fi_inquiry_route)
            .map((v) => v.label)
        )[0]
    ),
  })

  // 紹介元（組織）
  const [referralSource, setReferralSource] = useState<FormSelectObject>({
    id: pipedrive.deal.fi_referral_source?.value,
    name: pipedrive.deal.fi_referral_source?.name,
  })

  const [referralSourceList, setReferralSourceList] = useState<FormSelectList>([
    {},
  ])

  // 紹介元担当者
  const [referralSourceManager, setReferralSourceManager] =
    useState<FormSelectObject>({
      id: pipedrive.deal.fi_referral_source_manager?.value,
      name: pipedrive.deal.fi_referral_source_manager?.name,
    })

  const [referralSourceManagerList, setReferralSourceManagerList] =
    useState<FormSelectList>([{}])

  // 開業予定都道府県
  const [openingPrefecture, setOpeningPrefecture] = useState<FormSelectObject>({
    id: Number(pipedrive.org.opening_prefecture),
    name: String(
      pipedrive.orgFields
        .filter((v) => v.id == 4056)
        .map((openingPrefecture) =>
          openingPrefecture.options
            .filter((v) => v.id == pipedrive.org.opening_prefecture)
            .map((v) => v.label)
        )[0]
    ),
  })

  let tmpIndustryParent = {}
  if (pipedrive.org.industry_parent) {
    const t = pipedrive.orgFields
      .filter((v) => v.id == 4058)[0]
      .options.filter((v) => v.id == pipedrive.org.industry_parent)[0]
    tmpIndustryParent = {
      pipedrive_field_id: pipedrive.org.industry_parent,
      industry_parent_name: t.label,
    }
  }

  let tmpIndustryChild = {}
  if (pipedrive.org.industry_child) {
    const t = pipedrive.orgFields
      .filter((v) => v.id == 4059)[0]
      .options.filter((v) => v.id == pipedrive.org.industry_child)[0]
    tmpIndustryChild = {
      pipedrive_field_id: pipedrive.org.industry_child,
      industry_child_name: t.label,
    }
  }

  let tmpIndustryGrandchild = {}
  if (pipedrive.org.industry_grandchild) {
    const t = pipedrive.orgFields
      .filter((v) => v.id == 4074)[0]
      .options.filter((v) => v.id == pipedrive.org.industry_grandchild)[0]
    tmpIndustryGrandchild = {
      pipedrive_field_id: pipedrive.org.industry_grandchild,
      industry_grandchild_name: t.label,
    }
  }

  const [industryParentList, setIndustryParentList] = useState<IndustryParent>([
    tmpIndustryParent,
  ])
  const [industryChildList, setIndustryChildList] = useState<IndustryChild>([
    tmpIndustryChild,
  ])
  const [industryGrandchildList, setIndustryGrandchildList] =
    useState<IndustryGrandchild>([tmpIndustryGrandchild])

  const tmpStageList: FormSelectList = [{}]

  pipedrive.stageList
    .filter(
      (v) =>
        v.active_flag == true && v.pipeline_id == pipedrive.deal.pipeline_id
    )
    .map((stage) =>
      tmpStageList.push({
        id: stage.id,
        name: stage.name,
      })
    )

  tmpStageList.shift()

  const [stageList, setStageList] = useState<FormSelectList>(tmpStageList)

  const [loading, setLoading] = useState(true)

  const contactMethodList: FormSelectList = [{}]
  const openingPrefectureList: FormSelectList = [{}]

  // 問い合わせ経路
  pipedrive.dealFields
    .filter((v) => v.id == 12492)
    .map((contactMethod) =>
      contactMethod.options.map((e) =>
        contactMethodList?.push({
          id: e.id,
          name: e.label,
        })
      )
    )
  contactMethodList.shift()

  // 開業予定都道府県
  pipedrive.orgFields
    ?.filter((v) => v.id == 4056)
    .map((openingPrefecture) =>
      openingPrefecture.options.map((e) =>
        openingPrefectureList?.push({
          id: e.id,
          name: e.label,
        })
      )
    )
  openingPrefectureList.shift()

  // カンマ区切りに切り出し
  const numberFormat = (num: number): string => {
    return num.toLocaleString()
  }

  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    trigger,
    unregister,
    getValues,
  } = useForm<DealFields>({
    mode: 'all',
    defaultValues: {
      dealUserId: pipedrive.deal.user_id?.value,
      probability: pipedrive.deal.probability,
      expectedCloseDate: pipedrive.deal.expected_close_date,
      value: pipedrive.deal.value,
      orgOwnerId: pipedrive.org.owner_id?.value,
      personOwnerId: pipedrive.person?.owner_id?.value,
      pipelineId: pipedrive.deal.pipeline_id,
      stageId: pipedrive.deal.stage_id,
      inquiryRoute: pipedrive.deal.fi_inquiry_route,
      desiredAmountFrom: pipedrive.deal.fi_desired_amount_from
        ? numberFormat(pipedrive.deal.fi_desired_amount_from)
        : '',
      desiredAmountTo: pipedrive.deal.fi_desired_amount_to
        ? numberFormat(pipedrive.deal.fi_desired_amount_to)
        : '',
      decidedAmount: pipedrive.deal.fi_decided_amount
        ? numberFormat(pipedrive.deal.fi_decided_amount)
        : '',
      requestedDocument: pipedrive.deal.fi_requested_document,
      referralSource: pipedrive.deal.fi_referral_source?.value,
      referralSourceManager: pipedrive.deal.fi_referral_source_manager?.value,
      consultationContent: pipedrive.deal.consultation_content,
      consultationContentForm: pipedrive.deal.consultation_content_form,
      remark: pipedrive.deal.remark,
      remarkSecond: pipedrive.deal.remark_second,
      suSubsidyType: pipedrive.deal.su_subsidy_type,
      suDocumentSubmitDate: pipedrive.deal.su_document_submit_date,
      suAdoptDate: pipedrive.deal.su_adopt_date,
      suExecutionDate: pipedrive.deal.su_execution_date,
      alServiceParent: pipedrive.deal.al_service_parent,
      alServiceChild: pipedrive.deal.al_service_child,
      alCompany: pipedrive.deal.al_company?.value,
      alReferralCustomer: pipedrive.deal.al_referral_customer?.value,
      quHowKnow: pipedrive.deal.qu_how_know,
      quHopeMoveSeason: pipedrive.deal.qu_hope_move_season,
      quDesiredPlan: pipedrive.deal.qu_desired_plan,
      documentStorageLocation: pipedrive.deal.document_storage_location,
      contractStartDate: pipedrive.deal.contract_start_date,
      acContractPeriod: pipedrive.deal.ac_contract_period,
      eaSupportCenter: pipedrive.deal.ea_support_center?.value,
      eaSupportCenterManager: pipedrive.deal.ea_support_center_manager?.value,
      eaApplicationDate: pipedrive.deal.ea_application_date,
      orgName: pipedrive.org.name,
      orgAddress: pipedrive.org.address,
      orgAddressBuildings: pipedrive.org.address_buildings,
      representative: pipedrive.org.representative,
      representativeKana: pipedrive.org.representative_kana,
      orgTel: pipedrive.org.tel,
      orgTelSecond: pipedrive.org.tel_second,
      openingTime: pipedrive.org.opening_time,
      openingPrefecture: pipedrive.org.opening_prefecture,
      industryGrandparent: pipedrive.org.industry_grandparent,
      industryParent: pipedrive.org.industry_parent,
      industryChild: pipedrive.org.industry_child,
      industryGrandchild: pipedrive.org.industry_grandchild,
      foundedStatus: pipedrive.org.founded_status,
      eaPostCoronaPropose: pipedrive.org.ea_post_corona_propose,
      foundedDate: pipedrive.org.founded_date,
      startupType: pipedrive.org.startup_type,
      office: pipedrive.org.office,
      orgRemark: pipedrive.org.remark,
      staffNumber: pipedrive.org.staff_number,
      personName: pipedrive.person?.name,
      personTel: pipedrive.person?.phone.map((v) => v.value).join(','),
      personEmail: pipedrive.person?.email.map((v) => v.value).join(','),
      mailSend: pipedrive.person?.mail_send,
      kana: pipedrive.person?.kana,
      personAddress: pipedrive.person?.address,
      personAddressBuildings: pipedrive.person?.address_buildings,
      borrowingPaymentDelay: pipedrive.person?.borrowing_payment_delay,
      borrowingPaymentDelayDetail:
        pipedrive.person?.borrowing_payment_delay_detail,
      borrowingDetail: pipedrive.person?.borrowing_detail,
      borrowingAmount: pipedrive.person?.borrowing_amount
        ? numberFormat(pipedrive.person.borrowing_amount)
        : '',
      borrowing: pipedrive.person?.borrowing,
      personRemark: pipedrive.person?.remark,
      delayedUtilityBillPayment: pipedrive.person?.delayed_utility_bill_payment,
      delayedUtilityBillPaymentDetail:
        pipedrive.person?.delayed_utility_bill_payment_detail,
      sex: pipedrive.person?.sex,
      industryExperience: pipedrive.person?.industry_experience,
      industryExperienceYears: pipedrive.person?.industry_experience_years,
      birthday: pipedrive.person?.birthday,
      delayedTax: pipedrive.person?.delayed_tax,
      delayedTaxDetail: pipedrive.person?.delayed_tax_detail,
      insurance: pipedrive.person?.insurance,
      remainingRepaymentPeriodForInsurance:
        pipedrive.person?.remaining_repayment_period_for_insurance,
      insuranceCompany: pipedrive.person?.insurance_company,
      insuranceCancellationRefundAmount: pipedrive.person
        ?.insurance_cancellation_refund_amount
        ? numberFormat(pipedrive.person.insurance_cancellation_refund_amount)
        : '',
      resource: pipedrive.person?.resource
        ? numberFormat(pipedrive.person.resource)
        : '',
      selfBankruptcy: pipedrive.person?.self_bankruptcy,
      selfBankruptcyDetails: pipedrive.person?.self_bankruptcy_details,
      personTelSecond: pipedrive.person?.tel_second,
      customerRank: pipedrive.person?.customer_rank,
      orgPostCode: pipedrive.org.post_code,
      personPostCode: pipedrive.person?.post_code,
    },
  })

  // 登録時処理
  const handleOnSubmit = async (data: DealFields) => {
    setLoading(true)

    const pipedriveApiToken = process.env.REACT_APP_PIPEDRIVE_API_TOKEN || ''

    if (!process.env.REACT_APP_PIPEDRIVE_API_TOKEN) {
      throw new Error(
        '環境変数 REACT_APP_PIPEDRIVE_API_TOKEN が設定されていません。'
      )
    }

    const apiToken =
      authUser?.pipedrive_token != null
        ? authUser?.pipedrive_token
        : pipedriveApiToken

    try {
      const dealResult = await updateDeal(data, apiToken)
      if (!dealResult) throw new Error('deal update error')
      const orgResult = await updateOrg(data, apiToken)
      if (!orgResult) throw new Error('org update error')
      const personResult = await updatePerson(data, apiToken)
      if (!personResult) throw new Error('person update error')

      setSnackBar({
        open: true,
        severity: 'success',
        message: '取引情報を更新しました',
      })
    } catch (e) {
      console.log(e)
      setSnackBar({
        open: true,
        severity: 'error',
        message: '取引情報の更新に失敗しました',
      })
    }

    const now = new Date()

    setbeforeModified(
      now.getFullYear() +
        '年' +
        (now.getMonth() + 1) +
        '月' +
        now.getDate() +
        '日 ' +
        now.getHours() +
        '時' +
        now.getMinutes() +
        '分'
    )

    setLoading(false)
  }

  // 成約ボタンダイアログ設定
  const [contractOpen, setContractOpen] = useState(false)

  const handleClickContractOpen = (formError: boolean): void => {
    if (!formError) {
      setSnackBar({
        open: true,
        severity: 'error',
        message: '必須項目が未入力のため成約に変更できません',
      })
      return
    }
    setContractOpen(true)
  }

  const handleContractClose = () => {
    setContractOpen(false)
  }

  const handleClickContract = async () => {
    const pipedriveApiToken = process.env.REACT_APP_PIPEDRIVE_API_TOKEN || ''

    if (!process.env.REACT_APP_PIPEDRIVE_API_TOKEN) {
      throw new Error(
        '環境変数 REACT_APP_PIPEDRIVE_API_TOKEN が設定されていません。'
      )
    }

    const apiToken =
      authUser?.pipedrive_token != null
        ? authUser?.pipedrive_token
        : pipedriveApiToken
    // 成約処理
    contractDeal(pipedrive.deal.id, apiToken)
    pipedrive.deal.status = 'won'
    setContractOpen(false)
  }

  // 失注ボタンダイアログ設定
  const [lostOpen, setLostOpen] = useState(false)

  const handleClickLostOpen = (formError: boolean): void => {
    if (!formError) {
      setSnackBar({
        open: true,
        severity: 'error',
        message: '必須項目が未入力のため失注に変更できません',
      })
      return
    }
    setLostOpen(true)
  }

  const handleLostClose = () => {
    setLostOpen(false)
  }

  const handleClickLost = async () => {
    const pipedriveApiToken = process.env.REACT_APP_PIPEDRIVE_API_TOKEN || ''

    if (!process.env.REACT_APP_PIPEDRIVE_API_TOKEN) {
      throw new Error(
        '環境変数 REACT_APP_PIPEDRIVE_API_TOKEN が設定されていません。'
      )
    }
    const apiToken =
      authUser?.pipedrive_token != null
        ? authUser?.pipedrive_token
        : pipedriveApiToken
    // 失注処理
    lostDeal(pipedrive.deal.id, getValues('lostReason'), apiToken)
    pipedrive.deal.status = 'lost'
    setLostOpen(false)
  }

  // 紹介元（組織）
  useEffect(() => {
    const setOrgForReferralSource = async () => {
      const referralSourceList: FormSelectList = [{}]
      // 該当の組織を取得
      const orgList = await searchOrgListByName(referralSource.name)

      orgList.map((v) => {
        referralSourceList.push({
          id: v.item.id,
          name: v.item.name,
        })
      })

      referralSourceList.shift()

      setReferralSourceList(referralSourceList)
    }

    if (referralSource.name) {
      setOrgForReferralSource()
    }
  }, [referralSource])

  // 紹介元担当者
  useEffect(() => {
    const setPersonForReferralSourceManager = async () => {
      const referralSourceManagerList: FormSelectList = [{}]
      // 該当の人物を取得
      const personList = await searchPersonListByName(
        referralSourceManager.name
      )

      personList.map((v) => {
        referralSourceManagerList.push({
          id: v.item.id,
          name: v.item.name,
        })
      })

      referralSourceManagerList.shift()

      setReferralSourceManagerList(referralSourceManagerList)
    }

    if (referralSourceManager.name) {
      setPersonForReferralSourceManager()
    }
    setLoading(false)
  }, [referralSourceManager])

  // パイプライン変更時ステージ更新
  const setStage = (e: React.ChangeEvent<{ value: unknown }>): void => {
    const tmpStageList: FormSelectList = [{}]

    pipedrive.stageList
      .filter((v) => v.pipeline_id == Number(e.target.value))
      .map((v) =>
        tmpStageList.push({
          id: v.id,
          name: v.name,
        })
      )

    tmpStageList.shift()
    setStageList(tmpStageList)
  }

  // 組織郵便番号変更時
  const setOrgAddressByPostCode = (postCode: string) => {
    fetch(`https://api.zipaddress.net/?zipcode=${postCode}`, {
      mode: 'cors',
    })
      .then((result) => {
        return result.json()
      })
      .then((result) => {
        setOrgAddress(result.data?.fullAddress || '')
      })
  }

  // 人物郵便番号変更時
  const setPersonAddressByPostCode = (postCode: string) => {
    fetch(`https://api.zipaddress.net/?zipcode=${postCode}`, {
      mode: 'cors',
    })
      .then((result) => {
        return result.json()
      })
      .then((result) => {
        setPersonAddress(result.data?.fullAddress || '')
      })
  }

  // 起業する業種（親）の選択肢を準備
  const setIndustryParentFormList = async (pipedrive_field_id: number) => {
    if (!pipedrive_field_id) {
      setIndustryParentList([{}])
      setIndustryChildList([{}])
      setIndustryGrandchildList([{}])
      setValue('industryParent', 0)
      setValue('industryChild', 0)
      setValue('industryGrandchild', 0)
      return
    }
    const industryGrandparentId: number = INDUSTRY_GRANDPARENT.filter(
      (v) => v.pipedrive_field_id == pipedrive_field_id
    )[0].id

    const industryParentList = await getIndustryParent(industryGrandparentId)

    const tmpIndustryParentList: IndustryParent = [{}]

    industryParentList.map((v) =>
      tmpIndustryParentList.push({
        id: v.id,
        pipedrive_field_id: v.pipedrive_field_id,
        industry_parent_name: v.industry_parent_name,
      })
    )

    tmpIndustryParentList.shift()

    setIndustryParentList(tmpIndustryParentList)
    setIndustryChildList([{}])
    setIndustryGrandchildList([{}])
    setValue('industryParent', 0)
    setValue('industryChild', 0)
    setValue('industryGrandchild', 0)
  }

  // 起業する業種（子）の選択肢を準備
  const setIndustryChildFormList = async (pipedrive_field_id: number) => {
    if (!pipedrive_field_id) {
      setIndustryChildList([{}])
      setIndustryGrandchildList([{}])
      setValue('industryChild', 0)
      setValue('industryGrandchild', 0)
      return
    }
    const industryParentId = industryParentList.filter(
      (v) => v.pipedrive_field_id == pipedrive_field_id
    )[0].id

    if (industryParentId) {
      const industryChildList = await getIndustryChild(industryParentId)

      const tmpIndustryChildList: IndustryChild = [{}]

      industryChildList.map((v) =>
        tmpIndustryChildList.push({
          id: v.id,
          pipedrive_field_id: v.pipedrive_field_id,
          industry_child_name: v.industry_child_name,
        })
      )

      tmpIndustryChildList.shift()

      setIndustryChildList(tmpIndustryChildList)
      setIndustryGrandchildList([{}])
      setValue('industryChild', 0)
      setValue('industryGrandchild', 0)
    }
  }

  // 起業する業種（孫）の選択肢を準備
  const setIndustryGrandchildFormList = async (pipedrive_field_id: number) => {
    if (!pipedrive_field_id) {
      setIndustryGrandchildList([{}])
      setValue('industryGrandchild', 0)
      return
    }
    const industryChildId = industryChildList.filter(
      (v) => v.pipedrive_field_id == pipedrive_field_id
    )[0].id

    if (industryChildId) {
      const industryGrandchildList = await getIndustryGrandchild(
        industryChildId
      )

      const tmpIndustryGrandchildList: IndustryGrandchild = [{}]

      industryGrandchildList.map((v) =>
        tmpIndustryGrandchildList.push({
          id: v.id,
          pipedrive_field_id: v.pipedrive_field_id,
          industry_grandchild_name: v.industry_grandchild_name,
        })
      )

      tmpIndustryGrandchildList.shift()

      setIndustryGrandchildList(tmpIndustryGrandchildList)
      setValue('industryGrandchild', 0)
    }
  }

  // 読み込み終了までローディング
  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress />
      </Backdrop>
    )
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Input
        {...register('dealId')}
        id="dealId"
        type="hidden"
        value={pipedrive.deal.id}
      />
      <Input
        {...register('orgId')}
        id="orgId"
        type="hidden"
        value={pipedrive.org.id}
      />
      <Input
        {...register('personId')}
        id="personId"
        type="hidden"
        value={pipedrive.person?.id}
      />
      <Grid container spacing={3} item xs={12}>
        <Grid container xs={12} md={6} lg={3}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className={classes.dealButton}
            startIcon={<MonetizationOnIcon />}
          >
            取引
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.pipedriveButton}
            target="_blank"
            startIcon={<LinkIcon />}
            href={`https://solabo.pipedrive.com/deal/${pipedrive.deal.id}`}
          >
            Pipedrive
          </Button>
          {beforeModified && (
            <span className={classes.beforeModified}>
              前回更新時間：{beforeModified}
            </span>
          )}
        </Grid>
        <Grid container xs={12} md={6} lg={9} justify="flex-end">
          {pipedrive.deal.status == 'open' && (
            <>
              <Button
                variant="contained"
                size="large"
                className={classes.contractButton}
                onClick={async () => {
                  // 成約するためのその他エラーチェック
                  const formError = await trigger()
                  handleClickContractOpen(formError)
                }}
              >
                成約
              </Button>
              <Dialog
                open={contractOpen}
                onClose={handleContractClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{'成約確認'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Typography gutterBottom>
                      取引のステータスを「
                      <span style={{ color: '#08a742', fontWeight: 'bold' }}>
                        成約
                      </span>
                      」へ変更します。よろしいですか？
                    </Typography>
                    <Typography color="error">
                      ※ 入力内容が同時に登録されます
                    </Typography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleContractClose} color="primary">
                    キャンセル
                  </Button>
                  <Button
                    onClick={handleSubmit(async (data) => {
                      await handleOnSubmit(data)
                      handleClickContract()
                    })}
                    color="primary"
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              <Button
                variant="contained"
                size="large"
                className={classes.lostButton}
                onClick={async () => {
                  // 失注するためのその他エラーチェック
                  const formError = await trigger()
                  handleClickLostOpen(formError)
                }}
              >
                失注
              </Button>
              <Dialog
                open={lostOpen}
                onClose={handleLostClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">失注確認</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <Typography gutterBottom>
                      取引のステータスを「
                      <span style={{ color: '#f94839', fontWeight: 'bold' }}>
                        失注
                      </span>
                      」へ変更します。よろしいですか？
                    </Typography>
                    <Typography color="error">
                      ※ 入力内容が同時に登録されます
                    </Typography>
                  </DialogContentText>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="owner-select-label">失注理由</InputLabel>
                    <Controller
                      name="lostReason"
                      control={control}
                      rules={{ required: '※ 失注理由を選択してください' }}
                      render={({ field }) => (
                        <Select {...field}>
                          <MenuItem value="">選択してください</MenuItem>
                          {pipedrive.dealFields
                            .filter((v) => v.id == 12475)
                            .map((lostReason) =>
                              lostReason.options
                                .filter(
                                  (v) =>
                                    v.label.match('【共】') ||
                                    v.label.match('【融】')
                                )
                                .map((e) => (
                                  <MenuItem key={e.id} value={e.label}>
                                    {e.label}
                                  </MenuItem>
                                ))
                            )}
                        </Select>
                      )}
                    />
                  </FormControl>
                  {errors.lostReason && (
                    <span className={classes.errorMessage}>
                      {errors.lostReason.message}
                    </span>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleLostClose} color="primary">
                    キャンセル
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleSubmit(async (data) => {
                      await handleOnSubmit(data)
                      handleClickLost()
                    })}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
          {pipedrive.deal.status == 'won' && (
            <>
              <Button
                variant="contained"
                size="large"
                className={classes.contractButton}
              >
                成約済み
              </Button>
            </>
          )}
          {pipedrive.deal.status == 'lost' && (
            <>
              <Button
                variant="contained"
                size="large"
                className={classes.lostButton}
              >
                失注済み
              </Button>
            </>
          )}
          {pipedrive.deal.status == 'deleted' && (
            <>
              <Button
                variant="contained"
                size="large"
                className={classes.lostButton}
              >
                削除済み
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">オーナー</InputLabel>
              <Controller
                name="dealUserId"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.ownerList
                      .filter((v) => v.active_flag == true)
                      .map((dealOwner) => (
                        <MenuItem key={dealOwner.id} value={dealOwner.id}>
                          {dealOwner.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="grouped-select" style={{ color: 'red' }}>
                パイプライン
              </InputLabel>
              <Controller
                name="pipelineId"
                control={control}
                rules={{
                  required: '※ パイプラインを選択してください',
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                      setStage(e)
                    }}
                  >
                    <MenuItem value="">
                      <em>選択してください</em>
                    </MenuItem>
                    {pipedrive.pipelineList.map((pipeline) => (
                      <MenuItem key={pipeline.id} value={pipeline.id}>
                        {pipeline.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            {errors.pipelineId && (
              <span className={classes.errorMessage}>
                {errors.pipelineId.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="grouped-select" style={{ color: 'red' }}>
                ステージ
              </InputLabel>
              <Controller
                name="stageId"
                control={control}
                rules={{
                  required: '※ ステージを選択してください',
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={async (e) => {
                      await field.onChange(e)
                    }}
                  >
                    <MenuItem value="">
                      <em>選択してください</em>
                    </MenuItem>
                    {stageList.map((stage) => (
                      <MenuItem key={stage.id} value={stage.id}>
                        {stage.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            {errors.stageId && (
              <span className={classes.errorMessage}>
                {errors.stageId.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                {...register('inquiryRoute')}
                id="combo-box-demo"
                options={contactMethodList}
                getOptionLabel={(option) => option.name ?? ''}
                getOptionSelected={(option) => option.id == financyRoute.id}
                value={financyRoute}
                onChange={(_e, options) => {
                  setValue('inquiryRoute', options?.id)
                  setFinancyRoute({ id: options?.id, name: options?.name })
                }}
                renderInput={(params) => (
                  <TextField {...params} label="問い合わせ経路" />
                )}
              />
            </FormControl>
            {errors.inquiryRoute && (
              <span className={classes.errorMessage}>
                {errors.inquiryRoute.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">
                相談内容（フォーム）
              </InputLabel>
              <Controller
                name="consultationContentForm"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.dealFields
                      .filter((v) => v.id == 12567)
                      .map((consultationContentForm) =>
                        consultationContentForm.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                {...register('referralSource')}
                id="combo-box-demo"
                options={referralSourceList}
                getOptionLabel={(option) => option.name ?? ''}
                getOptionSelected={(option) => option.id == referralSource.id}
                value={referralSource}
                onChange={(_e, options) => {
                  setValue('referralSource', options?.id)
                  setReferralSource({ id: options?.id, name: options?.name })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="紹介元"
                    onKeyDown={(e) => {
                      if (e.code == 'Enter') {
                        setReferralSource({
                          name: (e.target as HTMLInputElement).value,
                        })
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                {...register('referralSourceManager')}
                id="combo-box-demo"
                options={referralSourceManagerList}
                getOptionLabel={(option) => option.name ?? ''}
                getOptionSelected={(option) =>
                  option.id == referralSourceManager.id
                }
                value={referralSourceManager}
                onChange={(_e, options) => {
                  setValue('referralSourceManager', options?.id)
                  setReferralSourceManager({
                    id: options?.id,
                    name: options?.name,
                  })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="紹介元担当者"
                    onKeyDown={(e) => {
                      if (e.code == 'Enter') {
                        setReferralSourceManager({
                          name: (e.target as HTMLInputElement).value,
                        })
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('desiredAmountFrom', {
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                variant="standard"
                fullWidth
                id="desiredAmountFrom"
                label="借入希望額From"
                autoComplete="desiredAmountFrom"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue('desiredAmountFrom', numberFormat(Number(amount)))
                  }
                }}
              />
            </FormControl>
            {errors.desiredAmountFrom && (
              <span className={classes.errorMessage}>
                {errors.desiredAmountFrom.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('desiredAmountTo', {
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: '※ 半角数字のみで入力してください',
                  },
                })}
                variant="standard"
                fullWidth
                id="desiredAmountTo"
                label="借入希望額To"
                autoComplete="desiredAmountTo"
                onBlur={(e) => {
                  if (e.target.value) {
                    const amount = e.target.value.replace(/,/g, '')
                    setValue('desiredAmountTo', numberFormat(Number(amount)))
                  }
                }}
              />
            </FormControl>
            {errors.desiredAmountTo && (
              <span className={classes.errorMessage}>
                {errors.desiredAmountTo.message}
              </span>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('requestedDocument')}
                multiline
                variant="outlined"
                fullWidth
                id="requestedDocument"
                label="依頼中の書類"
                autoComplete="requestedDocument"
                minRows={3}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('consultationContent')}
                multiline
                variant="outlined"
                fullWidth
                id="consultationContent"
                label="相談内容"
                autoComplete="consultationContent"
                minRows={3}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('remark')}
                multiline
                variant="outlined"
                fullWidth
                id="remark"
                label="備考"
                autoComplete="remark"
                minRows={3}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl className={classes.formControl}>
              <TextField
                {...register('remarkSecond')}
                multiline
                variant="outlined"
                fullWidth
                id="remarkSecond"
                label="備考2"
                autoComplete="remarkSecond"
                minRows={3}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.topButton}
        startIcon={<RateReviewIcon />}
      >
        補助金
      </Button>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={4} lg={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">補助金種類</InputLabel>
              <Controller
                name="suSubsidyType"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.dealFields
                      .filter((v) => v.id == 12548)
                      .map((suSubsidyType) =>
                        suSubsidyType.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.topButton}
        startIcon={<HomeWorkIcon />}
      >
        カルテット
      </Button>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">知ったきっかけ</InputLabel>
              <Controller
                name="quHowKnow"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.dealFields
                      .filter((v) => v.id == 12602)
                      .map((quHowKnow) =>
                        quHowKnow.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Controller
                name="quHopeMoveSeason"
                control={control}
                render={({ field }) => (
                  <MuiPickersUtilsProvider
                    utils={ExtendedUtils}
                    locale={jaLocale}
                  >
                    <KeyboardDatePicker
                      {...field}
                      label="入居希望時期"
                      value={field.value}
                      format="yyyy-MM-dd"
                      autoOk={true}
                      variant="inline"
                      onChange={(_date, v) => {
                        setValue('quHopeMoveSeason', v)
                      }}
                      invalidDateMessage="※ フォーマットに従って入力してください"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">希望プラン</InputLabel>
              <Controller
                name="quDesiredPlan"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.dealFields
                      .filter((v) => v.id == 12604)
                      .map((quDesiredPlan) =>
                        quDesiredPlan.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {/* 組織 */}
      <Button
        variant="contained"
        color="secondary"
        size="small"
        className={classes.betweenButton}
        startIcon={<BusinessIcon />}
      >
        組織
      </Button>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">オーナー</InputLabel>
              <Controller
                name="orgOwnerId"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.ownerList
                      .filter((v) => v.active_flag == true)
                      .map((orgOwner) => (
                        <MenuItem key={orgOwner.id} value={orgOwner.id}>
                          {orgOwner.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('orgName', {
                required: '※ 組織名を入力してください',
              })}
              margin="normal"
              fullWidth
              id="orgName"
              label={<span style={{ color: 'red' }}>組織名</span>}
              size="small"
            />
            {errors.orgName && (
              <span className={classes.errorMessage}>
                {errors.orgName.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('representative', {
                required: '※ 代表者名を入力してください',
              })}
              margin="normal"
              fullWidth
              id="representative"
              label={<span style={{ color: 'red' }}>代表者名</span>}
              size="small"
            />
            {errors.representative && (
              <span className={classes.errorMessage}>
                {errors.representative.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('representativeKana', {
                required: '※ 代表者名ふりがなを入力してください',
                pattern: {
                  value: /^[ぁ-んー]+$/,
                  message: '※ ふりがなのみで入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="representativeKana"
              label={<span style={{ color: 'red' }}>代表者名ふりがな</span>}
              size="small"
            />
            {errors.representativeKana && (
              <span className={classes.errorMessage}>
                {errors.representativeKana.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('orgTel', {
                required: '※ 電話番号を入力してください',
                pattern: {
                  value: /^0\d{1,4}-\d{1,4}-\d{4}$/,
                  message: '※ 電話番号形式で入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="orgTel"
              label={<span style={{ color: 'red' }}>電話番号</span>}
              size="small"
            />
            {errors.orgTel && (
              <span className={classes.errorMessage}>
                {errors.orgTel.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <TextField
              {...register('orgTelSecond', {
                pattern: {
                  value: /^0\d{1,4}-\d{1,4}-\d{4}$/,
                  message: '※ 電話番号形式で入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="orgTelSecond"
              label="電話番号2"
              size="small"
            />
            {errors.orgTelSecond && (
              <span className={classes.errorMessage}>
                {errors.orgTelSecond.message}
              </span>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={6} lg={2}>
            <TextField
              {...register('orgPostCode', {
                pattern: {
                  value: /^[0-9]{3}-[0-9]{4}$/,
                  message: '※ ハイフン入り(3-4)形式で入力してください',
                },
              })}
              margin="normal"
              fullWidth
              id="orgPostCode"
              label="郵便番号"
              size="small"
              onBlur={(e) => setOrgAddressByPostCode(e.target.value)}
            />
            {errors.orgPostCode && (
              <span className={classes.errorMessage}>
                {errors.orgPostCode.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('orgAddress')}
              margin="normal"
              fullWidth
              id="orgAddress"
              label="住所"
              size="small"
              value={orgAddress}
              onChange={(e) => setOrgAddress(e.target.value)}
            />
            {errors.orgAddress && (
              <span className={classes.errorMessage}>
                {errors.orgAddress.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              {...register('orgAddressBuildings')}
              margin="normal"
              fullWidth
              id="orgAddressBuildings"
              label="住所(ビル・マンション)"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                起業する業種（祖父母）
              </InputLabel>
              <Controller
                name="industryGrandparent"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => {
                      setIndustryParentFormList(e.target.value as number)
                      setValue('industryGrandparent', e.target.value as number)
                    }}
                  >
                    <MenuItem value="">選択してください</MenuItem>
                    {INDUSTRY_GRANDPARENT.map((industryGrandparent) => (
                      <MenuItem
                        key={industryGrandparent.pipedrive_field_id}
                        value={industryGrandparent.pipedrive_field_id}
                      >
                        {industryGrandparent.industry_grandparent_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                起業する業種（親）
              </InputLabel>
              <Controller
                name="industryParent"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => {
                      setIndustryChildFormList(e.target.value as number)
                      setValue('industryParent', e.target.value as number)
                    }}
                  >
                    <MenuItem value="">選択してください</MenuItem>
                    {industryParentList.map((industryParent) => (
                      <MenuItem
                        key={industryParent.pipedrive_field_id}
                        value={industryParent.pipedrive_field_id}
                      >
                        {industryParent.industry_parent_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                起業する業種（子）
              </InputLabel>
              <Controller
                name="industryChild"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => {
                      setIndustryGrandchildFormList(e.target.value as number)
                      setValue('industryChild', e.target.value as number)
                    }}
                  >
                    <MenuItem value="">選択してください</MenuItem>
                    {industryChildList.map((industryChild) => (
                      <MenuItem
                        key={industryChild.pipedrive_field_id}
                        value={industryChild.pipedrive_field_id}
                      >
                        {industryChild.industry_child_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                起業する業種（孫）
              </InputLabel>
              <Controller
                name="industryGrandchild"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => {
                      setValue('industryGrandchild', e.target.value as number)
                    }}
                  >
                    <MenuItem value="">選択してください</MenuItem>
                    {industryGrandchildList.map((industryGrandchild) => (
                      <MenuItem
                        key={industryGrandchild.pipedrive_field_id}
                        value={industryGrandchild.pipedrive_field_id}
                      >
                        {industryGrandchild.industry_grandchild_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Controller
                name="openingTime"
                control={control}
                rules={{
                  required: '※ 開業時期を選択してください',
                }}
                render={({ field }) => (
                  <MuiPickersUtilsProvider
                    utils={ExtendedUtils}
                    locale={jaLocale}
                  >
                    <KeyboardDatePicker
                      {...field}
                      label={<span style={{ color: 'red' }}>開業時期</span>}
                      value={field.value}
                      format="yyyy-MM-dd"
                      autoOk={true}
                      variant="inline"
                      onChange={(_date, v) => {
                        setValue('openingTime', v)
                      }}
                      invalidDateMessage="※ フォーマットに従って入力してください"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </FormControl>
            {errors.openingTime && (
              <span className={classes.errorMessage}>
                {errors.openingTime.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                {...register('openingPrefecture', {
                  required: '※ 開業予定都道府県を入力してください',
                })}
                id="combo-box-demo"
                options={openingPrefectureList}
                getOptionLabel={(option) => option.name ?? ''}
                getOptionSelected={(option) =>
                  option.id == openingPrefecture.id
                }
                value={openingPrefecture}
                onChange={(_e, options) => {
                  setValue('openingPrefecture', options?.id)
                  setOpeningPrefecture({ id: options?.id, name: options?.name })
                  trigger('openingPrefecture')
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span style={{ color: 'red' }}>開業予定都道府県</span>
                    }
                  />
                )}
              />
            </FormControl>
            {errors.openingPrefecture && (
              <span className={classes.errorMessage}>
                {errors.openingPrefecture.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel style={{ color: 'red' }} id="owner-select-label">
                創業状況
              </InputLabel>
              <Controller
                name="foundedStatus"
                control={control}
                rules={{
                  required: '※ 創業状況を選択してください',
                }}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4045)
                      .map((foundedStatus) =>
                        foundedStatus.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
            {errors.foundedStatus && (
              <span className={classes.errorMessage}>
                {errors.foundedStatus.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <Controller
                name="foundedDate"
                control={control}
                render={({ field }) => (
                  <MuiPickersUtilsProvider
                    utils={ExtendedUtils}
                    locale={jaLocale}
                  >
                    <KeyboardDatePicker
                      {...field}
                      label="創業年月"
                      value={field.value}
                      format="yyyy-MM-dd"
                      autoOk={true}
                      variant="inline"
                      onChange={(_date, v) => {
                        setValue('foundedDate', v)
                      }}
                      invalidDateMessage="※ フォーマットに従って入力してください"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </FormControl>
            {errors.foundedDate && (
              <span className={classes.errorMessage}>
                {errors.foundedDate.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel style={{ color: 'red' }} id="owner-select-label">
                起業形態
              </InputLabel>
              <Controller
                name="startupType"
                control={control}
                rules={{
                  required: '※ 起業形態を選択してください',
                }}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4055)
                      .map((startupType) =>
                        startupType.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
            {errors.startupType && (
              <span className={classes.errorMessage}>
                {errors.startupType.message}
              </span>
            )}
          </Grid>
          <Grid item xs={6} md={2} lg={1}>
            <FormControl className={classes.formControl}>
              <InputLabel id="owner-select-label">決算月</InputLabel>
              <Controller
                name="closingMonth"
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="">選択してください</MenuItem>
                    {pipedrive.orgFields
                      .filter((v) => v.id == 4084)
                      .map((closingMonth) =>
                        closingMonth.options.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))
                      )}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              {...register('orgRemark')}
              multiline
              variant="outlined"
              fullWidth
              id="orgRemark"
              label="備考"
              autoComplete="orgRemark"
              minRows={3}
            />
          </Grid>
        </Grid>
      </Grid>
      {pipedrive.person && (
        <span>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className={classes.betweenButton}
            startIcon={<PersonIcon />}
          >
            人物
          </Button>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label">オーナー</InputLabel>
                  <Controller
                    name="personOwnerId"
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {pipedrive.ownerList
                          .filter((v) => v.active_flag == true)
                          .map((personOwner) => (
                            <MenuItem
                              key={personOwner.id}
                              value={personOwner.id}
                            >
                              {personOwner.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <TextField
                  {...register('personName', {
                    required: '※ 顧客名を入力してください',
                  })}
                  margin="normal"
                  fullWidth
                  id="personName"
                  label={<span style={{ color: 'red' }}>顧客名</span>}
                  size="small"
                />
                {errors.personName && (
                  <span className={classes.errorMessage}>
                    {errors.personName.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <TextField
                  {...register('kana', {
                    required: '※ ふりがなを入力してください',
                    pattern: {
                      value: /^[ぁ-んー]+$/,
                      message: '※ ふりがなのみで入力してください',
                    },
                  })}
                  margin="normal"
                  fullWidth
                  id="kana"
                  label={<span style={{ color: 'red' }}>ふりがな</span>}
                  size="small"
                />
                {errors.kana && (
                  <span className={classes.errorMessage}>
                    {errors.kana.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <TextField
                  {...register('personTel', {
                    required: '※ 電話番号を入力してください',
                    pattern: {
                      value: /^0\d{1,4}-\d{1,4}-\d{4}$/,
                      message: '※ 電話番号形式で入力してください',
                    },
                  })}
                  margin="normal"
                  fullWidth
                  id="personTel"
                  label={<span style={{ color: 'red' }}>電話番号</span>}
                  size="small"
                />
                {errors.personTel && (
                  <span className={classes.errorMessage}>
                    {errors.personTel.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <TextField
                  {...register('personTelSecond', {
                    pattern: {
                      value: /^0\d{1,4}-\d{1,4}-\d{4}$/,
                      message: '※ 電話番号形式で入力してください',
                    },
                  })}
                  margin="normal"
                  fullWidth
                  id="personTelSecond"
                  label="電話番号2"
                  size="small"
                />
                {errors.personTelSecond && (
                  <span className={classes.errorMessage}>
                    {errors.personTelSecond.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <TextField
                  {...register('personEmail')}
                  margin="normal"
                  fullWidth
                  id="personEmail"
                  label="電子メール"
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={12} md={6} lg={2}>
                <TextField
                  {...register('personPostCode', {
                    pattern: {
                      value: /^[0-9]{3}-[0-9]{4}$/,
                      message: '※ ハイフン入り(3-4)形式で入力してください',
                    },
                  })}
                  margin="normal"
                  fullWidth
                  id="personPostCode"
                  label="郵便番号"
                  size="small"
                  onBlur={(e) => setPersonAddressByPostCode(e.target.value)}
                />
                {errors.personPostCode && (
                  <span className={classes.errorMessage}>
                    {errors.personPostCode.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  {...register('personAddress')}
                  margin="normal"
                  fullWidth
                  id="personAddress"
                  label="住所"
                  size="small"
                  value={personAddress}
                  onChange={(e) => setPersonAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  {...register('personAddressBuildings')}
                  margin="normal"
                  fullWidth
                  id="personAddressBuildings"
                  label="住所(ビル・マンション等)"
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <Controller
                    name="birthday"
                    control={control}
                    render={({ field }) => (
                      <MuiPickersUtilsProvider
                        utils={ExtendedUtils}
                        locale={jaLocale}
                      >
                        <KeyboardDatePicker
                          {...field}
                          label="生年月日"
                          value={field.value}
                          format="yyyy-MM-dd"
                          autoOk={true}
                          variant="inline"
                          onChange={(_date, v) => {
                            setValue('birthday', v)
                          }}
                          invalidDateMessage="※ フォーマットに従って入力してください"
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label">性別</InputLabel>
                  <Controller
                    name="sex"
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {pipedrive.personFields
                          .filter((v) => v.id == 9069)
                          .map((sex) =>
                            sex.options.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label">業界経験</InputLabel>
                  <Controller
                    name="industryExperience"
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {pipedrive.personFields
                          .filter((v) => v.id == 9133)
                          .map((industryExperience) =>
                            industryExperience.options.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <TextField
                  {...register('industryExperienceYears', {
                    pattern: {
                      value: /^[0-9,]+$/,
                      message: '※ 半角数字のみで入力してください',
                    },
                  })}
                  margin="normal"
                  fullWidth
                  id="industryExperienceYears"
                  label="業界経験年数"
                  size="small"
                />
                {errors.industryExperienceYears && (
                  <span className={classes.errorMessage}>
                    {errors.industryExperienceYears.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <TextField
                  {...register('resource', {
                    pattern: {
                      value: /^[0-9,]+$/,
                      message: '※ 半角数字のみで入力してください',
                    },
                  })}
                  margin="normal"
                  fullWidth
                  id="resource"
                  label="自己資金"
                  size="small"
                  onBlur={(e) => {
                    if (e.target.value) {
                      const amount = e.target.value.replace(/,/g, '')
                      setValue('resource', numberFormat(Number(amount)))
                    }
                  }}
                />
                {errors.resource && (
                  <span className={classes.errorMessage}>
                    {errors.resource.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label">
                    SoLaboからのメール受信
                  </InputLabel>
                  <Controller
                    name="mailSend"
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {pipedrive.personFields
                          .filter((v) => v.id == 9143)
                          .map((mailSend) =>
                            mailSend.options.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label">顧客ランク</InputLabel>
                  <Controller
                    name="customerRank"
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {pipedrive.personFields
                          .filter((v) => v.id == 9145)
                          .map((customerRank) =>
                            customerRank.options.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  {...register('personRemark')}
                  multiline
                  variant="outlined"
                  fullWidth
                  id="personRemark"
                  label="備考"
                  autoComplete="personRemark"
                  minRows={3}
                />
              </Grid>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.topButton}
            startIcon={<MoneyOffIcon />}
          >
            借入
          </Button>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label">借入(個人)</InputLabel>
                  <Controller
                    name="borrowing"
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {pipedrive.personFields
                          .filter((v) => v.id == 9093)
                          .map((borrowing) =>
                            borrowing.options.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <TextField
                  {...register('borrowingAmount', {
                    pattern: {
                      value: /^[0-9,]+$/,
                      message: '※ 半角数字のみで入力してください',
                    },
                  })}
                  margin="normal"
                  fullWidth
                  id="borrowingAmount"
                  label="借入金額(個人)"
                  size="small"
                  onBlur={(e) => {
                    if (e.target.value) {
                      const amount = e.target.value.replace(/,/g, '')
                      setValue('borrowingAmount', numberFormat(Number(amount)))
                    }
                  }}
                />
                {errors.borrowingAmount && (
                  <span className={classes.errorMessage}>
                    {errors.borrowingAmount.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={12} md={8} lg={3}>
                <TextField
                  {...register('borrowingDetail')}
                  margin="normal"
                  fullWidth
                  id="borrowingDetail"
                  label="借入詳細(個人)"
                  size="small"
                />
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label">借入支払遅延</InputLabel>
                  <Controller
                    name="borrowingPaymentDelay"
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {pipedrive.personFields
                          .filter((v) => v.id == 9092)
                          .map((borrowingPaymentDelay) =>
                            borrowingPaymentDelay.options.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={10} lg={3}>
                <TextField
                  {...register('borrowingPaymentDelayDetail')}
                  margin="normal"
                  fullWidth
                  id="borrowingPaymentDelayDetail"
                  label="借入支払遅延詳細"
                  size="small"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} item xs={12}>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label">
                    公共料金支払遅延
                  </InputLabel>
                  <Controller
                    name="delayedUtilityBillPayment"
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {pipedrive.personFields
                          .filter((v) => v.id == 9094)
                          .map((delayedUtilityBillPayment) =>
                            delayedUtilityBillPayment.options.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={10} lg={3}>
                <TextField
                  {...register('delayedUtilityBillPaymentDetail')}
                  margin="normal"
                  fullWidth
                  id="delayedUtilityBillPaymentDetail"
                  label="公共料金支払遅延詳細"
                  size="small"
                />
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label">税金滞納</InputLabel>
                  <Controller
                    name="delayedTax"
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {pipedrive.personFields
                          .filter((v) => v.id == 9096)
                          .map((delayedTax) =>
                            delayedTax.options.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={10} lg={3}>
                <TextField
                  {...register('delayedTaxDetail')}
                  margin="normal"
                  fullWidth
                  id="delayedTaxDetail"
                  label="税金滞納詳細"
                  size="small"
                />
              </Grid>
              <Grid item xs={6} md={2} lg={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label">
                    過去に自己破産・債務整理経験
                  </InputLabel>
                  <Controller
                    name="selfBankruptcy"
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {pipedrive.personFields
                          .filter((v) => v.id == 9098)
                          .map((selfBankruptcy) =>
                            selfBankruptcy.options.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={10} lg={3}>
                <TextField
                  {...register('selfBankruptcyDetails')}
                  margin="normal"
                  fullWidth
                  id="selfBankruptcyDetails"
                  label="過去に自己破産・債務整理経験詳細"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
        </span>
      )}
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Box component="span" m={3} className={classes.buttonWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={() => unregister('lostReason')}
          >
            登録
          </Button>
        </Box>
      </Grid>
      <Snackbar
        open={snackBar.open}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity={snackBar.severity} onClose={handleClose}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </form>
  )
}

export default CallDisplay
