import {
  IndustryChild,
  IndustryGrandchild,
  IndustryParent,
} from 'models/industry'

import back from './back'

// 起業する業種（親）を取得
export const getIndustryParent = async (
  industry_grandparent_id: number
): Promise<IndustryParent> => {
  return await back
    .post(`/industry/getParent`, {
      industry_grandparent_id: industry_grandparent_id,
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw new Error(err.response.data)
    })
}

// 起業する業種（親）を取得
export const getIndustryChild = async (
  industry_parent_id: number | undefined
): Promise<IndustryChild> => {
  return await back
    .post(`/industry/getChild`, {
      industry_parent_id: industry_parent_id,
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw new Error(err.response.data)
    })
}

// 起業する業種（孫）を取得
export const getIndustryGrandchild = async (
  industry_child_id: number | undefined
): Promise<IndustryGrandchild> => {
  return await back
    .post(`/industry/getGrandchild`, {
      industry_child_id: industry_child_id,
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw new Error(err.response.data)
    })
}
