import { useState, useEffect } from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Backdrop,
  CircularProgress,
  Box,
  Button,
  Snackbar,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { Alert, Autocomplete } from '@material-ui/lab'
import { FormSelectObject, FormSelectList, DealApi } from 'models/deal'
import { DealFieldsPipedrive, FreeDialFields } from 'models/dealFields'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import jaLocale from 'date-fns/locale/ja'

import format from 'date-fns/format'

import { Create } from '@material-ui/icons'
import { useAuthUser } from 'components/modules/AuthUserContext'
import {
  FINANCE_MEMBER_DISPLAY,
  SUBSIDY_MEMBER_DISPLAY,
  POST_CORONA,
  INDUSTRY_GRANDPARENT,
  PREFECTURE,
  PIPELINE,
} from 'components/modules/Const'
import { getDealFields } from 'api/dealField'
import { getOrgFields } from 'api/orgFields'
import GenericTemplate from 'components/templates/GenericTemplate'
import { createFreeDialDeal, existFreeDialDeal } from 'api/deal'
import {
  searchPersonListByPhone,
  getPersonById,
  getDealListByPersonId,
} from 'api/person'
import { getOrgById } from 'api/org'
import BusinessIcon from '@material-ui/icons/Business'
import PersonIcon from '@material-ui/icons/Person'
import LaunchIcon from '@material-ui/icons/Launch'
import RefreshIcon from '@material-ui/icons/Refresh'
import { addHours } from 'date-fns'

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: Date) {
    return format(date, 'yyyy年 MMM', { locale: this.locale })
  }
  getDatePickerHeaderText(date: Date) {
    return format(date, 'MMMd日(E)', { locale: this.locale })
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    formControl: {
      width: '100%',
      marginTop: '13px',
      marginBottom: '8px',
    },
    errorMessage: {
      color: 'red',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px',
    },
    submitButton: {
      marginTop: '10px',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    border: {
      borderTop: '2px solid #e5e5e5 ',
    },
    dealButton: {
      margin: '16px',
    },
    topButton: {
      margin: theme.spacing(1),
    },
    betweenButton: {
      margin: theme.spacing(1),
      marginTop: '40px',
    },
    contractButton: {
      margin: theme.spacing(1),
      backgroundColor: '#08a742',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '14px',
      '&:hover': {
        background: '#30b560',
      },
    },
    lostButton: {
      margin: theme.spacing(1),
      backgroundColor: '#f94839',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '14px',
      '&:hover': {
        background: '#fa6559',
      },
    },
    pipedriveButton: {
      margin: theme.spacing(1),
      fontWeight: 'bold',
      fontSize: '14px',
    },
    beforeModified: {
      color: 'green',
      margin: theme.spacing(1),
      paddingTop: '12px',
    },
    activityAddButton: {
      margin: theme.spacing(1),
      fontWeight: 'bold',
      fontSize: '14px',
    },
  })
)

type SnackBarParam = {
  open: boolean
  severity: 'error' | 'success' | 'warning' | 'info' | undefined
  message: string
}

const FreeDialDeal: React.VFC = (): JSX.Element => {
  // 変数定義
  const authUser = useAuthUser()
  const classes = useStyles()

  const contactMethodList: FormSelectList = [{}]
  const openingPrefectureList: FormSelectList = [{}]

  const [dealFields, setDealFields] = useState<DealFieldsPipedrive>()
  const [existPhone, setExistPhone] = useState<FormSelectObject>({})
  const [existPhoneList, setExistPhoneList] = useState<FormSelectList>([{}])
  const [deals, setDeals] = useState<DealApi[]>([])
  const [isFutokoroVisible, setFutokoroVisible] = useState<string | null>(null)
  // 問い合わせ経路
  dealFields
    ?.filter((v) => v.id == 12492)
    .map((contactMethod) =>
      contactMethod.options.map((e) =>
        contactMethodList?.push({
          id: e.id,
          name: e.label,
        })
      )
    )
  contactMethodList.shift()

  // 開業予定都道府県
  PREFECTURE.map((openingPrefecture) =>
    openingPrefectureList?.push({
      id: openingPrefecture.id,
      name: openingPrefecture.prefecture_name,
    })
  )
  openingPrefectureList.shift()

  // useState定義
  const [snackBar, setSnackBar] = useState<SnackBarParam>({
    open: false,
    severity: undefined,
    message: '',
  })

  // 問い合わせ経路
  const [financyRoute, setFinancyRoute] = useState<FormSelectObject>({
    id: 49,
    name: '電話問い合わせ',
  })

  // 開業予定都道府県
  const [openingPrefecture, setOpeningPrefecture] = useState<FormSelectObject>(
    {}
  )

  const [loading, setLoading] = useState(true)

  // 関数定義
  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm<FreeDialFields>({
    mode: 'all',
    defaultValues: {
      inquiryRoute: 49, // 電話問い合わせ
    },
  })

  // 登録時処理
  const handleOnSubmit = async (params: FreeDialFields) => {
    setLoading(true)
    const pipedriveApiToken = process.env.REACT_APP_PIPEDRIVE_API_TOKEN || ''

    if (!process.env.REACT_APP_PIPEDRIVE_API_TOKEN) {
      throw new Error(
        '環境変数 REACT_APP_PIPEDRIVE_API_TOKEN が設定されていません。'
      )
    }
    const apiToken =
      authUser?.pipedrive_token != null
        ? authUser?.pipedrive_token
        : pipedriveApiToken
    const authUserName = authUser?.name ?? null

    try {
      // 既存人物への更新か新規で切り分け
      const dealResult = Object.keys(existPhone).length
        ? await existFreeDialDeal(params, apiToken, authUserName)
        : await createFreeDialDeal(params, apiToken, authUserName)
      if (!dealResult) throw new Error('deal update error')

      setSnackBar({
        open: true,
        severity: 'success',
        message: '取引を作成しました',
      })
    } catch (e) {
      setSnackBar({
        open: true,
        severity: 'error',
        message: '取引作成に失敗しました',
      })
    }

    setLoading(false)
  }

  // 結果ダイアログ用
  const handleSnackBarClose = () => {
    setSnackBar({
      open: false,
      severity: undefined,
      message: '',
    })
  }

  // 選択した人物情報をフォームに反映
  const setExistPerson = async (personId: number | undefined) => {
    setLoading(true)
    // personIdより人物情報を取得
    const person = await getPersonById(personId)
    const org = await getOrgById(person.org_id.value)
    const orgFields = await getOrgFields()
    // 取引情報を取得
    const deals = await getDealListByPersonId(person.id)
    setDeals(deals)
    setFutokoroVisible(org.futokoro)

    setValue('ownerId', person.owner_id.id)
    setValue('orgName', person.org_id.name)
    setValue('name', person.name)
    setValue('kana', person.kana)
    setValue('tel', person.phone[0].value)

    setValue('industryGrandparent', org.industry_grandparent)
    setValue('openingTime', org.opening_time)
    setValue('openingPrefecture', org.opening_prefecture)
    setOpeningPrefecture({
      id: Number(org.opening_prefecture),
      name: String(
        orgFields
          .filter((v) => v.id == 4056)
          .map((openingPrefecture) =>
            openingPrefecture.options
              .filter((v) => v.id == org.opening_prefecture)
              .map((v) => v.label)
          )[0]
      ),
    })

    trigger()
    setLoading(false)
  }

  // 電話番号から人物検索
  useEffect(() => {
    const setExistPerson = async () => {
      const existPhoneList: FormSelectList = [{}]
      // 該当の人物を取得
      const personList = await searchPersonListByPhone(existPhone.name)

      if (personList) {
        personList.map((v) => {
          existPhoneList.push({
            id: v.item.id,
            name: v.item.name,
          })
        })

        existPhoneList.shift()

        setExistPhoneList(existPhoneList)
      }
    }
    if (existPhone.name) setExistPerson()
  }, [existPhone])

  // 初期読み込み内容
  useEffect(() => {
    const firstSet = async () => {
      const dealFields = await getDealFields()

      setDealFields(dealFields)
    }

    firstSet()

    setLoading(false)
  }, [])

  // 読み込み終了までローディング
  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress />
      </Backdrop>
    )
  }

  return (
    <GenericTemplate title="フリーダイヤル新規受付">
      <form>
        <Grid
          container
          justifyContent="space-around"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid container spacing={3} item md={9} xs={12}>
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    {...register('existPhone')}
                    id="combo-box-demo"
                    options={existPhoneList}
                    getOptionLabel={(option) => option.name ?? ''}
                    getOptionSelected={(option) => option.id == existPhone.id}
                    value={existPhone}
                    onChange={(_e, options) => {
                      setValue('existPhone', options?.id)
                      setExistPhone({ id: options?.id, name: options?.name })
                      if (options?.id) setExistPerson(options?.id)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="検索電話番号"
                        autoComplete="existPhone"
                        onKeyDown={(e) => {
                          if (e.code == 'ArrowRight') {
                            setExistPhone({
                              name: (e.target as HTMLInputElement).value,
                            })
                          }
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {isFutokoroVisible && (
                <Grid
                  container
                  xs={12}
                  md={6}
                  lg={4}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    className={classes.betweenButton}
                    startIcon={<PersonIcon />}
                  >
                    FUTOKOROアカウントあり
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={6} md={3} lg={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                    オーナー
                  </InputLabel>
                  <Controller
                    name="ownerId"
                    control={control}
                    rules={{
                      required: '※ オーナーを選択してください',
                    }}
                    render={({ field }) => (
                      <Select
                        native
                        defaultValue=""
                        id="grouped-native-select"
                        label="Grouping"
                        {...field}
                      >
                        <option aria-label="None" value="" />
                        <optgroup label="融資">
                          {FINANCE_MEMBER_DISPLAY.map((dealOwner) => (
                            <option key={dealOwner.id} value={dealOwner.id}>
                              {dealOwner.name}
                            </option>
                          ))}
                        </optgroup>
                        <optgroup label="補助金">
                          {SUBSIDY_MEMBER_DISPLAY.map((dealOwner) => (
                            <option key={dealOwner.id} value={dealOwner.id}>
                              {dealOwner.name}
                            </option>
                          ))}
                        </optgroup>
                        <optgroup label="ポスコロ">
                          {POST_CORONA.map((dealOwner) => (
                            <option key={dealOwner.id} value={dealOwner.id}>
                              {dealOwner.name}
                            </option>
                          ))}
                        </optgroup>
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.ownerId && (
                  <span className={classes.errorMessage}>
                    {errors.ownerId.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    {...register('inquiryRoute', {
                      required: '※ 問い合わせ経路を選択してください',
                    })}
                    id="combo-box-demo"
                    options={contactMethodList}
                    getOptionLabel={(option) => option.name ?? ''}
                    getOptionSelected={(option) => option.id == financyRoute.id}
                    value={financyRoute}
                    onChange={(_e, options) => {
                      setValue('inquiryRoute', options?.id)
                      setFinancyRoute({ id: options?.id, name: options?.name })
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span style={{ color: 'red' }}>問い合わせ経路</span>
                        }
                      />
                    )}
                  />
                </FormControl>
                {errors.inquiryRoute && (
                  <span className={classes.errorMessage}>
                    {errors.inquiryRoute.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <FormControl className={classes.formControl}>
                  <Controller
                    name="taskStartDate"
                    control={control}
                    rules={{
                      required: '※ アポイント日を入力してください',
                    }}
                    render={({ field }) => (
                      <MuiPickersUtilsProvider
                        utils={ExtendedUtils}
                        locale={jaLocale}
                      >
                        <KeyboardDatePicker
                          {...field}
                          label={
                            <span style={{ color: 'red' }}>アポイント日</span>
                          }
                          value={field.value ? field.value : null}
                          format="yyyy-MM-dd"
                          autoOk={true}
                          variant="inline"
                          invalidDateMessage="※ フォーマットに従って入力してください"
                          onChange={(_date, v) => {
                            if (v !== null && v !== undefined) {
                              setValue('taskStartDate', v)
                            }
                            trigger('taskStartDate')
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </FormControl>
                {errors.taskStartDate && (
                  <span className={classes.errorMessage}>
                    {errors.taskStartDate.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <FormControl className={classes.formControl}>
                  <Controller
                    name="taskStartTime"
                    control={control}
                    rules={{
                      required: '※ 開始時間を入力してください',
                    }}
                    render={({ field }) => (
                      <MuiPickersUtilsProvider
                        utils={ExtendedUtils}
                        locale={jaLocale}
                      >
                        <KeyboardTimePicker
                          {...field}
                          label={<span style={{ color: 'red' }}>開始時間</span>}
                          value={field.value ? field.value : null}
                          format="HH:mm"
                          autoOk={true}
                          variant="inline"
                          invalidDateMessage="※ フォーマットに従って入力してください"
                          ampm={false}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </FormControl>
                {errors.taskStartTime && (
                  <span className={classes.errorMessage}>
                    {errors.taskStartTime.message}
                  </span>
                )}
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.betweenButton}
              startIcon={<BusinessIcon />}
            >
              組織
            </Button>

            <Grid container spacing={3} item xs={12}>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  {...register('orgName', {
                    required: '※ 組織名を入力してください',
                  })}
                  margin="normal"
                  fullWidth
                  id="orgName"
                  label={<span style={{ color: 'red' }}>組織名</span>}
                  size="small"
                />
                {errors.orgName && (
                  <span className={classes.errorMessage}>
                    {errors.orgName.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="owner-select-label" style={{ color: 'red' }}>
                    起業する業種（祖父母）
                  </InputLabel>
                  <Controller
                    name="industryGrandparent"
                    control={control}
                    rules={{
                      required: '※ 起業する業種（祖父母）を入力してください',
                    }}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">選択してください</MenuItem>
                        {INDUSTRY_GRANDPARENT.map((industryGrandparent) => (
                          <MenuItem
                            key={industryGrandparent.pipedrive_field_id}
                            value={industryGrandparent.pipedrive_field_id}
                          >
                            {industryGrandparent.industry_grandparent_name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.industryGrandparent && (
                  <span className={classes.errorMessage}>
                    {errors.industryGrandparent.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <FormControl className={classes.formControl}>
                  <Controller
                    name="openingTime"
                    control={control}
                    rules={{
                      required: '※ 開業時期を入力してください',
                    }}
                    render={({ field }) => (
                      <MuiPickersUtilsProvider
                        utils={ExtendedUtils}
                        locale={jaLocale}
                      >
                        <KeyboardDatePicker
                          {...field}
                          label={<span style={{ color: 'red' }}>開業時期</span>}
                          value={field.value ? field.value : null}
                          format="yyyy-MM-dd"
                          autoOk={true}
                          variant="inline"
                          invalidDateMessage="※ フォーマットに従って入力してください"
                          onChange={(_date, v) => {
                            if (v !== null && v !== undefined) {
                              setValue('openingTime', v)
                            }
                            trigger('openingTime')
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </FormControl>
                {errors.openingTime && (
                  <span className={classes.errorMessage}>
                    {errors.openingTime.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    {...register('openingPrefecture', {
                      required: '※ 開業予定都道府県を選択してください',
                    })}
                    id="combo-box-demo"
                    options={openingPrefectureList}
                    getOptionLabel={(option) => option.name ?? ''}
                    getOptionSelected={(option) =>
                      option.id == openingPrefecture.id
                    }
                    value={openingPrefecture}
                    onChange={(_e, options) => {
                      setValue('openingPrefecture', options?.id)
                      setOpeningPrefecture({
                        id: options?.id,
                        name: options?.name,
                      })
                      trigger('openingPrefecture')
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span style={{ color: 'red' }}>開業予定都道府県</span>
                        }
                      />
                    )}
                  />
                </FormControl>
                {errors.openingPrefecture && (
                  <span className={classes.errorMessage}>
                    {errors.openingPrefecture.message}
                  </span>
                )}
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.betweenButton}
              startIcon={<PersonIcon />}
            >
              人物
            </Button>

            <Grid container spacing={3} item xs={12}>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  {...register('name', {
                    required: '※ 名前を入力してください',
                  })}
                  margin="normal"
                  fullWidth
                  id="name"
                  label={<span style={{ color: 'red' }}>名前</span>}
                  size="small"
                />
                {errors.name && (
                  <span className={classes.errorMessage}>
                    {errors.name.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  {...register('kana', {
                    pattern: {
                      value: /^[ぁ-んー　]+$/,
                      message: '※ ふりがなのみで入力してください',
                    },
                  })}
                  margin="normal"
                  fullWidth
                  id="kana"
                  label="ふりがな"
                  size="small"
                />
                {errors.kana && (
                  <span className={classes.errorMessage}>
                    {errors.kana.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  {...register('tel', {
                    required: '※ 電話番号を入力してください',
                    pattern: {
                      value: /^0\d{1,4}-\d{1,4}-\d{4}$/,
                      message: '※ 電話番号形式で入力してください',
                    },
                  })}
                  margin="normal"
                  fullWidth
                  id="tel"
                  label={<span style={{ color: 'red' }}>電話番号</span>}
                  size="small"
                />
                {errors.tel && (
                  <span className={classes.errorMessage}>
                    {errors.tel.message}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  {...register('email', {
                    pattern: {
                      // 一般的なメールアドレスのパターン
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/,
                      message: '※ 正しいメールアドレス形式で入力してください',
                    },
                  })}
                  margin="normal"
                  fullWidth
                  id="email"
                  label="メールアドレス"
                  size="small"
                />
                {errors.email && (
                  <span className={classes.errorMessage}>
                    {errors.email.message}
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    {...register('consultationContent')}
                    multiline
                    variant="outlined"
                    fullWidth
                    id="consultationContent"
                    label="相談内容"
                    autoComplete="consultationContent"
                    minRows={3}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Box component="span" m={3} className={classes.buttonWrapper}>
                <Button
                  size="large"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  startIcon={<Create />}
                  onClick={handleSubmit(handleOnSubmit)}
                >
                  登録
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1} md={3}>
            {deals?.map((v) => (
              <Grid item xs={12} key={v.id}>
                <Card
                  variant="outlined"
                  style={{
                    backgroundColor:
                      v.status === 'lost' ? '#ffe4e1' : '#f5f5f5',
                  }}
                >
                  <CardContent>
                    <Typography>
                      {
                        PIPELINE.filter(
                          (pipeline) => (pipeline.id = v.pipeline_id)
                        )[0].name
                      }
                      {'　'}
                      <RefreshIcon style={{ verticalAlign: 'middle' }} />{' '}
                      {format(
                        addHours(new Date(v.update_time), 9),
                        'yyyy年MM月dd日 HH時mm分'
                      )}
                    </Typography>
                    <a
                      href={`https://solabo.pipedrive.com/deal/${v.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        type="button"
                        variant="outlined"
                        color="default"
                        className={classes.submitButton}
                        startIcon={<LaunchIcon />}
                      >
                        Pipedrive 取引
                      </Button>
                    </a>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Snackbar
          open={snackBar.open}
          onClose={handleSnackBarClose}
          autoHideDuration={3000}
        >
          <Alert severity={snackBar.severity} onClose={handleSnackBarClose}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </form>
    </GenericTemplate>
  )
}

export default FreeDialDeal
