import { Stage } from 'models/stage'
import client from './client'

// pipedrive stage取得
export const getStageList = async (): Promise<Stage> => {
  return client
    .get(`stages?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}
