import client from './client'

import { PersonApi, PersonSeach } from '../models/person'
import { DealFields } from 'models/dealFields'
import { DealApi } from 'models/deal'

// 単一の取引情報を取得
export const getPersonById = async (
  id: number | undefined
): Promise<PersonApi> => {
  return client
    .get(`persons/${id}?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`)
    .then((res) => {
      // 識別子が多いためオブジェクトに置き換える
      const person = {
        ...res.data,
        mail_send: res.data['fb007cbad52b83495cd632fe10d3deacaca6dd63'],
        pr_facebook_account:
          res.data['619c600aedbf50a018697a064cd911609b728893'],
        pr_facebook_status:
          res.data['eb2c8563c753da17b5277b48610f60cc7316bceb'],
        tax_introduction: res.data['99dd9041103aa4891c7f452317bf911a632d70b0'],
        kana: res.data['3b08ad106a3437a6ed42ccf4636f84a3780091c1'],
        housing_loan: res.data['08a0f52504708bfed45cb83b6d62423bb27f21cd'],
        monthly_repayment_amount_of_housing:
          res.data['5162eb8355901cbc164223be23651ea8123c60bf'],
        remaining_repayment_period_for_housing:
          res.data['cad2b537621262b783c6f94dc8300ab29ae9aea2'],
        housing_balance: res.data['2959da4cadc8b81f1f11d97a8bb03ffdc1d7d5bf'],
        housing_buy_time: res.data['ee534fab15b0ee8ffb0620d612d8ed8c084719f3'],
        address: res.data['3b3306b8d8b26ea80fde37bf3a5c62fe1fd76d11'],
        address_buildings: res.data['cade37c587d201d7053983c04ce99f0062889a2d'],
        borrowing_payment_delay:
          res.data['c54a3277c33701f89f870468187216c9b12766c8'],
        borrowing_payment_delay_detail:
          res.data['30be01dc58c89f9b46d3921cbc5528d02c481a76'],
        borrowing_detail: res.data['71295f24b5ac5119c2268b3f2b1ac24d945199f1'],
        borrowing_amount: res.data['c472d03414b77867189887ebd2130a5b81572b94'],
        borrowing: res.data['6a7306196631eff631c4372c86468178bd552d6e'],
        remark: res.data['1d2908285267122181914c8fbd3b36224559af4a'],
        delayed_utility_bill_payment:
          res.data['8ba497163a295569d8784f32a480119f778df071'],
        delayed_utility_bill_payment_detail:
          res.data['4589f30d42fde9b791910ea6c993d0906eb80138'],
        sex: res.data['f3c81f50fcccece0ca0eff9fe5d9bb3b047eeb36'],
        old_manager: res.data['814668c3541aec05a84316b77afda96b179eb8c7'],
        industry_experience:
          res.data['974d9b355c4a16a4f0a89dcb23f10038c3256b2d'],
        industry_experience_years:
          res.data['c975942346256a5b416fd4135a2253fffdf83096'],
        birthday: res.data['ebb4945b7fd7b48b9414ab3661f68b1c8cc33491'],
        delayed_tax: res.data['dc5d2896c8674c98fc9648f35a3d441ab4202655'],
        delayed_tax_detail:
          res.data['92d350c0b148c05a07cfaf56f6d6d7d32887a31d'],
        insurance: res.data['9143c6c93eeb1cb0d14be58f0ae2390be73f9d7b'],
        remaining_repayment_period_for_insurance:
          res.data['3d24fd89f35357beee6c7f4390a3c979b1d62f3a'],
        insurance_company: res.data['b0193f298a2fed83ab0f562d439453196b81c90e'],
        insurance_cancellation_refund_amount:
          res.data['f599fc058be9fd438d264510a7ff3c70c90c4950'],
        resource: res.data['e8184b7525182005b65991a9cd0fd71c51de3ca8'],
        other_resource: res.data['5463f732a9579943a872d11ebe2c7b226d204503'],
        rent_or_house: res.data['b155864d991d29f296a962d5264c15a65c4896a5'],
        car_loan: res.data['c54184645cdbaf5d3697f81868a63bf2f9f225ea'],
        self_bankruptcy: res.data['804068f9b214b4d33a790102de9dfff7ba3f9aca'],
        self_bankruptcy_amount:
          res.data['2f0569517b565f36d6ed2ad50219226146740664'],
        self_bankruptcy_details:
          res.data['5a856abb6b73688890ef15770ff217b00ec03ef5'],
        spouse: res.data['a904fc3be487df877766dc3d6346461c2072c851'],
        spouse_industry: res.data['ced4fdc5da7fdf25fb95ed100cbae46c3499598f'],
        spouse_salary: res.data['58994cf4905ccab7bf9de5ad26ad0c83750caaba'],
        tel_second: res.data['aad0745b9a68b2f4294b6134519f87a463ff7410'],
        customer_rank: res.data['ee144876b83b237baed86c6956869a3638cfe9c0'],
        post_code: res.data['4a6226f947d59d3ef76b3269be5a60628c5571fc'],
        card_loan: res.data['affd36c9dadb23dcd3ec7729ab9d07044f1d9ee4'],
        card_loan_amount: res.data['ca0af508d7d99faa087746156724208c4c8fe2b7'],
        tax_advisory_contract:
          res.data['ec68b3975bc308d52090751ec1bebdf079673372'],
        tax_advisory_contract_amount:
          res.data['96f71afbe23a01afba3abe0702ee56bc93eae1dc'],
        financing_info: res.data['e4ad97d3927b414a036740db448889f0ec6d14c4'],
        hash: res.data['f52ec43d146d18d0f7b334b07fb452572af761d3'],
      }

      return person
    })
    .catch((err) => {
      console.log(err)
    })
}

// 文字列から人物候補を取得
export const searchPersonListByName = async (
  personName: string | undefined
): Promise<PersonSeach> => {
  return client
    .get(
      `persons/search?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`,
      {
        params: {
          term: personName,
        },
      }
    )
    .then((res) => {
      return res.data.items
    })
    .catch((err) => {
      console.log(err)
    })
}

export const updatePerson = async (
  person: DealFields,
  apiToken: string
): Promise<boolean> => {
  const personParam = {
    name: person.personName,
    owner_id: person.personOwnerId,
    phone: person.personTel,
    email: person.personEmail,
    fb007cbad52b83495cd632fe10d3deacaca6dd63: person.mailSend,
    '619c600aedbf50a018697a064cd911609b728893': person.prFacebookAccount,
    eb2c8563c753da17b5277b48610f60cc7316bceb: person.prFacebookStatus,
    '99dd9041103aa4891c7f452317bf911a632d70b0': person.taxIntroduction,
    '3b08ad106a3437a6ed42ccf4636f84a3780091c1': person.kana,
    '08a0f52504708bfed45cb83b6d62423bb27f21cd': person.housingLoan,
    '5162eb8355901cbc164223be23651ea8123c60bf':
      person.monthlyRepaymentAmountOfHousing,
    cad2b537621262b783c6f94dc8300ab29ae9aea2:
      person.remainingRepaymentPeriodForHousing,
    '2959da4cadc8b81f1f11d97a8bb03ffdc1d7d5bf': person.housingBalance,
    ee534fab15b0ee8ffb0620d612d8ed8c084719f3: person.housingBuyTime,
    '3b3306b8d8b26ea80fde37bf3a5c62fe1fd76d11': person.personAddress,
    cade37c587d201d7053983c04ce99f0062889a2d: person.personAddressBuildings,
    c54a3277c33701f89f870468187216c9b12766c8: person.borrowingPaymentDelay,
    '30be01dc58c89f9b46d3921cbc5528d02c481a76':
      person.borrowingPaymentDelayDetail,
    '71295f24b5ac5119c2268b3f2b1ac24d945199f1': person.borrowingDetail,
    c472d03414b77867189887ebd2130a5b81572b94: person.borrowingAmount,
    '6a7306196631eff631c4372c86468178bd552d6e': person.borrowing,
    '1d2908285267122181914c8fbd3b36224559af4a': person.personRemark,
    '8ba497163a295569d8784f32a480119f778df071':
      person.delayedUtilityBillPayment,
    '4589f30d42fde9b791910ea6c993d0906eb80138':
      person.delayedUtilityBillPaymentDetail,
    f3c81f50fcccece0ca0eff9fe5d9bb3b047eeb36: person.sex,
    //'814668c3541aec05a84316b77afda96b179eb8c7': person.personOldManager,
    '974d9b355c4a16a4f0a89dcb23f10038c3256b2d': person.industryExperience,
    c975942346256a5b416fd4135a2253fffdf83096: person.industryExperienceYears,
    ebb4945b7fd7b48b9414ab3661f68b1c8cc33491: person.birthday,
    dc5d2896c8674c98fc9648f35a3d441ab4202655: person.delayedTax,
    '92d350c0b148c05a07cfaf56f6d6d7d32887a31d': person.delayedTaxDetail,
    '9143c6c93eeb1cb0d14be58f0ae2390be73f9d7b': person.insurance,
    '3d24fd89f35357beee6c7f4390a3c979b1d62f3a':
      person.remainingRepaymentPeriodForInsurance,
    b0193f298a2fed83ab0f562d439453196b81c90e: person.insuranceCompany,
    f599fc058be9fd438d264510a7ff3c70c90c4950:
      person.insuranceCancellationRefundAmount,
    e8184b7525182005b65991a9cd0fd71c51de3ca8: person.resource,
    '5463f732a9579943a872d11ebe2c7b226d204503': person.otherResource,
    b155864d991d29f296a962d5264c15a65c4896a5: person.rentOrHouse,
    c54184645cdbaf5d3697f81868a63bf2f9f225ea: person.carLoan,
    '804068f9b214b4d33a790102de9dfff7ba3f9aca': person.selfBankruptcy,
    '2f0569517b565f36d6ed2ad50219226146740664': person.selfBankruptcyAmount,
    '5a856abb6b73688890ef15770ff217b00ec03ef5': person.selfBankruptcyDetails,
    a904fc3be487df877766dc3d6346461c2072c851: person.spouse,
    ced4fdc5da7fdf25fb95ed100cbae46c3499598f: person.spouseIndustry,
    '58994cf4905ccab7bf9de5ad26ad0c83750caaba': person.spouseSalary,
    aad0745b9a68b2f4294b6134519f87a463ff7410: person.personTelSecond,
    ee144876b83b237baed86c6956869a3638cfe9c0: person.customerRank,
    '4a6226f947d59d3ef76b3269be5a60628c5571fc': person.personPostCode,
    affd36c9dadb23dcd3ec7729ab9d07044f1d9ee4: person.cardLoan,
    ca0af508d7d99faa087746156724208c4c8fe2b7: person.cardLoanAmount,
    ec68b3975bc308d52090751ec1bebdf079673372: person.taxAdvisoryContract,
    '96f71afbe23a01afba3abe0702ee56bc93eae1dc':
      person.taxAdvisoryContractAmount,
    e4ad97d3927b414a036740db448889f0ec6d14c4: person.financingInfo,
  }

  return client
    .put(`persons/${person.personId}?api_token=${apiToken}`, personParam)
    .then(() => {
      return true
    })
    .catch((err) => {
      console.log(err)
      return false
    })
}

// 電話番号から人物を取得
export const searchPersonListByPhone = (
  phone: string | undefined
): Promise<PersonSeach> => {
  return client
    .get(
      `persons/search?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`,
      {
        params: {
          term: phone,
        },
      }
    )
    .then((res) => {
      return res.data.items
    })
    .catch((err) => {
      console.log(err)
    })
}

/**
 * 人物IDから保有する取引リストを取得
 * @param personId
 * @returns
 */
export const getDealListByPersonId = async (
  personId: number
): Promise<DealApi[]> => {
  return client
    .get(
      `persons/${personId}/deals?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`,
      {
        params: {
          sort: 'update_time DESC',
        },
      }
    )
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}
