import { SolaboBankbook } from 'models/solaboBankbook'
import back from './back'

// 振込先口座情報を取得
export const getSolaboBankbook = async (): Promise<SolaboBankbook[]> => {
  return await back
    .get(`/solaboBankbook/getBanks`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
      throw new Error(err.response.data)
    })
}
