import client from './client'
import { Pipeline } from '../models/pipeline'

// pipedrive user取得
export const getPipelineList = (): Promise<Pipeline> => {
  return client
    .get(`pipelines?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}
