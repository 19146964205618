import React, { useState, useEffect } from 'react'
import GenericTemplate from '../templates/GenericTemplate'
import MaterialTable from 'material-table'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  // MenuItem,
  // FormControl,
  // Select,
  // InputLabel,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core'

import { useNavigate } from 'react-router-dom'
//import { useAuthUser } from 'components/modules/AuthUserContext'
import { InvoiceDisplay, InvoiceList } from 'models/invoice'
import { getInvoiceList } from 'api/invoice'
import AddIcon from '@material-ui/icons/Add'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    createButton: {
      margin: '20px',
    },
  })
)

const Invoice: React.FC = () => {
  const classes = useStyles()
  //const authUser = useAuthUser()
  const [invoiceList, setInvoiceList] = useState<InvoiceList[] | undefined>()
  const [progress, setProgress] = useState(true)
  const [selectedRow, setSelectedRow] = useState<number | undefined | null>(
    null
  )

  const history = useNavigate()
  const editLink = (dealId: number) => {
    history(`/invoice/edit/${dealId}`)
  }

  useEffect(() => {
    const firstSetting = async () => {
      // 請求書データを取得
      const invoiceList = await getInvoiceList()

      setInvoiceList(invoiceList)

      setProgress(false)
    }
    firstSetting()
  }, [])

  if (progress) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress />
      </Backdrop>
    )
  }

  const invoices: InvoiceDisplay[] = []

  const numberFormat = (num: number): string => {
    return num.toLocaleString()
  }

  const formatDate = (dt: Date) => {
    if (!dt) return ''
    const date = new Date(dt)
    const y = date.getFullYear()
    const m = date.getMonth() + 1
    const d = date.getDate()
    return y + '年' + m + '月' + d + '日'
  }

  return (
    <GenericTemplate title="請求書一覧">
      <Grid container spacing={3} item xs={12}>
        <Grid container xs={12} md={6} lg={3}>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.createButton}
            startIcon={<AddIcon />}
            component={Link}
            to="/invoice/create"
          >
            新規作成
          </Button>
        </Grid>
      </Grid>
      {invoiceList?.map((invoice) => {
        const copyObject = {
          id: invoice.id,
          invoice_title: invoice.invoice_title,
          invoice_on: invoice.invoice_on,
          invoice_dead_on: invoice.invoice_dead_on,
          is_invoice: invoice.is_invoice,
          is_invoice_mail: invoice.is_invoice_mail,
          invoice_payment_status: invoice.invoice_payment_status,
          invoice_payment_at: invoice.invoice_payment_at,
          billing_amount_total: invoice.billing_amount_total,
          created_at: invoice.created_at,
        }

        invoices.push(copyObject)
      })}
      <MaterialTable
        localization={{
          header: { actions: '' },
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: '編集',
            onClick: (_, rowData) => {
              editLink((rowData as InvoiceDisplay).id)
            },
          },
        ]}
        columns={[
          { title: '件名', field: 'invoice_title' },
          {
            title: '請求日',
            field: 'invoice_on',
            render: (row) => {
              return formatDate(row.invoice_on)
            },
          },
          {
            title: '期限日',
            field: 'invoice_dead_on',
            render: (row) => {
              return formatDate(row.invoice_dead_on)
            },
          },
          {
            title: '請求状況',
            field: 'is_invoice',
            render: (row) => {
              return row.is_invoice == false ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  target="_blank"
                  href=""
                >
                  未請求
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  target="_blank"
                  href=""
                >
                  請求済
                </Button>
              )
            },
          },
          {
            title: 'メール送信',
            field: 'is_invoice_mail',
            render: (row) => {
              return row.is_invoice_mail == false ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  target="_blank"
                  href=""
                >
                  未送信
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  target="_blank"
                  href=""
                >
                  送信済
                </Button>
              )
            },
          },
          {
            title: '入金状況',
            field: 'invoice_payment_status',
            render: (row) => {
              let paymentStatus: JSX.Element = <></>
              if (row.invoice_payment_status == 0) {
                paymentStatus = (
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    target="_blank"
                    href=""
                  >
                    未入金
                  </Button>
                )
              } else if (row.invoice_payment_status == 1) {
                paymentStatus = (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    target="_blank"
                    href=""
                  >
                    請求済
                  </Button>
                )
              } else if (row.invoice_payment_status == 2) {
                paymentStatus = (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    target="_blank"
                    href=""
                  >
                    回収不可
                  </Button>
                )
              }

              return paymentStatus
            },
          },
          {
            title: '入金日',
            field: 'invoice_payment_at',
            render: (row) => {
              return formatDate(row.invoice_payment_at)
            },
          },
          {
            title: '総計',
            field: 'billing_amount_total',
            render: (row) => {
              return numberFormat(row.billing_amount_total)
            },
          },
          {
            title: '作成日',
            field: 'created_at',
            render: (row) => {
              return formatDate(row.created_at)
            },
          },
        ]}
        data={invoices}
        title="Invoices"
        onRowClick={(_evt, selectedRow) => setSelectedRow(selectedRow?.id)}
        options={{
          pageSize: 50,
          actionsColumnIndex: -1,
          filtering: true,
          rowStyle: (rowData) => ({
            backgroundColor: selectedRow === rowData.id ? '#EEE' : '#FFF',
          }),
        }}
      />
    </GenericTemplate>
  )
}

export default Invoice
